import React from 'react'
import {Navigate, useLocation, useNavigate, redirect, Outlet} from "react-router-dom"; 
import {useSelector, useDispatch} from "react-redux";
import { connect } from "react-redux";



const mapStateToProps = ({ session }) => ({
  session
});

const AuthRoutes = ( { session }) => {
	if(session != null && session.username != null){
		return <Navigate to='/dispatch' />
	}else{
		return <Outlet />; 
	}

}

export default connect(
  mapStateToProps,
)(AuthRoutes);