import ConnectIntuit from "../modules/intuit/ConnectIntuit"
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

export default function Connect(){
	return(
		<>
			<Container fluid id="bgbg">
				<Row>
					<ConnectIntuit />
				</Row>
			</Container>
		</>
	)
}