import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { requestCustomerDetails } from "../../../actions/customerApp.js"

import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";


export default function BasicInformation(){
	const dispatch = useDispatch(); 
	const [loader, setLoader ] = useState(true) 
	const [status, setStatus] = useState('')
	const customer = useSelector( (state) => state.customerdetails ); 
	const customerID = useSelector((state) => state.customerID)
	const details = useSelector( (state) => state.customerdetails )
	

	useEffect(()=>{
		if(details != null){
			if(details.status == "CREDIT HOLD"){
				setStatus( <Badge bg ="danger"> {details.status} </Badge>) 

			}else{
				setStatus('')
			}
		}
	},[details])


	useEffect(()=>{
		if(customer != null){
			console.log("we got a customer..."); 
		}
	}, [customer])

	useEffect(() => {
		if (customerID != null) {
			setLoader(true)
			dispatch(requestCustomerDetails(customerID)).then((data, err) => {
				setLoader(false)
			})
		} 
		
	}, [customerID])

	return(
		<Container>
			{
				loader ?
					<Spinner />
				:
					<>
						<Row>
							<Col>
								<h4><strong>{customer.display_name} </strong></h4>
							</Col>
							<Col>
								<h3>{status}</h3>
							</Col>
						</Row>
					</>
			}
		</Container>	

	)
}