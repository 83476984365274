import {
  GET_DUMP_REPORTS
} from "../../actions/dump";

const _nullDumpList = []; 

export default (state = _nullDumpList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_DUMP_REPORTS:
      return list;
    case "": 
      return _nullDumpList; 
    default:
      return state;
  }
};