import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function Assembly(){
	const report = useSelector((state) => state.universal_report)
	return(
		<Container>
			<Row>
				<Col>
					<h5> Assembly </h5>
				</Col>
			</Row>
			
			<Row>
				<Col>
					<p>
						<strong>Serial Number:</strong>{report.assembly.serial_number} <br/>
						<strong>Location:</strong> {report.assembly.location}<br/>
						<strong>Type: </strong>{report.assembly.type}<strong> Size:</strong> {report.assembly.size} <br/>
						<strong>Make: </strong>{report.assembly.make} <strong>Model: </strong> {report.assembly.model}<br/>
						<strong> Hazard: </strong> {report.assembly.hazard_type}
					</p>
				</Col>
			</Row>
		</Container>
	)	
}