import {
  SET_ON_SITE_NOTE, 
  CLEAR_ON_SITE_NOTE
} from "../../actions/scheduling";

const _nullOnSiteNote = null; 
                
export default (state = _nullOnSiteNote, { type, note }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ON_SITE_NOTE:
      return note;
    case CLEAR_ON_SITE_NOTE: 
      return _nullOnSiteNote
    case "": 
      return _nullOnSiteNote; 
    default:
      return state;
  }
};