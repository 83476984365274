import {
  SET_ROUNDS_DATE_TOTAL
} from "../../actions/rounds.js";

const _nullCount =  0;
                
export default (state = _nullCount, { type, total }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_DATE_TOTAL:
      return total;
    case "": 
      return _nullCount; 
    default:
      return state;
  }
};