import {
  SET_SERVICES_LIST, 
  CLEAR_SERVICES_LIST
} from "../../actions/scheduling";

const _nullList =  []; 
                
export default (state = _nullList, { type, serviceslist }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_LIST:
      return serviceslist;
    case CLEAR_SERVICES_LIST: 
      return _nullList
    case "": 
      return _nullList; 
    default:
      return state;
  }
};