import {
  RECEIVE_TEST_DETAILS, CLEAR_TEST_DETAILS
} from "../../actions/report";

const _nullDetails = null
                
export default (state = _nullDetails, { type, details }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_TEST_DETAILS:
      return details;
    case CLEAR_TEST_DETAILS:
      return _nullDetails; 
    case "" : 
      return _nullDetails; 
    default:
      return state;
  }
};