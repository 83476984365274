import {
  END_DATE
} from "../../actions/home";

import {format} from "date-fns"

const _nullDate = format( new Date(), "yyyy-MM-dd")
                
export default (state = _nullDate, { type, date }) => {
  Object.freeze(state);
  switch (type) {
    case END_DATE:
      return date;
    case "": 
      return _nullDate; 
    default:
      return state;
  }
};