import {
  ADDRESS_DETAILS
} from "../../actions/addressApp";

const _nullAddress =  null
                
export default (state = _nullAddress, { type, address}) => {
  Object.freeze(state);
  switch (type) {
    case ADDRESS_DETAILS:
      return address;
    case "": 
      return _nullAddress; 
    default:
      return state;
  }
};