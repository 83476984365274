import ServicesReport from "./ServicesReport"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UnfinishedServices from "./unfinished/app.js"

export default function ServicesApp(){
    const [key, setKey] = useState('dispatch');

    return(

        <Container>
            <Row>
                <Col>
                     <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                        >
                        <Tab eventKey="dispatch" title="Dispatch">
                            <ServicesReport />
                        </Tab>
                        <Tab eventKey="unfinished" title="Incomplete">
                            <UnfinishedServices />
                        </Tab>
                      </Tabs>
                </Col>
            </Row>
        </Container>
    
    )
}