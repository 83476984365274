import {
    SET_UNIVERSAL_STOP_ID, CLEAR_UNIVERSAL_STOP_ID
} from "../../actions/universal_stop";

const _null = null

export default (state = _null, { type, id }) => {
    Object.freeze(state);
    switch (type) {
        case SET_UNIVERSAL_STOP_ID:
            return id;
        case CLEAR_UNIVERSAL_STOP_ID:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};