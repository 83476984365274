import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestWaterDistricts} from "../../../../actions/servicesReport"
import {requestAddressDetails} from "../../../../actions/addressApp"
import {updateAddress} from "../../../../actions/addressApp"


export default function AddressForm(){
	const [locationName, setLocationName] = useState(null)
	const addressID = useSelector((state) => state.addressID )
	const address = useSelector((state) => state.address_details)
	const [residential, setResidential] = useState(null)
	const [street, setStreet] = useState(null)
	const [city, setCity] = useState(null)
	const [state, setState] = useState(null)
	const [zipcode, setZipcode] = useState(null)
	const [waterID, setWaterID]= useState(null)
	const [month, setMonth] = useState(null)
	const [name, setName] = useState(null);
	const [phone, setPhone] = useState(null);
	const [email, setEmail] = useState(null); 
	const [gate, setGate] = useState(null); 
	const [note, setNote] = useState(null); 
	const [track, setTrack] = useState(null); 
	const dispatch = useDispatch(); 
	const currentStopID = useSelector( (state) => state.services_report_current_stop_id)
	const [salesTax, setSalesTax] = useState(null)

	const [addressLoader, setAddressLoader] = useState(true)

	const waterList = useSelector( (state) => state.services_water_list)


	useEffect(()=>{
		dispatch( requestWaterDistricts() ).then((data, err)=>{
			console.log("water district gotten")
		})
	}, [])

	//request  address details
	useEffect(()=>{
		if(addressID != null){
			setAddressLoader(true)
			dispatch( requestAddressDetails(addressID) ).then((data, err) =>{
				setAddressLoader(false)
			})
		}else{
			setAddressLoader(true)
		}
	}, [addressID])

	useEffect(()=>{
		if(address != null){
			setLocationName(address.location_name); 
			setResidential(address.locationType); 
			setName(address.contact_name);
			setPhone(address.contact_phone);
			setGate(address.gate_code);
			setNote(address.note); 
			setStreet( address.street)
			setCity(address.city)
			setState(address.state)
			setZipcode(address.zipcode)
			setWaterID(address.water_district)
			setMonth(address.month_due)
			setTrack(address.track_num)
			setSalesTax(address.sales_tax)
		}
	}, [address])

	const update = ()=>{
		let obj = {};
		if (address != null ) {
			obj.locationName = locationName;
			obj.locationType = residential;
			obj.street = street;
			obj.city = city;
			obj.state = state;
			obj.zipcode = zipcode;
			obj.month = month;
			obj.waterDistrict = waterID;
			obj.contact_name = name;
			obj.contact_phone = phone;
			obj.gate = gate;
			obj.note = note
			obj.track = track;
			obj.sales_tax = salesTax;
			dispatch(updateAddress(addressID, obj)).then((data, err) => {

			})
		}
		
	}
	const renderWaters = waterList.map( (water) =>{
		return(
			<option value={water.waterID}> {water.short_name}</option>
		)
	})

	useEffect(() => {
		if (residential != null) {
			update()
		}
	}, [residential])

	useEffect(() => {
		if (waterID != null) {
			update()
		}
	}, [waterID])
	useEffect(() => {
		if ( month != null) {
			update()
		}
	}, [month])



	return(

		<>
			{
				addressLoader ? 
					<> waiting for address</>
				: 
					<Container>
						<Row>
							<Col>
								<h5> Service Address </h5>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="Location Name"
		        					size="sm"
		        					>
									<Form.Control 
										value= {locationName} 
										size="sm"
										onChange = {(event)=>setLocationName(event.target.value)}
										onBlur = {update}
										/>
								</FloatingLabel>
							</Col>
							<Col>
							
									<FloatingLabel
									controlId="floatingInput"
		        					label="Location Type"
		        					size="sm"
		        					>
									<Form.Select value = {residential } 
										onChange={(event) => {
											setResidential(event.target.value)
										}}
									>
										<option value = "RESIDENTIAL"> Residential </option>
										<option value = "COMMERCIAL" > Non-Residential </option>
									</Form.Select>
								</FloatingLabel>
								
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="Street"
		        					size="sm">
									<Form.Control 
										value= {street}
										size = "sm"
										onChange = {(event)=>setStreet(event.target.value)}
										onBlur = {update}
										/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="City"
		        					size="sm">
									<Form.Control 
										value= {city}
										size = "sm"
										onChange = {(event)=>setCity(event.target.value)}
										onBlur = {update}
									/>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="State"
		        					size="sm">
									<Form.Control 
										value= {state}
										size = "sm"
										onChange = {(event)=>setState(event.target.value)}
										onBlur = {update}
									/>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="Zipcode"
		        					size="sm">
									<Form.Control 
										value= {zipcode}
										size = "sm"
										onChange = {(event)=>setZipcode(event.target.value)}
										onBlur = {update}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="Water District"
		        					size="sm">
									<Form.Select 
										size="sm"
										value={waterID}
										onChange={(event) => {
											setWaterID(event.target.value)
										}}
							
									>
										<option value = {null}>Select a water district</option>
										{renderWaters}
									</Form.Select>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="Month Due"
		        					size="sm">
									<Form.Select size ="sm" value = {month} onChange = {(event => {setMonth(event.target.value)})}>
										<option value = "JAN">January</option>
										<option value = "FEB">February</option>
										<option value = "MARCH">March</option>
										<option value = "APR">April</option>
										<option value = "MAY">May</option>
										<option value = "JUN">June</option>
										<option value = "JUL">July</option>
										<option value = "AUG">August</option>
										<option value = "SEP">September</option>
										<option value = "OCT">October</option>
										<option value = "NOV">November</option>
										<option value = "DEC">December</option>
									</Form.Select>
								</FloatingLabel>
							</Col>
							<Col>
								<FloatingLabel
									controlId="floatingInput"
		        					label="Track #"
		        					size="sm">
									<Form.Control  size= "sm" value= {track} onChange = { (event) => setTrack(event.target.value)} onBlur = {update}/>
								</FloatingLabel>
							</Col>
							
							
						</Row>
						<Row>
							<Col>
								<FloatingLabel label = "Sales Tax">
									<Form.Control
										value={salesTax}
										onChange={(event) => setSalesTax(event.target.value)}
										onBlur={ update}
									/>
								</FloatingLabel>
							</Col>
						</Row>
						<Row>
							<Col>
								<FloatingLabel label="Site contact name">
									<Form.Control 
										value = {name}	
										size="sm"
										onChange = {(event)=>setName(event.target.value)}
										onBlur = {update}
									/>
								</FloatingLabel>
								<FloatingLabel label="Site phone number">
								<Form.Control 
									value = {phone}	
									onChange = {(event)=>setPhone(event.target.value)}
									onBlur = {update}
								/>
								</FloatingLabel>
								<FloatingLabel label = "Gate #">
									<Form.Control 
									value = {gate}	
									onChange = {(event)=>setGate(event.target.value)}
									onBlur = {update}
								/>
								</FloatingLabel>
								<FloatingLabel label = "Site Note">
									<Form.Control 
										value = {note}
										onChange= { (event) => setNote(event.target.value) }
										onBlur= {update}
									/>
								</FloatingLabel>
							</Col>
						</Row>
					</Container>
			}			
		</>
	)
}