import {
  GET_STOPLIST
} from "../../actions/dispatch";

const _nullStoplist = { 
  stoplist: []
}
                
export default (state = _nullStoplist, { type, stoplist }) => {
  Object.freeze(state);
  switch (type) {
    case GET_STOPLIST:
      return stoplist;
    case "": 
      return _nullStoplist; 
    default:
      return state;
  }
};