import {
  SET_BUILDER_SERVICE_ADDRESS, 
  CLEAR_BUILDER_SERVICE_ADDRESS
} from "../../actions/invoiceBuilder";

const _nullObj =  null
                
export default (state = _nullObj, { type, address }) => {
  Object.freeze(state);
  switch (type) {
    case SET_BUILDER_SERVICE_ADDRESS:
      return address;
    case CLEAR_BUILDER_SERVICE_ADDRESS: 
      return _nullObj
    case "": 
      return _nullObj; 
    default:
      return state;
  }
};