import {
  RECEIVE_ERROR,  
  CLEAR_ERROR
} from "../../actions/session";

const _nullError = { 
  success:  null, 
  message: null
}
                
export default (state = _nullError, { type, error }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_ERROR:
      return error;
    case CLEAR_ERROR: 
      return _nullError; 
    default:
      return state;
  }
};