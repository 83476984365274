import {
  RECEIVE_TESTER, CLEAR_TESTER
} from "../../actions/report";

const _nullTester = null
                
export default (state = _nullTester, { type, tester }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_TESTER:
      return tester;
    case CLEAR_TESTER:
        return _nullTester
    case "" : 
      return _nullTester; 
    default:
      return state;
  }
};