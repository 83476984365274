import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	setQuery,
	lookUpQuery,
	setCurrentDistrictID,
	createDistrict,
	nullifyQuery, clearResultList
} from "../../../actions/districtManagement.js"

export default function WaterDistrictSearchBar(){
	const [searchValue, setSearchValue] = useState(null);
	const query = useSelector((state) => state.district_query) 
	const resultsList = useSelector( (state) => state.district_results )
	const dispatch = useDispatch(); 
	const [resultsLoader, setResultsLoader] = useState(true)
	const currentID = useSelector((state) => state.district_id)

	const newWD = () => {
		dispatch(createDistrict({})).then((data, err) => {
			console.log("")
		})
		setSearchValue(null)
		dispatch(nullifyQuery()).then((data, err) => {
		})
		dispatch(clearResultList()).then((data, err) => {
			
		})
	}

	useEffect(()=>{
		if(searchValue != null && searchValue.length > 0){
			dispatch( setQuery( searchValue) ).then( (data, err) =>{
			})
		}
	}, [searchValue])

	useEffect(()=>{
		if(query != null){
			setResultsLoader(true)
			dispatch(lookUpQuery(query)).then((data, err) =>{
				setResultsLoader(false)
			})
		}
	}, [query])

	const renderResults = resultsList.map((dd, err) => {
		return(
			<ListGroup.Item   variant = "secondary" onClick = {
				()=>{
					dispatch(setCurrentDistrictID(dd.waterID)).then((data, err) => {
						setResultsLoader(true)
					})
				}
			} >
				{dd.short_name} - { dd.name}
			</ListGroup.Item>
		)
	})


	return(
		<Container>
			<Row>
				<Col sm md lg={ 10}>
					<FloatingLabel  label = "Search in water districts">
						<Form.Control
							value = {searchValue}
							onChange = {
								(event) =>setSearchValue(event.target.value)
							}
						/>
					</FloatingLabel>
					 
				</Col>
				<Col>
					<Container>
						<Button
							size="sm"
							variant = "secondary"
							onClick={
									newWD
							}
						> New Water District </Button>
					</Container>
				</Col>
			</Row>
			<Row>
				<Col sm md lg={10}>
					{
						resultsLoader ?
							<></>
						: 
							<ListGroup>
								{renderResults}	
							</ListGroup>

					}
					
				</Col>
				<Col>
				</Col>
			</Row>
		</Container>
	)
}