import {
  SET_ROUNDS_MONTH, CLEAR_ROUNDS_MONTH
} from "../../actions/rounds.js";

const today = new Date()
const _nullMonth=  String(today.getMonth() + 1).toUpperCase()
                
export default (state = _nullMonth, { type, month }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_MONTH:
      return month;
    case CLEAR_ROUNDS_MONTH: 
      return _nullMonth; 
    case "": 
      return _nullMonth; 
    default:
      return state;
  }
};