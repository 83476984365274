import {
    SET_DATE_SELECTED, CLEAR_DATE_SELECTED
} from "../../actions/unfinished";

const _null = null 


export default (state = _null, { type, date }) => {
    Object.freeze(state);
    switch (type) {
        case SET_DATE_SELECTED:
            return date;
        case CLEAR_DATE_SELECTED:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};