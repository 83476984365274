export const requestReportResults = (reportID) => (
	fetch("backflow/unireport?" + new URLSearchParams({
		reportID: reportID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)


export const emailReport = (email, reportID) => (
	fetch("backflow/unireport/email", {
		method: "POST",
		body : JSON.stringify( {reportID: reportID, email: email} ),
		headers: {
			"Content-Type": "application/json"
		}
	})

)