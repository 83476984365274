import Row from 'react-bootstrap/Row';	
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'

export default function SchedulingApplication(){
	return(
		<Container>
			<Row>
				<Col>
					Select a date 
				</Col>
			</Row>
			<Row>
				<Col>
					Assembly list / service type / tester assigned
				</Col>
			</Row>
			<Row>
				<Col>
					Notes 
				</Col>
			</Row>
		</Container>
	)
}