import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

export default function EditTestReport(props){
	return(
		<Container>
			<Row>
				<Col>
					<h6> Assembly </h6>
					<InputGroup size="sm">
						<InputGroup.Text> Serial Number  </InputGroup.Text>
						<Form.Control />
					</InputGroup>
					<InputGroup size="sm">
						<InputGroup.Text> Location </InputGroup.Text>
						<Form.Control />
					</InputGroup>
					<InputGroup size="sm">
						<InputGroup.Text> Size  </InputGroup.Text>
						<Form.Control />
						<InputGroup.Text> Type </InputGroup.Text>
						<Form.Control />

					</InputGroup>
					<InputGroup size="sm">
						<InputGroup.Text> Make   </InputGroup.Text>
						<Form.Control />
						<InputGroup.Text> Model</InputGroup.Text>
						<Form.Control />
						
					</InputGroup>
					<InputGroup size="sm">
						<InputGroup.Text> Hazard </InputGroup.Text>
						<Form.Control />
					</InputGroup>

					<hr/>
					<h6> Double Check </h6>
					<InputGroup size= "sm">
						<InputGroup.Text> #1 </InputGroup.Text>
						<Form.Control />
						<InputGroup.Text> psid </InputGroup.Text>
						<Badge>
							Tight
						</Badge>
					</InputGroup>
					<InputGroup size="sm">
						<InputGroup.Text> #2 </InputGroup.Text>
						<Form.Control />
						<InputGroup.Text> psid </InputGroup.Text>
						<Badge>
							Tight
						</Badge>
					</InputGroup>
					<hr/>
					<h6> RP </h6>
					<InputGroup size= "sm">
						<InputGroup.Text> Pressdrop:  </InputGroup.Text>
						<Form.Control />
					
						<InputGroup.Text> Opened at:  </InputGroup.Text>
						<Form.Control />
						
						<Badge>
							PASS/FAIL
						</Badge>
					</InputGroup>
					<hr/>
					<h6> SVBA/ PVBA </h6>
					<InputGroup size="sm">
						<InputGroup.Text> air inlet opened at:  </InputGroup.Text>
						<Form.Control />
					</InputGroup>
					<InputGroup size="sm">
						<InputGroup.Text> check valve pressdrop:  </InputGroup.Text>
						<Form.Control />
					</InputGroup>
					<br/><br/>
					<Button> Save Changes </Button>
				</Col>
				<Col>
				</Col>
			</Row>

		</Container>
	)

}