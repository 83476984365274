import {
   SET_TOKEN_URL, CLEAR_TOKEN_URL
} from "../../actions/card_payment";

const _null = null
                
export default (state = _null, { type, url }) => {
  Object.freeze(state);
  switch (type) {
    case SET_TOKEN_URL:
      return url;
    case "": 
      return _null; 
    default:
      return state;
  }
};