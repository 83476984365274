import {
   SET_CAPTURE_RESULTS, CLEAR_CAPTURE_RESULTS
} from "../../actions/card_payment";

const _null = null
                
export default (state = _null, { type, obj }) => {
  Object.freeze(state);
  switch (type) {
    case SET_CAPTURE_RESULTS:
      return obj;
    case "": 
      return _null; 
    default:
      return state;
  }
};