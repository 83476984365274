import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { getDistrict} from "../../../actions/waterDistricts.js"

export default function WaterDistrictCard(){
	const dispatch = useDispatch(); 
	const waterDistrict = useSelector((state) => state.waterDistrict)
	const [waterReady, setWaterReady] = useState(false); 
	const chosen = useSelector((state) => state.waterID)  

	useEffect(()=>{
		if(waterDistrict == null){
			setWaterReady(false)
		}else{
			setWaterReady(true); 
		}
	}, [waterDistrict])

	useEffect(()=>{
		if(chosen!=null){
			dispatch( getDistrict( chosen) ).then( (data, err) => {
				console.log("District: ", data)
			})
		}
		
	}, [chosen])
	
	return(
		<Container>
			<Row>
				<Col>{
						waterReady ?
						<>
								<h5> My Water District </h5>
								<strong> Water District: </strong> {waterDistrict.name} <br/>
								<strong> Email: </strong> {waterDistrict.p_email} <br/>
								<strong> Delivery Method: </strong> {waterDistrict.delivery_method} <br/>
								<strong> Deliver At: </strong> <br/>{waterDistrict.Address} <br/>
								<strong> Primary Contact:  </strong> {waterDistrict.primary_contact} <br/>
								<strong> Phone: </strong> {waterDistrict.p_phone} <br/>
							</>
						:
							<> click on any water district</>
					}
					
				</Col>
			</Row>
		</Container>

	)
}