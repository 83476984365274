export const requestReportList = () =>(
	fetch("backflow/dump", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestDate = (reportID) =>(
	fetch("backflow/dump/date?" +  new URLSearchParams({
		reportID : reportID
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestResults = (reportID) =>(
	fetch("backflow/dump/results?" +  new URLSearchParams({
		reportID : reportID
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestWaterDistrict = (reportID) =>(
	fetch("backflow/dump/waterDistrict?" +  new URLSearchParams({
		reportID : reportID
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)