
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'; 
import Stack from 'react-bootstrap/Stack'; 
import Button from 'react-bootstrap/Button'; 
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';  
import Card from 'react-bootstrap/Card'; 
import { useState, useEffect } from 'react';
import {useSelector, useDispatch} from "react-redux"
import { connectQB, disconnectQB, getCSRFToken, checkConnection } from "../../actions/quickbooks"; 


export default function ConnectIntuit(){
	const connectImage = require("../../Media/Quickbooks-Images/folder1/pickme.png");
	const disconnectImage = require("../../Media/Quickbooks-Images/folder1/pickme2.png");
	const dispatch = useDispatch(); 
	const [isConnected, setIsConnected] = useState(null); 
	const [csrf, setCSRF] = useState(""); 
	const [showSpinner, setShowSpinner] = useState(false); 
	const [buttonShow, setButtonShow] = useState(
										<Button variant="black" > 
											<img id="connect-image" className="show hide"src={connectImage} />
										</Button>)



	useEffect(()=>{
		getCSRFToken().then((data, err)=>{
			setCSRF(data); 
		})
	}, [])

	useEffect( ()=>{
		setShowSpinner(true)
		checkConnection().then( ( data, err) =>{
			setIsConnected(data); 
			setShowSpinner(false)
		})

	}, [])

	useEffect(()=>{
		if(isConnected == null){
			setButtonShow(<Button variant="black" > 
										<img id="connect-image" src={connectImage} />
									</Button>)
		}else if(isConnected == true){
			setButtonShow(<Button onClick={()=> {
					setShowSpinner(true)
					dispatch( disconnectQB(), csrf ).then((data, err)=>{
						setShowSpinner(false)
					})
				}
			} variant="link" > 
										Disconnect
									</Button>)
		}else if( isConnected == false) {
			setButtonShow(<Button variant="black" onClick={ ()=> {
					dispatch( connectQB(), csrf )
				}
			}> 
										<img id="connect-image" src={connectImage} />
									</Button>)
		}
	}, [isConnected]) 

	return(
		<Container>
			<Row>
				<Modal show={showSpinner}>
					<Modal.Body>
						<Spinner />
					</Modal.Body>
				</Modal>
			</Row>
			<Row>
			
				<Col>
					<Card>
						<Card.Header>
							<Row>
								<Col>
									<h6> American Backflow App displays invoice information for your clients's invoices. </h6>
									<h6>This app also processess payments online using Intuit Payment Inc as payment processor.</h6>
									<h6>By connecting your QuickBooks Online account to American Backflow App, you agree to allow your QuickBooks account data to be used for the following purposes: </h6>
									<ul>
										<li>Displaying Invoice details</li>
										<li>Displaying Customer details </li>
										<li>Payment Processing </li>
									</ul>
								</Col>
							</Row>
						</Card.Header>
						<Card.Body >

							<Row>
								<Col>
									<h5 className="americanNameSecondary"> American Backflow App </h5>
								</Col>
								<Col>
									<h1> ==> </h1>
								</Col>
								<Col>
									{ buttonShow}
								</Col>
							</Row>

						</Card.Body>
						<Card.Footer>
							<Row>
								<Col>
									<h6><strong> Technical Support: </strong> <a href="email: gemadhc@gmail.com">gemah@backflow-plumbing.com</a></h6>
								</Col>
							</Row>
						</Card.Footer>
					</Card>
				</Col>

			</Row>
			

				
		</Container>	
	)
	
}