import {
    SET_UNIVERSAL_STOP_PHONE, CLEAR_UNIVERSAL_STOP_PHONE
} from "../../actions/universal_stop";

const _null = null


export default (state = _null, { type, str }) => {
    Object.freeze(state);
    switch (type) {
        case SET_UNIVERSAL_STOP_PHONE:
            return str;
        case CLEAR_UNIVERSAL_STOP_PHONE:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};