import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Canvas,
  Image
} from "@react-pdf/renderer";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import format from 'date-fns/format'; 
import {useSelector, useDispatch} from "react-redux";
const ryanh = require('../Media/signatures/ryanh.jpg'); 	
const mikej = require('../Media/signatures/mikey.png'); 
const blank = require('../Media/signatures/mikey.png'); 
const ericn = require('../Media/signatures/ericn.jpg'); 
const joshb = require('../Media/signatures/joshb.jpg'); 
const mylesd = require('../Media/signatures/mylesd.png'); 	
const trevort  =require('../Media/signatures/Trevort.png'); 
const owenj = require('../Media/signatures/owenj.png'); 
const colbyj = require('../Media/signatures/colbyj.png'); 
const jeremyc = require('../Media/signatures/Jeremyc.png'); 
const joeyg = require('../Media/signatures/JoeyG.png'); 

export default function TestReportPDF(props){

	const testReport = useSelector( (state) => state.testReport.testReport)
	const contact = useSelector( (state) => state.testReport.contact )
	const address = useSelector( (state) => state.testReport.address )
	const tester = useSelector( (state) => state.testReport.tester )
	const gauge = useSelector( (state) => state.testReport.gauge )
	

	const [signature, setSignature] = useState(blank)

	useEffect(()=>{
		switch(tester.tester_name.toUpperCase()){
			case 'RYAN HOTCHKISS':
				setSignature(ryanh)
				break; 
			case 'MIKE YOUNCE': 
				setSignature(mikej); 
				break; 
			case 'ERIC NEALEIGH':
				setSignature(ericn); 
				break; 
			case 'JOSH BLACKER':
				setSignature(joshb); 
				break; 
			case 'MYLES DIEHL':
				setSignature(mylesd); 
				break; 
			case 'TREVOR TAYLOR': 
				setSignature(trevort); 
				break;
			case 'OWEN JOHNSTON':
				setSignature(owenj); 
				break; 
			case 'COLBY JONES': 
				setSignature(colbyj); 
				break; 
			case 'JEREMY CASE': 
				setSignature(jeremyc); 
				break; 
			case 'JOEY GALATI': 
				setSignature(joeyg); 
				break;
			default:
				setSignature(blank); 
		}

	}, [tester.tester_name])

	var bottomMoved = 130
	var rightMoved =  28

	const styles = StyleSheet.create({
	  page: {
	    backgroundColor: "white",
	    color: "black",
	    padding: 20
	  },
	  viewer: {
	    width: window.innerWidth, //the pdf viewer will take up all of the width and height
	    height: window.innerHeight/1.2,
	  },
	  checkBox:{
	  	height: 10, 
	  	width: 10, 
	  	borderStyle: "solid", 
	  	borderColor: "black", 
	  	borderWidth: 1,
	  	margin: 0, 
	  	padding: 0
	  }, 
	  section:{
	  	padding: 3, 
	  	borderStyle: "solid", 
	  	borderColor: "black", 
	  	borderWidth: 1, 
	  	flexDirection: "column",
	  	fontSize: 10, 
	  	paddingBottom: 5
	  }, 
	  section2:{
	  	position: "relative", 
	  	top:  -130, 
	  	left: -28, 
	  	fontSize: 10, 
	  	padding: 3, 
	  	borderStyle: "solid", 
	  	borderColor: "black", 
	  	borderWidth: 1, 
	  },
	  section3:{
	  	position: "relative", 
	  	bottom:  155, 
	  	right: 24, 
	  	fontSize: 10, 
	  	padding: 3, 
	  	borderStyle: "solid", 
	  	borderColor: "black", 
	  	borderWidth: 1, 
	  	flexDirection: "row"
	  },
	  section4:{
	  	position: "relative", 
	  	bottom:  180, 
	  	right:  90, 
	  	fontSize: 10, 
	  	padding: 3, 
	  	borderStyle: "solid", 
	  	borderColor: "black", 
	  	borderWidth: 1, 
	  	flexDirection: "row",
	  	height: 30
	  },
	  section5:{
	  	position: "relative", 
	  	bottom:  170, 
	  	right:  90, 
	  	fontSize: 10, 
	  	padding: 3, 
	  	borderStyle: "solid", 
	  	borderColor: "black", 
	  	borderWidth: 1, 
	  	flexDirection: "row"
	  },
	  section6:{
	  	position: "relative", 
	  	bottom:  320, 
	  	right:  180, 
	  	fontSize: 10, 
	  	padding: 3, 
	  	borderStyle: "solid", 
	  	borderColor: "pink", 
	  	backgroundColor: "pink",
	  	borderWidth: 1, 
	  	flexDirection: "row",
	  	height: 100, 
	  	width: 380
	  },
	  column:{
	  	borderRightStyle: "solid", 
	  	borderRightWidth: 1.5, 
	  	borderRightColor: "black",
	  	flexDirection: "column"
	  },
	  column2:{
	  	borderRightStyle: "solid", 
	  	borderRightWidth: 1.5, 
	  	borderRightColor: "black",
	  	flexDirection: "column",
	  	position: "relative",
	  	bottom: 5,
	  	padding:3
	  },
	  column3:{
	  	borderRightStyle: "solid", 
	  	borderRightWidth: 1.5, 
	  	borderRightColor: "black",
	  	flexDirection: "column",
	  	justifyContent: "space-around", 
	  	position: "relative",
	  	bottom: 20,
	  	right: 65,
	  	padding:3
	  },
	  columnRepair3:{
	  	borderRightStyle: "solid", 
	  	borderRightWidth: 1.5, 
	  	borderRightColor: "black",
	  	flexDirection: "column",
	  	justifyContent: "space-around", 
	  	position: "relative",
	  	bottom:0,
	  	right: 65,
	  	padding:3
	  },
	   columnRepair4:{
	  	borderRightStyle: "solid", 
	  	borderRightWidth: 1.5, 
	  	borderRightColor: "black",
	  	flexDirection: "column",
	  	justifyContent: "space-around", 
	  	position: "relative",
	  	bottom:10,
	  	right:135,
	  	padding:3
	  },
	  columnRepair5:{
	  	flexDirection: "column",
	  	justifyContent: "space-around", 
	  	position: "relative",
	  	bottom:10,
	  	right:135,
	  	padding:3
	  },
	  column4:{
	  	justifyContent: "space-around", 
	  	flexDirection: "column",
	  	position: "relative",
	  	bottom: 20,
	  	right: 60,
	  	padding:3
	  },
	  lowerBorder:{
	  	borderBottomStyle: "solid", 
	  	borderBottomWidth: 1.5, 
	  	borderBottomColor: "black",
	  }, 
	  columnFlex:{
	  	flexDirection: "row",
	  	justifyContent: "space-between", 

	  }, 
	  rowClose :{
	  	flexDirection: "row"
	  }, 
	  modified: {
	  	color: "red"
	  }, 
	  bottom:{
	  	position: "relative", 
	  	bottom: 320, 
	  	right: 165,
	  	fontSize: 10
	  }, 
	  smallText:{
	  	fontSize: 8
	  }, 
	  overlined:{
	  	borderTopStyle: "solid", 
	  	borderTopColor: "black", 
	  	borderTopWidth: 1
	  }, 
	  image:{
	  	height: 40, 
	  	width: 150
	  }
	});



	const onX = (painterObject) =>{

      painterObject 
      .save() 
      .moveTo(1,9) 
      .lineTo(9, 1) 
      .moveTo(1,1) 
      .lineTo(9, 9)
       .stroke("red")
       
   }

   const onXWhite = (painterObject) =>{

      painterObject 
      .save() 
      .moveTo(1,9) 
      .lineTo(9, 1) 
      .moveTo(1,1) 
      .lineTo(9, 9)
       .stroke("white")
       
   }
      

	const [middleText, setMiddleText] = useState("Another Hello")
	const [existing, setExisting] = useState(false); 
	const [neww, setNew] = useState(false); 
	const [removed, setRemoved] = useState(false); 
	const [repaired, setRepaired] = useState(false); 
	const [replaced, setReplaced] = useState(false); 

	const [RP, setRP ] = useState(false); 
	const [RPDA, setRPDA] = useState(false); 
	const [RPDAII, setRPDAII] = useState(false);
	const [DC, setDC] = useState(false); 
	const [DCDA, setDCDA] = useState(false); 
	const [DCDAII, setDCDAII] = useState(false); 
	const [PVB, setPVB] = useState(false); 
	const [SVB, setSVB] = useState(false); 
	const [AVB, setAVB] = useState(false); 
	const [AG, setAG] = useState(false); 

	/*useEffect( ()=>{
		switch(props.assembly.status.toUpperCase()){
			case 'E':
				setExisting(true); 
				break; 
			case 'RM':
				setRemoved(true);  
				break; 
			case 'NEW':
				setNew(true); 
				break; 
			case 'RP': 
				setReplaced(true); 
				break; 
			default: 
				setExisting(true);  
		}
		switch(props.assembly.type.toUpperCase()){
			case 'RP': 
				setRP(true); 
				break; 
			case 'RPDA': 
				setRPDA(true); 
				break ; 
			case 'RPDAII': 
				setRPDAII(true);
				break; 
			case 'DC': 
				setDC(true); 
				break; 
			case 'DCDA': 
				setDCDA(true); 
				break; 
			case 'DCDAII': 
				setDCDAII(true); 
				break; 
			case 'PVB': 
				setPVB(true); 
				break 
			case 'SVB': 
				setSVB(true); 
				break; 
			case 'AVB':
				setAVB(true); 
				break 
			case 'AG': 
				setAG(true); 
				break; 
			default: 
				setDC(true); 

		}

	}, [])*/

	return(
		<>
		<PDFViewer style={styles.viewer}>
      		<Document>
    
        		<Page size="A5" style={styles.page}>
        			
        			<View style={ styles.section}>
						<Text> Backflow Preventer Report</Text>
						<Text> American Backflow& Plumbing Services, Inc. </Text>
					</View>
					<View style={ styles.section}>
						<View style={ styles.columnFlex}>
							<View style={ styles.columnFlex}>
								{
									neww ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text> New </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									existing ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text> Existing </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									removed ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text> Removed</Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									repaired ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text> Repaired</Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									replaced ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text> Replaced </Text>
							</View>
						</View>
						<View>
							<View style={ styles.columnFlex}>
								<View style={ styles.rowClose}>
									<Text >Property Name:</Text>
									<Text style={styles.modified} ></Text>
								</View>
								<View style={styles.rowClose}>
									<Text >Phone</Text>
									<Text style={styles.modified}> {contact.phone_number}</Text>
								</View>
							</View>
							<View style={ styles.columnFlex}>
								<View style={ styles.rowClose}>
									<Text >Contact Name:</Text>
									<Text style={styles.modified} >{contact.client_name}</Text>
								</View>
								<View style={styles.rowClose}>
									<Text >Phone</Text>
									<Text style={styles.modified}> {contact.phone_number}</Text>
								</View>
							</View>
							<View style={ styles.columnFlex}>
								<View style={ styles.rowClose}>
									<Text>Street</Text>
									<Text style={styles.modified}> { address.street} </Text>
								</View>
								<View style={styles.rowClose}>
									<Text> City: </Text>
									<Text style={styles.modified}>{ address.city}</Text>
								</View>
								<View style={styles.rowClose}>
									<Text>State:</Text>
									<Text style={styles.modified}> { address.state} </Text>
								</View>
								<View style={styles.rowClose}>
									<Text>Zipcode:</Text>
									<Text style={styles.modified}> { address.zipcode} </Text>
								</View>

							</View>
							<View style={styles.columnFlex}>
								<Text >Water Supplier: _____</Text>
								<Text>Preventer Address: _____ </Text>
							</View>
							<View style={ styles.columnFlex}>
								<View style={styles.rowClose}>
									<Text >Make:</Text>
									<Text style={styles.modified}> </Text>
								</View>
								<View style={ styles.rowClose }>
									<Text>Model:</Text>
									<Text style={styles.modified}></Text>
								</View>
								<View style={styles.rowClose}>
									<Text>Size:</Text>
									<Text style={styles.modified}> "  </Text>
								</View>
							</View>
							<View style={ styles.columnFlex}>
								<View style={styles.rowClose}>
									<Text>Serial Number: </Text>
									<Text style={ styles.modified} >  </Text>
								</View>
								<View style={ styles.rowClose}>
									<Text >Location:</Text>
									<Text style={styles.modified}>     </Text>
								</View>
								
							</View>
						</View>
						<View style={ styles.columnFlex}>

							<View style={ styles.columnFlex}>
								{
									RP  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> RP </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									RPDA  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> RPDA</Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									RPDAII  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> RPDA-II</Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									DC  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> DC </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									DCDA  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> DCDA </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									DCDAII  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> DCDA-II </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									PVB  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> PVB </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									SVB  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> SVB </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									AVB  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> AVB </Text>
							</View>
							<View style={ styles.columnFlex}>
								{
									AG  ? <Canvas style={styles.checkBox} paint={ onX } /> :  <Canvas style={styles.checkBox} paint={ onXWhite } /> 

								}
								<Text style={styles.smallText}> AG </Text>
							</View>

						</View>
					</View>
					<View style={styles.section2}>
						<Text> Approved </Text>
						<View style={ styles.columnFlex}>
							<View style={ styles.columnFlex}>
								{ testReport.approved_assembly ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} /> }
								<Text> Assembly </Text>
							</View>
							<View style={ styles.columnFlex}>
								{ testReport.approved_assembly ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} /> }
								<Text> Installation </Text>
							</View>
							<View style={ styles.columnFlex}>
								{ testReport.approved_assembly ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox}  paint={ onXWhite}/> }
								<Text> Orientation </Text>
							</View>
							<View style={ styles.columnFlex}>
								{ testReport.approved_assembly ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox}  paint={ onXWhite}/> }
								<Text> Airgap </Text>
							</View>
							<View style={ styles.columnFlex}>
								<Text> Pipesize </Text>
								{ testReport.pipesize == 0 ? <Text></Text> : <Text style={styles.modified}>{ testReport.pipesize}</Text>}
								
							</View>
							<View style={ styles.columnFlex}>
								<Text> Phys. Sep </Text>
								{ testReport.physical_separation == 0 ? <Text></Text> : <Text style={styles.modified}>{ testReport.physical_separation}</Text>}
							</View>
							
						</View>

					</View>

					<View style={styles.section3}>
						<View style={ styles.column }>
							<View>
								<Text style={styles.subtitle}>Check #1 </Text>
								<View style={styles.rowClose}>
									<Text>Pressdrop </Text>
								
									{ testReport.RPA_pressdrop == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.RPA_pressdrop }</Text>} 

								</View>
							</View>
							<View>
								<Text style={styles.subtitle}> Relief Valve </Text>
								<View style={styles.rowClose}>
									<Text>Opened At </Text>
								
									{ testReport.RPA_opened_at == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.RPA_opened_at }</Text>} 
								</View>
								<View >
									<View style={styles.rowClose}>
									{
									testReport.RPA_RV_pass ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
									}
									<Text> Passed</Text>
									</View>
									<View style={ styles.rowClose}>
									{
									testReport.RPA_pressdrop == 0 ? <Canvas style={styles.checkBox} paint={ onXWhite} /> : !testReport.RPA_RV_pass?  <Canvas style={styles.checkBox} paint={ onX}/> : <Canvas style={styles.checkBox} paint={ onXWhite} />
									}
									<Text> Failed </Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ styles.column2 }>
							<Text> Double Check </Text>
							<View >
								<View style={ styles.lowerBorder}>
								<Text> Check#1 </Text>
								<View  style={ styles.rowClose}>

									{
									testReport.RPA_typeII ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite}/>
									}
									<Text> Type II </Text>
									
								</View>
								<View style={styles.columnFlex}>
									<View  >
										<View  style= { styles.rowClose}>
											{
											testReport.RPA1_tight ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
											}
											<Text> Tight</Text>
										</View>
										<View style= { styles.rowClose}>
											{
											testReport.RPA1_leaked ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite}/>
											}
											<Text> Leaked</Text>
										</View>
										
									</View>
									<View >
										<Text> psid</Text>
										 { testReport.RPA1_psid == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.RPA1_psid }</Text>} 
									</View>
									
								</View>
								</View>
								<View>
								<Text> Check#2 </Text>
								
								<View  style={ styles.columnFlex}>
									<View>
										<View style= { styles.rowClose}>
											{
											testReport.RPA2_tight ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
											}
											<Text> Tight</Text>
										</View>
										<View style= { styles.rowClose}>
											{
											testReport.RPA2_leaked ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
											}
											<Text> Leaked</Text>
										</View>
									</View>
									<View >
										<Text> psid</Text>
										{ testReport.RPA2_psid == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.RPA2_psid }</Text>} 
									</View>
								</View>
							</View>
							</View>
						</View>
						<View style = {styles.column3} >
							<Text> Air Inlet </Text>

							<View  style={ styles.columnFlex}>
								<Text style={styles.label}> Opened At</Text>
								 {testReport.VB_air_opened_at == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.VB_air_opened_at}</Text>} 
							</View>
							<View style={styles.rowClose}>
								{
									testReport.VB_air_opened_fully ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
								}
								<Text style={styles.label}> Open Fully</Text>
							</View>
							<View style={styles.rowClose}>
								<Canvas style={styles.checkBox}  />
								<Text style={styles.label}> Did Not Open</Text>
							</View>
						</View>
						<View style = {styles.column3} >
							<Text> Check Valve</Text>

							<View  style={ styles.columnFlex}>
								<Text style={styles.label}> Pressdrop</Text>
								 {testReport.VB_check_pressdrop == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.VB_check_pressdrop} </Text>} 
							</View>
							<View style={styles.rowClose}>
								{
									testReport.VB_check_failed ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
								}
								<Text style={styles.label}>Failed</Text>
							</View>

						</View>

						<View style = {styles.column4} >
							<Text> Initial Test </Text>
							<View style={styles.rowClose}>
								{testReport.initialTest_pass ? <Canvas style={styles.checkBox}  paint={onX} /> :   <Canvas style={styles.checkBox}  paint={onXWhite} />}
								
								<Text style={styles.label}>Pass</Text>
							</View>
							<View style={styles.rowClose}>
								{ /*props.status.toUpperCase() == "CANCELLED" ||  props.status.toUpperCase() == "SCHEDULED" ?  <Canvas style={styles.checkBox}  paint={onXWhite} /> : 

									!testReport.initialTest_pass ? <Canvas style={styles.checkBox}  paint={onX} /> :   <Canvas style={styles.checkBox}  paint={onXWhite} />
								*/}
								<Text style={styles.label}>Failed</Text>
							</View>
							<View  style={ styles.rowClose}>

								<Text style={styles.label}> Date</Text>

								{ /*props.status.toUpperCase() == "CANCELLED" ||  props.status.toUpperCase() == "SCHEDULED" ?  <Text></Text> : 

									testReport.initialTest_date == "" ? <Text></Text> : <Text style={styles.modified}>  { format( new Date( testReport.initialTest_date), 'dd/MM/yyyy')} </Text>
								*/}
								 
							</View>
							<View  style={ styles.rowClose}>
								<Text style={styles.label}> System PSI</Text>
								 {testReport.initialTest_system_psid == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.initialTest_system_psid} </Text>} 
							</View>
							<View  style={ styles.rowClose}>
								<Text style={styles.label}> DMR</Text>
								 {testReport.initialTest_dmr== 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.initialTest_dmr} </Text>} 
							</View>

							
						</View>

					</View>

					<View style={styles.section4}>
						<Text> Notes </Text>
						{ testReport.comments == "0" ? <Text></Text> : <Text style={styles.modified}>  {testReport.comments }</Text>} 
					</View>

					<View style={styles.section5}>
						<View style={ styles.column }>
							<View>
								<Text >Check #1 </Text>
								<View style={styles.rowClose}>
									<Text>Pressdrop </Text>
								
									{ testReport.repair_rpa_pressdrop == null ? <Text></Text> : <Text style={styles.modified}>  {testReport.repair_rpa_pressdrop }</Text>} 

								</View>
							</View>
							<View>
								<Text style={styles.subtitle}> Relief Valve </Text>
								<View style={styles.rowClose}>
									<Text>Opened At </Text>
								
									{ testReport.repair_rpa_openedat == null ? <Text></Text> : <Text style={styles.modified}>  {testReport.repair_rpa_pressdrop }</Text>} 
								</View>
								<View >
									<View style={styles.rowClose}>
									{
										testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
									testReport.RPA_RV_pass ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
									}
									<Text> Passed</Text>
									</View>
									<View style={ styles.rowClose}>
									{
										testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
									  !testReport.repair_rpa_relief_pass?  <Canvas style={styles.checkBox} paint={ onX}/> : <Canvas style={styles.checkBox} paint={ onXWhite} />
									}
									<Text> Failed </Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ styles.column2 }>
							<Text> Double Check </Text>
							<View >
								<View style={ styles.lowerBorder}>
								<Text> Check#1 </Text>
								<View  style={ styles.rowClose}>

									{
									testReport.repair_rpa_double_typeII ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite}/>
									}
									<Text> Type II </Text>
									
								</View>
								<View style={styles.columnFlex}>
									<View  >
										<View  style= { styles.rowClose}>
											{
												testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
											testReport.repair_rpa_double_c1_tight ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
											}
											<Text> Tight</Text>
										</View>
										
										
									</View>
									<View >
										<Text> psid</Text>
										 { testReport.repair_rpa_double_c1_psid == null? <Text></Text> : <Text style={styles.modified}>  {testReport.repair_rpa_double_c1_psid }</Text>} 
									</View>
									
								</View>
								</View>
								<View>
								<Text> Check#2 </Text>
								
								<View  style={ styles.columnFlex}>
									<View>
										<View style= { styles.rowClose}>
											{

											testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
											testReport.repair_rpa_double_c2_tight ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
											}
											<Text> Tight</Text>
										</View>
									</View>
									<View >
										<Text> psid</Text>
										{ testReport.repair_rpa_double_c2_psid == null ? <Text></Text> : <Text style={styles.modified}>  {testReport.repair_rpa_double_c2_psid }</Text>} 
									</View>
								</View>
							</View>
							</View>
						</View>
						<View style = {styles.columnRepair3} >
							<Text> Air Inlet </Text>

							<View  style={ styles.columnFlex}>
								<Text style={styles.label}> Opened At</Text>
								 {testReport.repair_vb_openedat == null ? <Text></Text> : <Text style={styles.modified}>  {testReport.repair_vb_openedat}</Text>} 
							</View>
							<View style={styles.rowClose}>
								{

								testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
								testReport.repair_vb_air_opened_fully ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
								}
								<Text style={styles.label}> Open Fully</Text>
							</View>
							<View style={styles.rowClose}>
								{

								testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
								testReport.repair_vb_air_did_not_open ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
								}
								<Text style={styles.label}> Did Not Open</Text>
							</View>
						</View>
						<View style = {styles.columnRepair4} >
							<Text> Check Valve</Text>

							<View  style={ styles.columnFlex}>
								<Text style={styles.label}> Pressdrop</Text>
								 {testReport.repair_vb_check_pressdrop == 0 ? <Text></Text> : <Text style={styles.modified}>  {testReport.repair_vb_check_pressdrop} </Text>} 
							</View>
							<View style={styles.rowClose}>
								{

								testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
								testReport.repair_vb_check_failed ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
								}
								<Text style={styles.label}>Failed</Text>
							</View>

						</View>
						
						<View style = {styles.columnRepair5} >
							<Text> Repair Test </Text>
							<View style={styles.rowClose}>
								{

								testReport.repair_pass == null ? <Canvas style={styles.checkBox} paint={ onXWhite} />  : 
								testReport.repair_pass ? <Canvas style={styles.checkBox} paint={ onX} /> : <Canvas style={styles.checkBox} paint={ onXWhite} />
								}
								<Text style={styles.label}>Pass</Text>
							</View>
							
							<View  style={ styles.rowClose}>
								<Text style={styles.label}> Date</Text>
								{

								testReport.repair_pass == null ? <Text></Text>  : 
								testReport.initialTest_date == "" ? <Text></Text> : <Text style={styles.modified}>  { format( new Date( testReport.initialTest_date), 'dd/MM/yyyy')} </Text>
								}	

							</View>
							
						</View>

					</View>

					<View style={styles.bottom}>
						<View>
							<Text style={styles.smallText}>In completing and submitting this test report, the tester certifies that the assembly was tested and maintained in accordance with all applicable rules, laws, codes, and regulations of the state and water system using approved testing equipment and approved testing procedures. </Text>
						</View>
						<View >
							<View  style={ styles.columnFlex}>
								<View  >
									<Text style={ styles.modified }> {gauge.serialN}</Text>
									<Text style={ styles.overlined }> Gauge Number </Text>
								</View>
								<View  >
									<Text style={ styles.modified }> {gauge.model}</Text>
									<Text style={ styles.overlined }> Make Model</Text>
								</View>
								<View >
									<Text style={ styles.modified }> {format( new Date( gauge.calibrationDate) , "dd/MM/yyyy" ) }</Text>
									<Text style={ styles.overlined }> Calibration Date</Text>
							
								</View>
							</View>
							<View style={ styles.columnFlex}>
								<View >
									<Text style={ styles.modified }> {tester.tester_name}</Text>
									<Text style={ styles.overlined }> Tester Name </Text>

								</View>
								<View >
									<Text  style={ styles.modified }> {tester.WA_certification}</Text>
									<Text style={ styles.overlined }> WA Ceritification # </Text>
									
								</View>
								<View >
									<Text style={ styles.modified }> {tester.OR_certification}</Text>
									<Text style={ styles.overlined } > OR Certification # </Text>
								</View>
								<View style={ styles.rowClose}>
									<Canvas style={styles.checkBox} paint={ onX} />
									<Text> Water Restored </Text>
								</View>
							</View>
							<View style={ styles.columnFlex}>
								<View >
									
									<Image style={styles.image} src={ signature }/>
									<Text style={ styles.overlined } > Tester Signature </Text>
								</View>
								
							</View>
						</View>
					</View>
		
					
        		</Page>
      		</Document>
    	</PDFViewer>
		</>
	)
}