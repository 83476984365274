import * as server from "../util/universal_report";

export const SET_UNIVERSAL_REPORT_ID = "SET_UNIVERSAL_REPORT_ID"; 
export const CLEAR_UNIVERSAL_REPORT_ID = "CLEAR_UNIVERSAL_REPORT_ID"; 

export const SET_UNIVERSAL_REPORT = "SET_UNIVERSAL_REPORT"; 
export const CLEAR_UNIVERSAL_REPORT = "CLEAR_UNIVERSAL_REPORT"; 


const setReportID = (id) => ({ type: SET_UNIVERSAL_REPORT_ID, id})
const clearReportID = () => ({ type: CLEAR_UNIVERSAL_REPORT_ID })

const setReport = (obj) => ({ type: SET_UNIVERSAL_REPORT, obj})
const clearReport = () => ({ type: CLEAR_UNIVERSAL_REPORT })


export const clearAllReport = () => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let promises = []; 
		promises.push(await dispatch(clearReportID() ) )
		promises.push( await dispatch( clearReport() ) )
		Promise.all(promises).then((data, err) =>{
			resolve()
		})
	})
}

export const reduceReportID = (id) => dispatch => {
	return new Promise((resolve, reject) =>{
		dispatch( setReportID(id) )		
		resolve()
	}) 
}


export const requestReportResults = (reportID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		const response = await server.requestReportResults(reportID); 
		const data = await response.json()
		if(response.ok){
			dispatch( setReport(JSON.parse(data)) )
			resolve()		
		}
	})
}

export const sendEmail = (emailAddress, reportID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.emailReport(emailAddress, reportID); 
		const data = await response.json()
		if(response.ok){
			resolve()
		}
	})
}