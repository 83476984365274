import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {requestAssembly} from "../../../actions/report.js"

export default function Approved(){
	const assembly = useSelector( (state) => state.report_assembly)
	const report = useSelector( (state) =>state.testReport)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)


	return(
		<Container>

			{
				report ? 
					<>
						<Row>
							
							<Col>
								
								<h5>Approved</h5>
								<Form.Check type="checkbox" label ="assembly" checked = {report.approved_assembly} readOnly />
								<Form.Check type="checkbox" label ="installation" checked = {report.approved_installation} readOnly/>
								<Form.Check type="checkbox" label ="orientation" checked = {report.approved_orientation} readOnly/>
								<Form.Check type="checkbox" label ="airgap" checked = {report.approved_airgap} readOnly/>
								<Form.Check type="checkbox" label ="usc" checked = {report.approved_USC} readOnly/>
							</Col>
							<Col>
								<h5> System</h5>
									<p> <strong>DMR: </strong> <u>{report.initialTest_dmr}</u><br/>
									<strong> System PSI: </strong><u>{report.initialTest_system_psid}</u>
									</p>
									<Col>
							

								<Form.Check label = "Cleaned" checked ={report.cleaned} readOnly/>
								<Form.Check label = "Repaired" checked ={report.repaired} readOnly/>
								
								
							</Col>
							</Col>
						</Row>
					</>
				: 
					<>
					</>
			}
						
		</Container>
	)
}