import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";

export default function QBAccount() {
    const dd = useSelector((state) => state.billingDetails)
    const [hasQB, setHasQB] = useState(false)

    useEffect(() => {
        if (dd != null) {
            if (dd.qb_id == null) {
                setHasQB(false)
            } else {
                setHasQB(true)
            }
        }
    }, [dd])
    return (
        <Container >
            <br/><br/>
            <Row>
                <Col>
                {
                    hasQB ?
                            <Badge bg="success">
                                In QuickBooks
                        </Badge>
                        :
                        <Badge bg="secondary">Not in QuickBooks</Badge>
                    }
                </Col>
            </Row>
        </Container>
    )

}