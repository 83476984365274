import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import BillingInformation from "./BillingInformation"
import OtherDetails from "./OtherDetails"
import LineItems from "./LineItems"

import { useState, useEffect, useRef, useCallback } from "react";
import {
  requestInvoiceDetails
} from "../../../.././../../actions/universal_invoice"
import { useSelector, useDispatch } from "react-redux";
import {getSalesTax} from "../../../.././../../actions/invoice"


export default function InvoiceApp(){
	const invoiceID = useSelector( (state) => state.universal_invoice_id)
	const addressID = useSelector( (state) => state.addressID)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
			setLoading(true)
			dispatch( requestInvoiceDetails(invoiceID)).then((data, err) =>{
				dispatch(getSalesTax(addressID)).then((data, err) => {
					setLoading(false)
				})
			}) 

			
	}, [invoiceID])
	
	return(
		<Container>

			{
				loading ?	
					<Spinner />
					: 
					<>	
						<Row>
							<Col>
								<BillingInformation />
							</Col>
						</Row>
						<hr />
						<Row>
							<Col>
								<LineItems />
							</Col>
						</Row>

					</>

			}
			
		</Container>
	)
}