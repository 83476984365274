import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";

import CardPayment from "../card_payment/app.js"
import ServiceAddress from "./ServiceAddress"
import BillingDetails from "./BillingDetails"
import ServiceDetails from "./ServiceDetails"
import InvoiceItems from "./InvoiceItems"

export default function InvoiceApplication(){
	const invoiceID = useSelector( (state) => state.builder_id); 

	return(
		<Container className="schedule-form">>
			<Row>
				<Col>
					<ServiceDetails />
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<h4>Invoice  #{invoiceID} </h4>
				</Col>
			</Row>
			<Row >
				<Col >
					<InvoiceItems />
				</Col>
			</Row>
			<Row>
				<Col>
				
				</Col>
			</Row>
		</Container>
	)
}