import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {sendEmail} from "../../../actions/universal_test_report.js"

export default function EmailReport(){
	const [emailAddress, setEmailAddress] = useState(null); 
	const reportID = useSelector( (state) => state.reportID)
	const waterDistrict = useSelector((state) => state.waterDistrict)
	const session = useSelector((state) => state.session)
	const [loader, setLoader] = useState(false)

	const [other, setOther] = useState(true)
	const send = (event)=> {	
		event.preventDefault()
		setLoader(true)
		sendEmail(emailAddress, reportID).then((data,err) =>{
			setLoader(false)
			setEmailAddress('')
		})
	}

	return(
		<Container>
			<Row>
				<Col>
					<h5>Email Test Report </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Check 
						type = "radio" 
						name ="email" 
						label = "Water"
						onClick = {
							(event)=>{
								if(event.target.checked){
									setEmailAddress(waterDistrict.p_email)
								}
							}
						}
					/>
				</Col>

				<Col>
					<Form.Check 
						type = "radio" 
						name ="email" 
						label = "Myself"
						onClick = {
							(event)=>{
								if(event.target.checked){
									setEmailAddress(session.username)
								}
							}
						}
					/>
				</Col>
				<Col>
					<Form.Check 
						type = "radio" 
						name ="email" 
						label = "Other"
						onClick = {
							(event)=>{
								if(event.target.checked){
									setEmailAddress('')
								}
							}
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form onSubmit = { send }>
						<InputGroup>
							
							<Form.Control
								required
								type = "email"
								value = {emailAddress}
								onChange = {
									(event) => {
										setEmailAddress(event.target.value)

									}
								}

							 />
							 
								
						</InputGroup>
						<br/>
							{
								loader ?
									<Spinner />
								: 
									<Button  variant = "secondary" type= "submit"> Send </Button>

							}
						
					</Form>
				</Col>
			</Row>
		</Container>
	)
}