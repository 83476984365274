import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import TestReport from "./TestReport"
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { requestNextPop,
 requestReport, 
 requestTotalNotYetSent, 
requestTotalNotYetSentNoDate, 
 requestNextPopNoDate, 
 sendReportToReviewList
} from "../../../actions/waterDelivery.js"

import {  sendTestReportToClient } from "../../../actions/clientDelivery.js"
import WaterDetails from "./WaterDetails"
import { CheckMarkAnimation }  from "../animations.js"
import { requestReportList, requestTestReport, setAsSent, setForReview } from  "../../../actions/report";
import WaterDistrictCard from "./WaterDistrictCard"
import EmailReport from "./EmailReport"

import {format} from "date-fns"

export default function PoppingList(props){
	const [offset, setOffset] = useState(null);
	const [offsetChecker, setOffsetChecker] = useState(null)
	const [total, setTotal] = useState(null); 
	const [isDone, setIsDone] = useState(true)
	const [testLoader, setTestLoader] = useState(true); 
	const dispatch = useDispatch(); 
	const report = useSelector((state) => state.testReport)
	const reportID = useSelector( (state) => state.reportID)
	const [showCheckMark , setShowCheckMark] = useState(false)
	const [showPDF, setShowPDF] = useState(false)
	const [waterSent, setWaterSent] = useState(null)
	const [clientSent, setClientSent]  = useState(null)
	const [link, setLink] = useState('')
	const [hasLink, setHasLink] = useState(false)
	const [allowRequest, setAllowRequest] = useState(false)
	const waterDistrict =  useSelector( (state) => state.waterDistrict)
	const waterID = useSelector((state) => state.waterID)
	const dateSelected = useSelector((state) => state.deliveryDate)
	const [reason, setReason] = useState(null); 
	const [waterSelected, setWaterSelected] = useState(false)
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		if (report != null) {
			if (report.reportLink == '' || report.reportLink == null) {
				setHasLink(false)
				
			} else {
				setLink(report.reportLink)
				setHasLink(true)
			}
		}
	}, [report])




	useEffect(()=>{
		if (waterID != null) {
			requestTotalNotYetSentNoDate(waterID).then((data, err) => {
				setTotal(data)
				setWaterSelected(true)
			})
		} else {
			setWaterSelected(false)
		}
			
	}, [waterID])

	useEffect(()=>{
		console.log("This is the total: ", total)
		if(total == 0){
			setIsDone(true)
		}else{
			setOffsetChecker(0)
			setIsDone(false)
		}
	}, [total])


	useEffect(()=>{
		if(offsetChecker < 0){
			setOffset(total - 1)
		}else if (offsetChecker == total){
			setOffset(0)
		}else{
			setOffset(offsetChecker)
		}
	}, [offsetChecker])
	

	useEffect(()=>{
		console.log("This is the offset: ", offset)
		if(offset != null  && waterID != null){
			dispatch( requestNextPopNoDate(waterID, offset) ).then((data, err) => {
				setTestLoader(false)
				setReason(null)
			})	
			requestTotalNotYetSentNoDate(waterID).then((data, err) => {
				setTotal(data)
				setWaterSelected(true)
			})
		}
	}, [offset, waterID])

	/*useEffect(()=>{
		if(reportID != null){
			setTestLoader(true)
			dispatch( requestTestReport(reportID) ).then((data, err) =>{
				setTestLoader(false)
			}) 
		}
	}, [reportID])*/

	function sent(){
		dispatch( setAsSent(reportID)).then( (data, err) =>{
			setLoader(true)
			requestTotalNotYetSentNoDate(waterID).then((data, err) => {
				setTotal(data)
				setLoader(false)
				setWaterSelected(true)
			})
			setShowCheckMark(true)
		} )		
	}

	const sendToReview = (event) => {
		event.preventDefault()
		setTestLoader(true)
		setForReview(reportID, reason).then((data, err) => {
			console.log("Set it for review...");
			setReason('')
			requestTotalNotYetSentNoDate(waterID).then((data, err) => {
					setTotal(data)
					setWaterSelected(true)
					if(data == 0){

					}else{
						dispatch(requestNextPopNoDate(waterID, offset)).then((data, err) => {
							setReason('')
						})	

					}
					
					setTestLoader(false)
			})
		})	
	}

	return(
		<Container  className ="paddedSection">	
			<Modal show = {showCheckMark} centered onHide ={
					() => {
						setShowCheckMark(false) 
						if(total == 0){

						}else{
							dispatch( requestNextPopNoDate(waterID, offset) ).then((data, err) => {
								setTestLoader(false)
								setReason(null)
							})	
						}
						
						setOffset(0)
					}
				}>	
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<CheckMarkAnimation />
				</Modal.Body>
			</Modal>
			
			<Row>
				{
					waterSelected ?
						<>
							<Col className="testResults" >
								{

									isDone ?
										<>
											No more test reports for this water district
										</>
										:
										<>	
												
														<TestReport
															total={total}
															minusTotal={() => setTotal(total - 1)}
															minusOffset={() => setOffset(offset - 1)}
														/>

										</>
								}
							</Col>
					
							<Col sm md lg={3}>
								<Container>
									<Row >
										<Col>
											<p> {offset + 1} of {total}</p>
										</Col> <Col>
											{

												waterID ?
													<ButtonGroup >
														<Button size="sm" variant="secondary" onClick={() => setOffsetChecker(offset - 1)} disabled={isDone}>
															Prev </Button>
														<Button size="sm" variant="secondary" onClick={() => setOffsetChecker(offset + 1)} disabled={isDone}> Next </Button>
													</ButtonGroup>
													:
													<>
													</>
											}
										</Col>

									</Row>
									<hr/>
									<Row >
										<Col >
											{
												reportID ?
													<ButtonGroup vertical>
														
														<Button
															size="sm"
															variant="dark"
															onClick={
																() => {
																	sent()
																}
															}
														>
															{
																loader ?
																	<Spinner />
																: 
																	<> Flag As Submitted</>
															}
															
														</Button>
														{
															hasLink ?
																<Button variant="link">
																	<a href={link} target="_blank">Preview PDF</a>
																</Button>
															: 
																<>
																</>
														}

													
													</ButtonGroup>
													:
													<>
														</>
											}
											
										</Col>
									</Row>
									<hr/>
									<Row>
										<Col>
											<EmailReport />
										</Col>
									</Row>
									<br/>
									<Row>
										<hr/>
										<Col>
											<WaterDistrictCard />
										</Col>
										<br/>
									</Row>
									<br/>
									<Row>
										<hr/>
										<Col>
											{
												testLoader ?
													<Spinner />
												:
													<Form onSubmit={sendToReview} >
														<FloatingLabel label="Reason">

															<Form.Control
																type="textarea"
																value={reason}
																onChange={(event) => setReason(event.target.value)}
																required
															/>
														</FloatingLabel>
														<br />
														<Button size="sm" variant="secondary" type="submit">
															Flag for review
														</Button>
													</Form>


											}
											
										</Col>
									</Row>

								</Container>
							</Col>
						</>
						:
						<Col className="testResults">
							Select a water district
						</Col>

				}
			</Row>


		</Container>

	)

}