import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setID, clearID, requestGaugeDetails, updateGaugeDetails}  from "../../../actions/gauges"
import {format, add, sub} from "date-fns"

export default function GaugeForm () {
	const gaugeID = useSelector((state) => state.gauge_id)
	const gaugeDetails = useSelector((state) => state.gauge_details)
	const [obj, setObj] = useState(null)
	const [serial, setSerial ] = useState(null); 
	const [date, setDate] = useState(null); 
	const [make, setMake] = useState(null); 
	const [myLink, setMyLink] = useState(null);
	const [loader, setLoader] = useState(false)
	const dispatch = useDispatch();

	useEffect(()=>{
		//load gauge details
		if(gaugeID != null && gaugeID != -1){
			setLoader(true)
			dispatch( requestGaugeDetails(gaugeID) ).then((data, err) =>{
				setObj(data)
				setLoader(false)
			})
		}
	}, [gaugeID])

	useEffect( () =>{
		if(gaugeDetails != null){
			setSerial(gaugeDetails.serialN)
			setDate(format( new Date( gaugeDetails.calibrationDate), 'yyyy-MM-dd') );
			setMake(gaugeDetails.model)
			setMyLink(gaugeDetails.link)
		}

	}, [gaugeDetails])


	const update = () =>{
		if(gaugeID != null && gaugeID != -1){
			let obj = {}; 
			obj.serial = serial; 
			obj.make = make; 
			obj.calibration = date; 	
			dispatch( updateGaugeDetails(gaugeID, obj) ).then((data, err) =>{
				setObj(data)
			})
		}
	}

	return(
		<Container>

			{
				loader  ?
					<>loading... </>
				: 
					<>
						<Row>	
							<Col>
								<Form.Label> Serial Number</Form.Label>
								<Form.Control 
									value = {serial}
									onChange = {(event)=> setSerial(event.target.value) }
									onBlur = { update}
								/>

								<Form.Label> Calibration Date</Form.Label>
								<Form.Control 
									value={date}  
									type="date" 
									onChange = {(event)=> setDate(event.target.value) }
									onBlur = { update}
								/>
								<Form.Label> Make/Model</Form.Label>
								<Form.Control  
									value = {make} 
									onChange = {(event)=> setMake(event.target.value) }
									onBlur = { update}
								/>

								{
									myLink ?
										<Button variant = "link"> 

											<a href= {myLink} target = '_blank'> See Calibrations</a> 
										</Button>
									: 
										<> </>
								}
								
							</Col>
						</Row>
					</>
			}
						
		</Container>
	)
}