import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {setAddress, setContact, setDetails, setServices} from "../../../../actions/newstop"
import ServiceLine from "./ServiceLine" 

export default function ServicesForm(){
	const services = useSelector( (state) => state.newstop_services)
	const dispatch = useDispatch(); 
	function newAssembly(){
		let newlist = [...services]
		let newObj = {
			location: null,
			serial: null,
			size: 1, 
			make: null, 
			model: null,
			hazard: null, 
			service: "TEST"
		}
		newlist.push(newObj)
		dispatch ( setServices(newlist) ).then((data, err) =>{

		})
	}

	const renderServices = services.map( (serv, index) =>{	
		return(
			<ServiceLine service = {serv} index = {index}/>
		)
	})
	return(
		<Table size="sm" responsive borderless striped>
			<thead> 
				<tr>
					<th>
						location
					</th>
					<th>
						S/N
					</th>
					<th>
						Size	
					</th>
						
				
					<th>
						Make
					</th>
					<th>
						Model
					</th>
					<th>
						Hazard
					</th>
					<th>
						Service
					</th>
				</tr>
			</thead>
			<tbody>
				
				{renderServices} 
				<tr>
					<td>
						<Button 
							size = "sm" 
							variant = "light"
							onClick = { () => newAssembly()  }
						>+</Button>
					</td>
				</tr>
				
			</tbody>
		</Table>	
	)
}