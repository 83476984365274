import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import { requestPPReports } from "../../../actions/testReports.js"
import { format } from "date-fns"
import { clearclear } from "../../../actions/waterDelivery"; 

import TestersApp from "../accounts/TestersApp"
import GaugesApp from "../accounts/GaugesApp"

import ByDay from "./ByDay"
import ByDistrict from "./ByDistrict"
import TestReportDump from "../testReportDump/TestReportDump"
import PdfFails from "./PdfFails/Application"
import FailedApp from "./failedapp/FailedApp"
import AMGApp from "./amg/AMGApp"
import WaterDistrictManagement from "../districts/WaterDistrictManagement"

export default function WaterDeliveryApplication(){
	const [appToShow, setAppToShow] = useState(<ByDistrict />)
	const [currentTab, setCurrentTab] = useState("DISTRICT"); 
	const dispatch = useDispatch(); 

	useEffect(()=>{	
		//clear the state for district 
		switch(currentTab.toUpperCase()){
			case "ALL": 
				setAppToShow(<ByDay/>)
				break; 
			case "DISTRICT": 
				setAppToShow(<ByDistrict />)
				break;
			case "DUMP": 
				setAppToShow(<TestReportDump />)
				break;
			case "TA": 
				setAppToShow(<TestersApp />)
				break; 
			case "FAILED": 
				setAppToShow(<FailedApp />)
				break; 
			case "AMG": 
				setAppToShow(<AMGApp />)
				break; 
			case "GG": 
				setAppToShow(<GaugesApp />)
				break; 
			case "FAILS": 
				setAppToShow(<PdfFails />)
				break;
			case "WD":
				setAppToShow(
					<Container>
						<WaterDistrictManagement />
					</Container>
				)
				break;
			default:
				setAppToShow(<ByDay />)
				break;
		}
	}, [ currentTab])

	return(
		<Container fluid>

			<Row>
				<Col md={1} lg={1}>
					<Nav
						variant = 'underline'
				      	defaultActiveKey="DISTRICT"
				      	id="uncontrolled-tab-example"
				      	className="waterNav"
				      	onSelect = { (eventKey) => {
				      		dispatch( clearclear()).then((data, err)=> {
				      			setCurrentTab(eventKey)
				      		});
				      		
				      	}}

					>	
					     <Nav.Item  title="By District" mountOnEnter unmountOnExit >
					     	<Nav.Link eventKey="DISTRICT"> By District</Nav.Link>
					     </Nav.Item>
					     <Nav.Item title="Failed" mountOnEnter unmountOnExit >
					     	<Nav.Link eventKey="FAILED" >Failed Reports</Nav.Link>
					     </Nav.Item>
					 
					     <Nav.Item  title="Water Districts" mountOnEnter unmountOnExit disabled>
					     	<Nav.Link eventKey="WD" > Water Districts </Nav.Link>
					     </Nav.Item>
				    </Nav>
				</Col>
				<Col sm md lg={8}>
				
						{appToShow}

				</Col>
				
			</Row>
		</Container>
	)
}