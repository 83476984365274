import {
  GET_INVOICES
} from "../../actions/invoice";

const _nullInvoiceList = { 
  invoicelist: []
}
                
export default (state = _nullInvoiceList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_INVOICES:
      return list;
    case "": 
      return _nullInvoiceList; 
    default:
      return state;
  }
};