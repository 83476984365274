import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";


export default function InvoiceDetails(){
	const [edit, setEdit] = useState(false)

	return(
		<Container>

			<Row>
				<Col xm sm md lg = {9}>
				{ 
					edit ?
						<>
							<Form.Label> Payment Amount</Form.Label>
							<Form.Control />
						</>
					: 
						<>
							<h6> Payment Amount </h6>
							<h4> $xxx </h4>
						</>
				}
				</Col>
			
				<Col>
					<Container>
						<br/>
					<Button onClick = { () => setEdit( !edit)}>
							{
								edit ? 
									<> Save
									</>
								: 
									<>Edit</>
							}
					</Button>
					</Container>
				</Col>
			</Row>
			<Row>
				
			</Row>
		</Container>
	)
}

