import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import WaterDistrictSearchBar from "./WaterDistrictSearchBar"
import WaterDistrict from "./WaterDistrict"

export default function WaterDistrictManagement() {

	const currentID = useSelector((state) => state.district_id)
	const [show, setShow] = useState(false)
	useEffect(() => {
		if (currentID != null) {
			setShow(true)
		} else {
			setShow(false)
		}
	}, [currentID])

	return (
		<Container className="main-container-scroll" >
			<br />
			<Row>
				<Col>
						<h3> Water District Management </h3>
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col>
					<WaterDistrictSearchBar />
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col>
					{
						show ?
							<WaterDistrict />
							:
							<></>

					}
					
				</Col>
			</Row>
		</Container>

	)	
}