import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { format } from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useSelector, useDispatch } from "react-redux";
import {
    cancelStop,
    requestServices,
    updateStopDetails,
    requestStopDetails
} from "../../../.././../../actions/universal_stop"
import { setAsClientSent, getReportLog, setAsWaterSent} from "../../../.././../../actions/customerApp.js"
import {determineReportID, sendReportToSelf} from "../../../.././../../actions/waterDelivery.js"
import TestReport from "../../../../testReports/TestReport"
import DisplayResults from "./DisplayResults"

import {
    requestReportResults, reduceReportID
} from "../../../.././../../actions/universal_test_report"



export default function ServiceRow(props) {
    const [initial, setInitial] = useState('')
    const [final, setFinal] = useState('')
    const [myReport, setMyReport] = useState(null)
    const [exists, setExists] = useState(false)
    const [hasData, setHasData] = useState(false)
    const dispatch = useDispatch(); 
    const [showReport, setShowReport] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false);
    const tt = useRef(null)
    const stopID = useSelector((state) => state.universal_stopID)

    useEffect(() =>{
        if(props.row.initialTest_pass){
            setInitial("PASSED")
        }else if( props.row.initialTest_fail){
            setInitial("FAILED")
        }else{
            setInitial("---")
        }

    }, [props])

    useEffect(() =>{
        if(props.row.repair_pass){
            setFinal("PASSED")
        }else if( props.row.repair_fail){
            setFinal("FAILED")
        }else{
            setFinal("---")
        }

    }, [props])

    return (
        <>
        <Modal show = {showReport} size = "xl" centered backdrop = "static" backdropClassName = "modal-backdrop dark-background">
                <Modal.Header closeButton onHide = {() => {setShowReport(false)} }>
                    Results
                </Modal.Header>
                <Modal.Body>
                    <DisplayResults />
                </Modal.Body>
            </Modal>

        <tr>
            <td>
            
                <Button
                        onClick={() =>
                        {
                            dispatch( reduceReportID(props.row.testReportID) ).then((data, err) =>{
                                setShowReport(true) 
                            })
                        }

                     } variant = "link"> {props.row.testReportID} </Button>  



                
            </td>
            <td>{props.row.serial_number}</td>
            <td>{initial} </td>
            <td>{final}</td>
            <td>{props.row.status}</td>
            <td>
                <Form.Check  label = "" 
                    disabled={ disabled}
                    onClick={(event) => {
                        setAsWaterSent(props.row.testReportID, event.target.checked).then((resolve, reject) => {
                            console.log("Done!")
                            dispatch( requestServices(stopID)) .then((data, err) =>{
                                console.log("Done!")
                            })

                        }) 
                    }}
                    checked = {props.row.waterSent}
                />
                    {
                        props.row.waterSent ?
                            <> { format( new Date( props.row.waterSentOn) , "MM-dd-yyyy") }</>
                        : 
                            <> </>
                    }

             </td>
            <td>
                <Form.Check label=""
                    disabled={ disabled }
                    onClick={(event) => {
                        setAsClientSent(props.row.testReportID, event.target.checked).then((resolve, reject) => {
                            console.log("Done!")
                             dispatch( requestServices(stopID)) .then((data, err) =>{
                                console.log("Done!")
                            })
                        })
                    }}
                    checked={
                            props.row.clientSent
                    }
                />

                {
                        props.row.clientSent ?
                            <> { format( new Date( props.row.clientSentOn) , "MM-dd-yyyy") }</>
                        : 
                            <> </>
                    }
                    
            </td>
            <td>
                <Button 
                    size='sm'
                    ref={tt}
                    variant="info"
                    disabled={ sent}
                    onClick = {
                        () =>{
                            setSending(true)
                            setSent(true)
                            sendReportToSelf(props.row.testReportID).then((data, err) =>{
                                setSending(false)
                            })
                        }
                    }
                > 
                    {
                        sending? 
                            <Spinner />
                        : 
                            <> Email Report to Self </>
                    }
                </Button>
                                                        
            </td>
            </tr> 
            <tr>
                <td colSpan={ 8}>
                    {props.row.reason}
                </td>
            </tr>
        </>
    )
}