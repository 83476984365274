import {
  RECEIVE_INVOICE_LINE_ITEMS,  
} from "../../actions/invoice.js";

const _nullInvoiceLineItems = []; 
                
export default (state = _nullInvoiceLineItems, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_INVOICE_LINE_ITEMS:
      return list;
    case "": 
      return _nullInvoiceLineItems; 
    default:
      return state;
  }
};