import {
  GET_WATER_DISTRICT_FILTER
} from "../../actions/dump";

const _nullDistrict = null; 

export default (state = _nullDistrict, { type, district }) => {
  Object.freeze(state);
  switch (type) {
    case GET_WATER_DISTRICT_FILTER:
      return district;
    case "": 
      return _nullDistrict; 
    default:
      return state;
  }
};