import {
  RECEIVE_TESTERS,
  CLEAR_TESTERS
} from "../../actions/dispatch";

const _nullTesters = []
          
export default (state = _nullTesters, { type, testerlist }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_TESTERS:
      return testerlist;
    case CLEAR_TESTERS:
      return _nullTesters; 
    default:
      return state;
  }
};