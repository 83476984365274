import {
  SET_PAYMENT, 
  CLEAR_PAYMENT
} from "../../actions/scheduling";

const _nullPayment = null; 
                
export default (state = _nullPayment, { type, payment }) => {
  Object.freeze(state);
  switch (type) {
    case SET_PAYMENT:
      return payment;
    case CLEAR_PAYMENT: 
      return _nullPayment
    case "": 
      return _nullPayment; 
    default:
      return state;
  }
};