import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {format} from "date-fns"
import { getBaseURL, processCardPayment} from "../../../actions/card_payment"
import ProcessingResults from "./ProcessingResults"

export default function ProcessingForm(){
	const dispatch = useDispatch(); 
	const [years, setYears] = useState([])
	const [name, setName] = useState('test name')
	const [cardNumber, setCardNumber] = useState('5111005111051128')
	const [month, setMonth] = useState('10')
	const [year, setYear] = useState('2025')
	const [zipcode, setZipcode] = useState('97011')
	const [cvc, setCvc] = useState('1234')
	const [message, setMessage] = useState("") 
	const tokenUrl = useSelector( (state) => state.card_url)
	const card_token = useSelector( (state) => state.card_token)
	const [showErrors, setShowErrors] = useState(true)


	useEffect(()=>{
	
	}, [card_token])

	const months = [
		{ name: "January", digit: "01"},
		{ name: "February", digit: "02"}, 
		{ name: "March", digit: "03"}, 
		{ name: "April", digit: "04"}, 
		{ name: "May", digit: "05"}, 
		{ name: "June", digit: "06"}, 
		{ name: "July", digit: "07"}, 
		{ name: "August", digit: "08"}, 
		{ name: "September", digit: "09"}, 
		{ name: "October", digit: "10"}, 
		{ name: "November", digit: "11"}, 
		{ name: "December", digit: "12"}, 
		 ]

	function generateNext50Years() {
	  const today = new Date();
	  const yearsArray = []; 
	  for (let i = 0; i < 50; i++) {
	    const futureDate = new Date(today); // Create a new date instance
	    futureDate.setFullYear(today.getFullYear() + i); // Add `i` years to the current year
	    yearsArray.push(format( futureDate, "yyyy")); // Store the future date in the array
	  }
	  return yearsArray;
	}

	useEffect(()=>{
		let newarr = generateNext50Years()
		setYears(newarr)
	}, [])

	const processCard = (event) =>{
		event.preventDefault()
		if(!month || !year){
			setMessage("Please select a month/year")
		}else{
			let card = {}
			card.name = name; 
			card.number = cardNumber; 
			card.expMonth = month; 
			card.expYear = year; 
			card.cvc = cvc; 
			console.log("This is the card: ", card)
			dispatch( processCardPayment(card, tokenUrl, 50) ).then((data, err) =>{

			})
		}
	}

	const renderYears = years.map( (yy) => <option value = {yy} > {yy} </option>)

	const renderMonths = months.map( (mm) => <option value = {mm.digit}> {mm.name} </option>)

	return(
		<Container>
			{
				showErrors ? 
					<> 
						<ProcessingResults />
					</>
				: 
					<> </>

			}

				{message}
			<Form onSubmit = {processCard}>
			<Row>
				<Col>
					<h6> Credit Card Details </h6> 
					
				</Col>
			</Row>
			<Row>
				<Col>
					<FloatingLabel label = "Name">
						<Form.Control 
							value = {name}
							onChange = {
								(event) =>  setName(event.target.value)

							} 
							maxLength = "50"
							required
						/>
					</FloatingLabel>

				</Col>
				<Col>
					<FloatingLabel label = "Number">
						<Form.Control 
							value = {cardNumber}
							onChange = {
								(event) =>  setCardNumber(event.target.value)

							} 
							maxLength = "20"
							required
						/>
					</FloatingLabel>
				</Col>
			</Row>
			<Row>

			</Row>
			<Row>
				<Col xm sm md lg = {3}>
					<InputGroup size= "sm">
						<InputGroup.Text> Month</InputGroup.Text>
						<Form.Select value = {month} onChange = {(event) => setMonth( event.target.value)} required>
							<option> </option>
							{renderMonths}
						</Form.Select>
					</InputGroup>
					<InputGroup size= "sm">
						<InputGroup.Text> Year</InputGroup.Text>
						<Form.Select value = {year} onChange = {(event) => setYear( event.target.value)}>
							<option></option>
							{renderYears}
						</Form.Select>
					</InputGroup>

				</Col>
				<Col>
					<FloatingLabel label = "Zipcode">
					<Form.Control 
						value = { zipcode}
						onChange = {(event) => setZipcode(event.target.value)}
						required 
						maxLength = "10"
					/>
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel label = "CVC">
					<Form.Control 
						value = {cvc}
						onChange = {(event) => setCvc(event.target.value)}
							required 
						maxLength = "4"
					/>
					</FloatingLabel>	
				</Col>
			</Row>
			<br/>
			<Row>
				<Button type ="submit"> Pay $xxx</Button>
			</Row>
			</Form>
		</Container>
	)
}

