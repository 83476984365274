import {
  SET_SELECTED_WO , CLEAR_SELECTED_WO
} from "../../actions/workorder.js";

const _null = null
                
export default (state = _null, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SELECTED_WO:
      return id;
    case CLEAR_SELECTED_WO: 
      return _null;
    case "": 
      return _null; 
    default:
      return state;
  }
};