import {
  RECEIVE_DATES,  
  CLEAR_DATES
} from "../../actions/stops";

const _nullDates = { 
  dates: []
}
                
export default (state = _nullDates, { type, datelist }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_DATES:
      return datelist;
    case CLEAR_DATES: 
      return _nullDates; 
    default:
      return state;
  }
};