import * as server from "../util/employeeServer";

export const SET_TESTER_DETAILS = "SET_TESTER_DETAILS"
export const CLEAR_TESTER_DETAILS = "CLEAR_TESTER_DETAILS"
export const SET_EMPLOYEE_SEARCH = "SET_EMPLOYEE_SEARCH"
export const SET_GAUGE_LIST = "SET_GAUGE_LIST"
export const SET_TESTER_ID = "SET_TESTER_ID"
export const CLEAR_TESTER_ID = "CLEAR_TESTER_ID"
export const SET_TESTER_LIST = "SET_TESTER_LIST"


const receiveTesterList = (list) =>({
	type: SET_TESTER_LIST, 
	list
})
const receiveGaugeList = (list) =>({
	type: SET_GAUGE_LIST, 
	list
})
const receiveTesterID = (id) => ({
	type: SET_TESTER_ID, 
	id
})
const ccTesterID = () => ({
	type: CLEAR_TESTER_ID
})


const receiveEmployeeSearch = (list) => ({
	type: SET_EMPLOYEE_SEARCH, 
	list
})

const receiveEmployeeDetails = (details) => ({
	type: SET_TESTER_DETAILS, 
	details 
})
const ccEmployeeDetails = () => ({
	type: CLEAR_TESTER_DETAILS
})

export const updateGauge = (gaugeID, obj) => {
	
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateGauge(gaugeID, obj); 
		const data = await response.json(); 
		if(response.ok){
			
			resolve()
		}
	})
}

export const updateTester = (testerID, obj) => dispatch => {
	try{
		return new Promise( async (resolve, reject) =>{
			const response = await server.updateTester(testerID, obj); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveEmployeeDetails(JSON.parse(data).details) )
				resolve(JSON.parse(data).details)
			}
		})
	}catch(e){
		console.log("The search failed...")
	}
}

export const setTesterID = (testerID) => dispatch =>{
	try {
		return new Promise(async (resolve, reject) => {
			if (testerID == null) {
				dispatch(ccTesterID(testerID));
				resolve()
			} else {
				dispatch(receiveTesterID(testerID));
				resolve()
			}
			
		})
	}catch(e){
		console.log("The search failed...")
	}
}
export const requestTesterList = () => dispatch => {
	try{
		return new Promise( async (resolve, reject) =>{
			const response = await server.requestTesterList(); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveTesterList(JSON.parse(data).list) )
				resolve()
			}
		})
	}catch(e){
		console.log("The search failed...")
	}
}
export const requestGaugesList = () => dispatch => {
	try{
		return new Promise( async (resolve, reject) =>{
			const response = await server.requestGaugeList(); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveGaugeList(JSON.parse(data).list) )
				resolve()
			}
		})
	}catch(e){
		console.log("The search failed...")
	}

}

export const clearDetails = () => dispatch => {
	return new Promise((resolve, reject) => {
		dispatch(ccEmployeeDetails())
		resolve()
	})
}
export const requestTesterDetails = (testerID) => dispatch =>{
	try{
		return new Promise(async (resolve, reject) => {
			
				const response = await server.requestTesterDetails(testerID);
				const data = await response.json();
				if (response.ok) {
					dispatch(receiveEmployeeDetails(JSON.parse(data).details))
					resolve(JSON.parse(data).details)
				}
			
		})
	}catch(e){
		console.log("The search failed...")
	}

}
export const requestEmployeeSearch = (query) => dispatch => {
	try{
		return new Promise( async (resolve, reject) =>{
			const response = await server.requestEmployeeSearch(query); 
			const data = await response.json(); 
			if(response.ok){
				dispatch( receiveEmployeeSearch(JSON.parse(data).list) )
				resolve()
			}
		})
	}catch(e){
		console.log("The search failed...")
	}
} 