import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
  requestLineItemDetails, removeLineItem, requestLineItems, updateLineItem
} from "../../../.././../../actions/universal_invoice"



function Display(props){
	const [obj, setObj] = useState(null)
	const [description, setDescription] = useState(null)
	const [quantity, setQuantity] = useState(null)
	const [price, setPrice] = useState(null)
	const [taxable, setTaxable] = useState(null)
	const [amount, setAmount] = useState(null)
	const [edit, setEdit] = useState(false)
	const tax_rate = useSelector( (state) => state.invoice_tax_rate)
	useEffect( ()=>{

		if(props.item.id){
			requestLineItemDetails(props.item.id).then((data, err) =>{
				console.log("Item details: ", data)
				setObj(data)
				setDescription( data.description)
				setQuantity( data.quantity)
				setPrice ( data.unitPriceDefined )
				setTaxable( data.taxable )
				setAmount( data.amount ) 
			})
		}
	}, [])

	useEffect(()=>{
		if(taxable){
			setAmount( price * quantity + ( tax_rate.rate * (price * quantity) ) )
		}else{
				setAmount( price * quantity )
		}
	}, [price, quantity, taxable])

	return(
		<>{
			obj ? 
				<>
				
						<td>
								{description}

						</td>
						<td> 
								{quantity}
						</td>
						<td> 
								${price}
						</td>
						<td> 
								<Form.Check
								size = "sm"
								checked = {taxable}
								readOnly
							 /> 
						</td>
						<td> 
								${amount}
						</td>
				
			</>
			: 
				<>
					no data to show...
				</>

		}
		</>
		
	)

}

export default function LineItem(props){
	const [obj, setObj] = useState(null)
	const [description, setDescription] = useState(null)
	const [quantity, setQuantity] = useState(null)
	const [price, setPrice] = useState(null)
	const [taxable, setTaxable] = useState(null)
	const [amount, setAmount] = useState(null)
	const [edit, setEdit] = useState(false)
	const tax_rate = useSelector( (state) => state.invoice_tax_rate)
	const invoiceID = useSelector( (state) => state.universal_invoice_id)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	useEffect( ()=>{
		if(props.item.id){
			requestLineItemDetails(props.item.id).then((data, err) =>{
				console.log("Item details: ", data)
				setObj(data)
				setDescription( data.description)
				setQuantity( data.quantity)
				setPrice ( data.unitPriceDefined )
				setTaxable( data.taxable )
				setAmount( data.amount ) 
			})
		}
	}, [])

	useEffect(()=>{
		if(taxable){
			setAmount( price * quantity + ( tax_rate.rate * (price * quantity) ) )
		}else{
				setAmount( price * quantity )
		}
	}, [price, quantity, taxable])

	const removeItem = () =>{	
		removeLineItem(props.item.id).then( (data, err) =>{
			dispatch( requestLineItems(invoiceID) ).then((data, err) =>{
			})
		})
	}

	const update = (event) =>{
	  console.log("updating .. ")
		let bag = {}
		bag.description = description; 
		bag.quantity = quantity; 
		bag.price = price; 
		bag.taxable = taxable; 
		bag.amount = amount
		setLoading(true)
		updateLineItem(props.item.id, bag).then((data, err) =>{
			dispatch( requestLineItems(invoiceID) ).then((data, err) =>{
				setLoading(false) 
				setEdit(false)
			})
		})	
	}


	return(
		<tr>
		{
			edit ? 
				<>
					{
						loading ?
								<>
									<Spinner />
										
								</>
						: 
							<>
								<td>
											<Form.Control 
												size = "sm"
												value = { description }
												onChange = {(event) => setDescription(event.target.value)}
												
											/>

										</td>
										<td> 
											<Form.Control 
												size = "sm"
												type = "number"
												step = {1.0}
												min = {1}
												value = { quantity  }
												onChange = {(event) => setQuantity(event.target.value)}
											
											/> 
										</td>
										<td> 
											<InputGroup size = "sm">
												<InputGroup.Text >$</InputGroup.Text>
												<Form.Control 
												size = "sm"
													type = "number"
													step = {0.05}
													value = { price  }
													onChange = {(event) => setPrice(event.target.value)}
												
												/> 
											</InputGroup>
										</td>
										<td> 
											<Form.Check
												size = "sm"
												checked = {taxable}
												onChange = {(event) => setTaxable(event.target.checked) }
											 /> 
										</td>
										<td> 
											<InputGroup size = "sm">
												<InputGroup.Text>$</InputGroup.Text>
														<Form.Control 
															size = "sm"
															value = {amount}
															readOnly
														/> 
											</InputGroup>
										</td>
									
									
							</>

					}
				</>
							
			: 
				<Display item = {props.item}/>

	}
	<td> 
		<ButtonGroup>
			{
				edit ? 
					<Button size ="sm" onClick = { update }>Save </Button>
				: 
					<Button 
					size = "sm" 
					variant = "link"
					onClick = { ()=> setEdit(true)}
					> Edit </Button>
			}
				
				<Button 
					onClick = { removeItem }
					variant = "secondary" size= "sm">X</Button> 
		</ButtonGroup>
	</td>
</tr>

	)
}