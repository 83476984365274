import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

import {format, add, sub} from "date-fns"
import WaterCard from "./WaterCard"
import WaterDistrictCard from "./WaterDistrictCard"
import PoppingList from "./PoppingList"

import { 
	getList, 	
getTotal, 
getTotals, 
determineDate, 
getPrintable, 
requestSharedLink, 
sendEmails, 
sendEmailsNoDate, 
getTotalSent, 
getTotalPDFed,
getTotalInTransit, 
requestWaterDistrictList,
setDistrictID
} from "../../../actions/waterDelivery.js"
import GetEmail from "./GetEmail"
import { clearclear } from "../../../actions/waterDelivery"; 


/*By district app*/
export default function ByDistrict(){
	const dispatch = useDispatch(); 
	const report = useSelector((state) => state.testReport)
	const waterD = useSelector( (state) => state.waterDistrict)
	const [loader, setLoader] = useState(false)
	const districtlist = useSelector( (state) => state.waterlist)
	const [currentWaterDistrict, setCurrentWaterDistrict] = useState(null)	
	const [selectedItem, setSelectedItem] = useState(null);
	const [offset, setOffset] = useState(0);
	const [show, setShow] = useState(false)
	const [showModal, setShowModal] = useState(false)

	
	useEffect(()=>{
		if(currentWaterDistrict != null){
			dispatch( setDistrictID(currentWaterDistrict) ).then((data, err) =>{
				console.log("District ID  updated! ")
			})
			setOffset(0)
		}
	}, [currentWaterDistrict])

	useEffect(()=>{
		if(show){
			setLoader(true)
			dispatch( requestWaterDistrictList()).then((data, err) =>{
				console.log("Done getting water districts!"); 
				setLoader(false)
			})
		}	
	}, [show])

	const handleItemClick = (index) => {
    	setSelectedItem(index === selectedItem ? null : index);
 	 };
 	 
	const renderDistricts = districtlist.map( (dd, index) =>{
		let myClass = "light"
		let myid = `item${dd.id}`

		return(
		
				<ListGroup.Item  key ={index} id = {myid} 
				style={{ backgroundColor: index === selectedItem ? 'lightgreen' : 'white' }}
				onClick = {
					()=>{
						setCurrentWaterDistrict(dd.waterDistrictID)
						handleItemClick(index)
					}
				} >
					<Row>
						<Col>
							{dd.waterName}
						</Col> 

						<Col>
							{dd.delivery_method}
						</Col>
						<Col>
							{dd.total}
						</Col>
					</Row>
				</ListGroup.Item>
		)
	})



	return(

		<Container className="main-container">
			<Modal show = {showModal} centered size="sm">
				<GetEmail closeModal = {() => setShowModal(false)}/>
			</Modal>

			<Offcanvas placement = "start" show={show}  onHide = {()=>setShow(false)}>
		        <Offcanvas.Header closeButton>
		          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
		        </Offcanvas.Header>
		        <Offcanvas.Body>
		         <ListGroup className = "scrollable-list">
								{renderDistricts}
				</ListGroup>
		        </Offcanvas.Body>
		      </Offcanvas>
			<Row >
				<Col sm md lg = {3}>
					<h6 ><strong>TEST REPORTS READY TO BE SENT</strong></h6>
				</Col>
				<Col sm md lg = {2}>
					<Button   variant="secondary" size= "sm" onClick = {() => setShow(true)}>Select a water district</Button>
		
				</Col>
			</Row>
			<Row >

				<Col>	
				<hr/>
					<PoppingList />
				</Col>	
			</Row>
		</Container>
	)
}