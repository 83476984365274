import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import AssemblyCard from "./AssemblyCard"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import StopCard from "./StopCard"
import { determineID, requestStops } from "../../../../actions/addressApp"
import { reduceID,  clearStop} from "../../../../actions/universal_stop" 
import { reduceInvoiceID, clearAllInvoice } from "../../../../actions/universal_invoice" 
import { requestTesterNames } from "../../../../actions/dispatch"
import StopsApp from "./stopcard/app"


/*function StopsApp() {
	useEffect(() => {
		console.log("Opened item...")
	}, [])
	const stopID = useSelector((state) => state.universal_stopID)
	return (
		<>
			{stopID}
		</>
	)
}*/

export default function DisplayStops() {
	const stoplist = useSelector((state) => state.address_stoplist )
	const addressID = useSelector((state) => state.addressID)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [noStops, setNoStops] = useState(true)
	const [selectedItem , setSelectedItem] = useState(null)

	useEffect(()=>{
		if(addressID != null){	
			setLoader(true)	
			dispatch( requestStops(addressID) ).then((data, err) =>{	
				setLoader(false)
			})
		}
	}, [addressID])

	useEffect(()=>{
		if(stoplist.length > 0 ){
			setNoStops(false)
		}else{
			setNoStops(true)
		}
	}, [stoplist])


	const renderStops = stoplist.map( (stop, index) =>{
		return (
			<StopCard stop={stop} index={index} />
		)

	})

	useEffect(() => {
		if (selectedItem != null) {
			dispatch(reduceID(stoplist[selectedItem].id))
			dispatch(reduceInvoiceID(stoplist[selectedItem].invoiceID)) 
		}
	}, [selectedItem])

	const loadBody = (eventKey) => {
		console.log("OPENED ONSELECT ", eventKey)
		setSelectedItem(eventKey)
	}

	useEffect(() => {
		dispatch(requestTesterNames()).then((data, err) => {
		})
	}, [])
	return(
		
		<Container>
			<Row>
				<Col xs={3} sm md lg={3} className= "stop-list">
					{
						loader ?
							<>
								<Spinner />
							</>
							:
							<>
								{
									noStops ?
										<> No stops scheduled </>
										:
										<>
											<ListGroup  variant = "flush">
												{renderStops}
											</ListGroup>


										</>
								}
							</>


					}

				</Col>
				<Col>
					<StopsApp />
				</Col>
				
			</Row>
		

		</Container>
		
	)
}