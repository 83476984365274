import {
  SET_ITEM_LIST, 
  CLEAR_ITEM_LIST
} from "../../actions/scheduling";

const _nullList =  []; 
                
export default (state = _nullList, { type, itemlist }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ITEM_LIST:
      return itemlist;
    case CLEAR_ITEM_LIST: 
      return _nullList
    case "": 
      return _nullList; 
    default:
      return state;
  }
};