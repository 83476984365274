import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import GaugeRow from "./GaugeRow"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {setID, clearID, requestGaugeDetails, updateGaugeDetails, createGauge}  from "../../../actions/gauges"

export default function NewGauge(props) {

	const [serial, setSerial ] = useState(null); 
	const [date, setDate] = useState(null); 
	const [make, setMake] = useState(null); 
	const dispatch = useDispatch(); 

	const create = (event)=>{
		event.preventDefault(); 
		console.log("New Gauge")
		let obj = {}
		obj.serial = serial; 
		obj.make = make; 
		obj.calibration = date; 
		dispatch( createGauge(obj) ).then((data, err) =>{
			props.closeModal(); 
		})
	}
	return(
		<Container>
			<Form onSubmit={create}>

				<Form.Label> Serial Number</Form.Label>
						<Form.Control 
							value = {serial}
							onChange = { (event)=> setSerial(event.target.value) }
						/>

						<Form.Label> Calibration Date</Form.Label>
						<Form.Control 
							value={date}  
							type="date" 
							onChange = { (event)=> setDate(event.target.value) }
							
						/>
						<Form.Label> Make/Model</Form.Label>
						<Form.Control  
							value = {make} 
							onChange = { (event)=> setMake(event.target.value) }
						
						/>

					<hr/>
					<Button type="submit">
					 	Create
					</Button>
			</Form>
			
		</Container>
	)
}