import {
  SET_SERVICES_REPORT_DATE
} from "../../actions/servicesReport";
import {format} from "date-fns"

const _nullDate =  format( new Date(), "yyyy-MM-dd"); 
                
export default (state = _nullDate, { type, date }) => {
  Object.freeze(state);
  switch (type) {
    case SET_SERVICES_REPORT_DATE:
      return date;
    case "": 
      return _nullDate; 
    default:
      return state;
  }
};