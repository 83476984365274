import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import WaterDetails from "./WaterDetails"
import TestReport from "./TestReport"
import PoppingList from "./PoppingList"
import Spinner from "react-bootstrap/Spinner"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestWaterDistrictsMail, getDistrict, requestWaterDistrictsOnline} from "../../../actions/waterDistricts"
import { getList, getTotal, getTotals, determineDate} from "../../../actions/waterDelivery.js"

export default function OnlineInput(props){
	const dispatch = useDispatch();
	const [waterLoader, setWaterLoader] = useState(true) 
	const currentDate = useSelector((state) => state.deliveryDate) 

	useEffect( () =>{
		console.log("Requesting water district details: ", props.waterID)
		setWaterLoader(true)
		dispatch( getDistrict(props.waterID) ).then( (data, err) =>{
			setWaterLoader(false)
		})
	}, [])

	const closingModal = ()=>{
		props.refreshList(); 
		props.closeModal()
	}

	return(
		<>
			<Modal.Header closeButton onHide={ closingModal }>
				<Row>
						<Col>

						</Col>
					</Row>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row>
						<Col>
							<PoppingList hasDate = {props.hasDate }/>
						</Col>
					</Row>
				</Container>
			</Modal.Body>
		</>
	)
}