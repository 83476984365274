import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops  } from "../../../actions/scheduling.js"
import {format } from "date-fns"
import TestersCalendar from "./TestersCalendar"
import Reports from "./Reports"
import { requestReports, getDasher  } from "../../../actions/home.js"

import InvoiceApplication from "../invoice/InvoiceApplication"

export default function HomeApplication(){
	const totallist = useSelector( (state) => state.home_totalslist)
	const dispatch = useDispatch(); 
	const [totalsLoaded, setTotalsLoaded] = useState(false)
	const [loaded, setLoaded] = useState(false)
	const startDate = useSelector((state) => state.start_date )
	const endDate = useSelector( (state) => state.end_date)
	const [dash, setDash] = useState(null);
	const [showDash, setShowDash] = useState(false); 
	useEffect(()=>{
		if(dash != null){
			setShowDash(true)
			console.log("This is the dasher: ",  dash )
		}

	}, [dash])

	useEffect(()=>{
		getDasher().then((data, err) =>{
			setDash(data)
		})

	}, [])
	useEffect(()=>{	
		setTotalsLoaded(false); 
		dispatch( getTotalsList() ).then( (data, err) =>{
			setTotalsLoaded(true)
		})
	}, [])

	useEffect(()=>{
		setLoaded(false)
		dispatch( requestReports(startDate, endDate) ).then((data, err) =>{
			setLoaded(true)
		}) 

	}, [startDate, endDate])

	const renderTotals = totallist.map( (tt) =>{
		return(
			<tr>
				<td>
					{ format( new Date( tt.date), "MM/dd/yyyy EEEE" )}
				</td>
				<td>
					{tt.total}
				</td>
			</tr>
		)
	} ) 
	return(
		<Container>
			<Row>
				<Col>
					{	
						showDash ? 
							<>
								<br/>
								<br />
								<h3> Quick Numbers </h3>
								<p>
								Pdfs remaining to be created <strong>{dash.pdfs}</strong> <br/>
								Yesterday's stops no completed: <strong>{dash.incompletes} </strong><br/>
								Today's total stops: <strong> {dash.total} </strong><br/>
								Total test reports that have not been entered/sent to water districts: <strong> {dash.water} </strong><br/>
								Total test reports that have not been sent to customers: <strong>{dash.customer}</strong>
								</p>
							</>
						: 
							<>
								<Spinner />
							</>
					}
				</Col>
			</Row>
			
		</Container>
	)
}