import { format } from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestReportResults
} from "../../../.././../../actions/universal_test_report"
import { useSelector, useDispatch } from "react-redux";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import RV from "./RV"
import DC from "./DC"
import XVBA from "./XVBA"
import Approved from "./Approved"
import Assembly from "./Assembly"
import Gauge from "./Gauge"
import Tester from "./Tester"
import System from "./System"
import Remarks from "./Remarks"

export default function DisplayResults(){
	const reportID = useSelector((state) => state.universal_reportID)
	const report = useSelector((state) => state.universal_report)
	const [tableToShow, setTableToShow] = useState(<DC />)
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	useEffect(()=>{
		setLoading(true)
		if(reportID != null) {
			dispatch ( requestReportResults(reportID) ) .then((data, err) =>{
				setLoading(false)
			})
		}
		
	}, [reportID])


	useEffect(()=>{
		if(report != null){
			switch(report.assembly.type){
				case "DCDA": 
				case "DC": 
				case "DCDAII": 
					setTableToShow(<DC />)
					break; 
				case "RP": 
				case "RPDA": 
				case "RPDAII": 
					setTableToShow( <RV />)
					break; 
				case "AVB": 
				case "PVB": 
				case "SVB": 
					setTableToShow(<XVBA />)
					break; 
				case "AG": 
					setTableToShow(<> </>)
					break; 
				default: 
					setTableToShow(<DC />)  
			}
		}
	}, [report])


	return(
		<>
			{ loading ?
				<Spinner />
			: 
				<Container>
					<Row>
						<Col>
							<Assembly />
						</Col>
						<Col>
							<Gauge />
						</Col>
						<Col>
							<Tester />
						</Col>
					</Row>
					<Row>
						<Col>
							<Approved />
						</Col>
					
						<Col>
							<Remarks />
						</Col>
						<Col>
							<System />
						</Col>
					</Row>
					<Row>
						<Col>
							{tableToShow}
						</Col>
					</Row>
				</Container>
			}
		</>
		
	)
}