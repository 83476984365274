import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	updateBillingName, 
	updateBillingContact, 
	updateBillingAddress, 
	updateBillingDetails
} from "../../../../actions/account"


export default function BillingCard(props){
	const [billingName, setBillingName] = useState(null); 
	const [cName, setCname] = useState(null); 
	const [phone, setPhone] = useState(null); 
	const [email, setEmail] = useState(null); 
	const [street, setStreet] =useState(null); 
	const [city, setCity] = useState(null); 
	const [state, setState] = useState(null); 
	const [zipcode, setZipcode] = useState(null); 
	const [po, setPO] = useState(null);
	const [qbID, setQbId] = useState(null)
	const [hasQB, setHasQB] = useState(false)
	const [careOf, setCareOf] = useState(null); 
	const [salesTax, setSalesTax] = useState(null); 
	const dd = useSelector( (state) => state.billingDetails)

	const dispatch = useDispatch(); 
	useEffect(()=>{
		if(dd != null ){
			setBillingName(dd.name); 
			setCname(dd.contact); 
			setPhone(dd.phone); 
			setEmail(dd.email); 
			setStreet(dd.street); 
			setCity(dd.city); 
			setState(dd.state); 
			setZipcode(dd.zipcode); 
			setPO(dd.PO);  
			setCareOf(dd.care_of); 
			if (dd.qb_id == null) {
				setHasQB(false)
			} else {
				setHasQB(true)
			}
		}
	}, [dd]) 	
	
			

	return(
		<>

		<Col>	
			<InputGroup size="sm">
			<InputGroup.Text> Billing To: </InputGroup.Text>
			<Form.Control 
				value= {billingName}
				onChange = {(event)=> setBillingName(event.target.value)}
				onBlur = {() =>{
					if( billingName != dd.billing_name){
						dispatch ( updateBillingName(billingName, dd.id ) ).then((data, err) =>{
							console.log("updated billing name")
						})
					}
				}}
				type="text"
			/>
			</InputGroup>
		</Col>
		<Col>
				<InputGroup size="sm">
					<InputGroup.Text>Street</InputGroup.Text>
				<Form.Control 
					value= {street}
					onChange = {(event)=> setStreet(event.target.value)}
					onBlur = {() =>{
						if( street != dd.street){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, dd.id ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
				/>
			</InputGroup>
			<InputGroup size="sm">
				<InputGroup.Text>City</InputGroup.Text>
				<Form.Control 
					
					value= {city}
					onChange = {(event)=> setCity(event.target.value)}
					onBlur = {() =>{
						if( city != dd.city){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, dd.id ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
				/>

			</InputGroup>
			<InputGroup size="sm">
				<InputGroup.Text>State</InputGroup.Text>
				<Form.Control 
					
					value= {state}
					onChange = {(event)=> setState(event.target.value)}
					onBlur = {() =>{
						if( state != dd.state){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, dd.id ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}

				/>
			</InputGroup>
			<InputGroup size="sm">
				<InputGroup.Text>Zipcode</InputGroup.Text>
				<Form.Control 
					
					value= {zipcode}
					onChange = {(event)=> setZipcode(event.target.value)}
					onBlur = {() =>{
						if( zipcode != dd.zipcode){
							dispatch ( updateBillingAddress({ street: street, city: city, state: state, zipcode: zipcode}, dd.id ) ).then((data, err) =>{
								console.log("updated billing name")
							})
						}
					}}
				/>
			</InputGroup>
			
			<InputGroup size="sm">
				<InputGroup.Text>Care Of</InputGroup.Text>
				<Form.Control 
				size="sm"
				value= {careOf}
				onChange = {(event)=> setCareOf(event.target.value)}
				onBlur = {() =>{
					if( careOf != dd.care_of){
						dispatch ( updateBillingDetails({ po: po, careof: careOf, sales_tax: salesTax} , dd.id ) ).then((data, err) =>{
							console.log("updated billing name")
						})
					}
				}}
			/>
			</InputGroup>
			</Col>
			<br/>
		<Col>
				
			
		</Col>

		<hr/>	
		</>
	)
}