import Row from 'react-bootstrap/Row';	
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { requestActiveWorkOrders, 
		reduceWorkOrderSelected} 
from "../../../../actions/workorder"

import { format } from "date-fns"


export default function WorkOrders(){
	const list = useSelector((state) => state.wo_active)
	const selected = useSelector((state) => state.wo_selected)
	const [mySelected, setMySelected] = useState(null)
	const dispatch = useDispatch() 
	useEffect(()=>{
		dispatch(requestActiveWorkOrders() ).then((data, err) =>{

		})
	}, [])

	useEffect(()=>{
		dispatch(reduceWorkOrderSelected(mySelected) )
	}, [mySelected])
	const renderOptions = list.map( (op) =>{
		return(
			<option value = {op.id}> {op.name} - { format( new Date( op.created_on) , "yyyy-MM-dd") }</option>
		)
	})

	return(
		<Container>
			<Row>
				<Col>
					<InputGroup size= "sm">
						<InputGroup.Text> WO </InputGroup.Text>
						<Form.Select value = { mySelected} onChange = {
							(event) =>{
								setMySelected(event.target.value)
							}
						}>
							<option value ={null}></option>
							{renderOptions}
						</Form.Select>
					</InputGroup>
				</Col>
			</Row>
		</Container>

	)

}