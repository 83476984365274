import {
    SET_CURRENT_DISTRICT,
    CLEAR_CURRENT_DISTRICT
} from "../../actions/districtManagement";
const _nullObj = null;

export default (state = _nullObj, { type, obj }) => {
    Object.freeze(state);
    switch (type) {
        case SET_CURRENT_DISTRICT:
            return obj;
        case CLEAR_CURRENT_DISTRICT:
            return _nullObj;
        case "":
            return _nullObj;
        default:
            return state;
    }
};