import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestWaterDistricts} from "../../../../actions/servicesReport"
import {setAddress, setContact, setDetails, setServices} from "../../../../actions/newstop"


export default function AddressForm(){
	const [locationName, setLocationName] = useState(null)
	const [street, setStreet] = useState(null)
	const [city, setCity] = useState(null)
	const [state, setState] = useState(null)
	const [zipcode, setZipcode] = useState(null)
	const [waterID, setWaterID]= useState(null)
	const waterList = useSelector( (state) => state.services_water_list)
	const dispatch = useDispatch()
	useEffect(()=>{
		dispatch( requestWaterDistricts() ).then((data, err)=>{
			console.log("water district gotten")
		})
	}, [])

	const update = ()=>{
		let obj = {}; 
		obj.locationName = locationName; 
		obj.street = street; 
		obj.city = city; 
		obj.state = state; 
		obj.zipcode = zipcode; 
		obj.waterDistrict =  waterID;
		dispatch( setAddress(obj) ).then((data, err) =>{
			
		})
	}

	const renderWaters = waterList.map( (water) =>{
		return(
			<option value={water.waterID}> {water.short_name}</option>
		)
	})
	return(
				<Container>
					<Row>
						<Col>
							<h5> Service Address </h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<FloatingLabel
								controlId="floatingInput"
	        					label="Location Name"
	        					size="sm"
	        					>
								<Form.Control 
									value= {locationName} 
									size="sm"
									onChange = {(event)=>setLocationName(event.target.value)}
									onBlur = {update}
									/>
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FloatingLabel
								controlId="floatingInput"
	        					label="Street"
	        					size="sm">
								<Form.Control 
									value= {street}
									size = "sm"
									onChange = {(event)=>setStreet(event.target.value)}
									onBlur = {update}
									/>
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FloatingLabel
								controlId="floatingInput"
	        					label="City"
	        					size="sm">
								<Form.Control 
									value= {city}
									size = "sm"
									onChange = {(event)=>setCity(event.target.value)}
									onBlur = {update}
								/>
							</FloatingLabel>
						</Col>
						<Col>
							<FloatingLabel
								controlId="floatingInput"
	        					label="State"
	        					size="sm">
								<Form.Control 
									value= {state}
									size = "sm"
									onChange = {(event)=>setState(event.target.value)}
									onBlur = {update}
								/>
							</FloatingLabel>
						</Col>
						<Col>
							<FloatingLabel
								controlId="floatingInput"
	        					label="Zipcode"
	        					size="sm">
								<Form.Control 
									value= {zipcode}
									size = "sm"
									onChange = {(event)=>setZipcode(event.target.value)}
									onBlur = {update}
								/>
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FloatingLabel
								controlId="floatingInput"
	        					label="Water District"
	        					size="sm">
								<Form.Select 
								size="sm" 
								value = {waterID} 
								onChange = {(event)=> setWaterID(event.target.value)}
								onBlur = {update}
								>
									<option value = {null}>Select a water district</option>
									{renderWaters}
								</Form.Select>
							</FloatingLabel>
						</Col>
					</Row>
				</Container>
	)
}