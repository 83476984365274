import {
  SET_ADDRESS
} from "../../actions/newstop";

const _nullAddress = { 
  locationName: null, 
  street: null, 
  city: null, 
  state: null, 
  zipcode: null, 
  waterDistrict: null
}
                
export default (state = _nullAddress, { type, obj}) => {
  switch (type) {
    case SET_ADDRESS:
      return obj;
    case "": 
      return _nullAddress; 
    default:
      return state;
  }
};