import {
    SET_UNIVERSAL_ITEM_LIST, CLEAR_UNIVERSAL_ITEM_LIST
} from "../../actions/universal_invoice";

const _null = []


export default (state = _null, { type, list }) => {
    Object.freeze(state);
    switch (type) {
        case SET_UNIVERSAL_ITEM_LIST:
            return list;
        case CLEAR_UNIVERSAL_ITEM_LIST:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};