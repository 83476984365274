import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NewStopRow from "./NewStopRow"
import WorkOrders from "./WorkOrders"
import {requestAssemblyList} from "../../../../actions/addressApp"
import {format} from "date-fns"
import { updateScheduleServiceList, createStop, determineInvoiceID, clearInvoice} from "../../../../actions/addressApp"
import { requestTesterNames } from "../../../../actions/dispatch"
import InvoiceApplication from "../../invoice/InvoiceApplication"



export default function NewStop(){
	const testerList = useSelector( (state) => state.testers);
	const assemblyList = useSelector((state) => state.address_assemblies)
	const dispatch = useDispatch(); 
	const workorder = useSelector((state) => state.wo_selected)
	const [loader, setLoader] = useState(true); 
	const addressID = useSelector((state) => state.addressID)
	const customerID = useSelector((state) => state.customerID)
	const [show, setShow] =  useState(true)
	const handleClose = () => setShow(false);
 	const handleShow = () => setShow(true);
 	const [date, setDate] = useState(format( new Date(),  "yyyy-MM-dd"))
 	const [testerID, setTesterID] = useState(null); 
 	const [tList, setTList] = useState([]); 
	const initial = useSelector( (state) => state.customerdetails )

 	const [requestor, setRequestor] = useState(null); 
 	const [phone, setPhone] = useState(null); 
 	const [note, setNote] = useState(null); 
 	const [startTime, setStartTime] = useState('08:00')
	const [endTime, setEndTime] = useState('16:00')
	const [frame, setFrame] = useState(null); 
	const [time, setTime] = useState(false)
	const [wind, setWind] = useState(false);

	const currentList = useSelector( (state) => state.schedule_services_list); 
	const [showAlert, setShowAlert] = useState(false)
	const [alertMessage, setAlertMessaage] = useState('Please assign a tester')
	const [testerValid, setTesterValid] = useState(false)

	const [loading, setLoading] = useState(false)
	const [scheduled, setScheduled] = useState(false)

	const [showTesters, setShowTesters] = useState(false)
	const [showCreditHold, setShowCreditHold] =useState(false)

	useEffect(()=>{
		if(initial != null){
			if(initial.status == "CREDIT HOLD"){
				setShowCreditHold(true)
			}else{
				setShowCreditHold(false)
			}
		}else{

		}
	}, [initial])


	const clearForm = () => {
		setDate(format(new Date(), "yyyy-MM-dd"))
		setRequestor(null); 
		setPhone(null);
		setStartTime('08:00');
		setEndTime('16:00');
		setNote(null)
		setFrame(null); 
		setTime(false); 
		setWind(false);
		dispatch(clearInvoice()).then((data, err) => {
		})
		setScheduled(false)
	}

	useEffect( () =>{
		setShowTesters(false)
		dispatch( requestTesterNames() ).then((data, err) =>{
			setShowTesters(true)
		})
	}, [])

	useEffect(()=>{
		console.log("New list: ", tList)
	}, [tList.length])

	useEffect(()=>{
	
			let newList = []
			for(let i = 0;  i < assemblyList.length; i++){				
				let sObj =  {}; 
				sObj.assemblyID = assemblyList[i].id; 
				sObj.serviceType = "TEST"; 
				sObj.selected = true; 
				newList.push(sObj); 
			}
			dispatch( updateScheduleServiceList(newList)).then((data, err) =>{	
				console.log("Awesome!")
			})
		
	}, [assemblyList])

	const requestlist = () => {
		setLoader(true)
		dispatch(requestAssemblyList(addressID, false)).then((data, err) => {
			setLoader(false)
		})
	}

	useEffect(()=>{
		requestlist(); 
	}, [])

	const validate = () =>{
		return new Promise( (resolve, reject) =>{
			if(tList.length == 0){
				resolve(false)
			}else{
				resolve(true)
			}
		})

	}

	const createMe =  (event) =>{
		return new Promise( async( resolve, reject) =>{
			try{
				event.preventDefault(); 
				//check everything is correct
				setShowAlert(false)
				if( await validate() ){
					let obj  = {}; 
					obj.date = date;
					obj.testerID = tList[0]; 
					obj.tester_list = tList; 
					obj.startTime = startTime; 
					obj.endTime = endTime; 
					obj.requestor = requestor; 
					obj.phone = phone; 
					obj.note = note; 
					obj.services = currentList; 
					obj.addressID = addressID; 
					obj.accountID = customerID; 
					obj.workorder = workorder
					console.log("This is the scheduling packet: ", obj)
					setLoading(true)
					setScheduled(true)
					dispatch( createStop(obj) ) .then((data, err) =>{
						setLoading(false)
					})
				}else{
					
					setShowAlert( true)
				}
					
			}catch(err){
				console.log("This is the error: ", err)
			}

		})
	}

	useEffect(()=>{
		if(showAlert){
			document.querySelector("#scheduleForm").scrollIntoView(); 
		}
	}, [showAlert])

	const renderTesters = testerList.map( (tt) =>{
		if( tt.tester){
			return(
				<option value ={tt.id} > {tt.tester_name}</option>
			)
		}else{
			return(
				<> </>
			)
		}
		
	})

	const renderCheckTesters = testerList.map( (tt) =>{
		if( tt.tester){
			return(
				<Col>
					<Form.Check 
						type="checkbox" 
						label= {tt.tester_name}
						onClick = {
							(event)=>{
								if(event.target.checked){
									if(!tList.includes(tt.id)){
										let newlist = [...tList]; 
										newlist.push(tt.id); 
										setTList(newlist)
									}
										
								}else{
									if(tList.includes(tt.id)){
										//find index of
										console.log(`${tt.id} is in the list`)
										let myIndex = tList.indexOf(tt.id); 
										console.log("This is the index: ", myIndex)
										let removed = tList.splice(myIndex, 1)
										console.log("removed: ", removed)
										let newlist = [...tList]
										setTList(newlist)
									}
								}	
							}
						}
					/>
				</Col>
			)
		}else{
			return(
				<> </>
			)
		}
		
	})


	const renderAssemblies = assemblyList
	.filter( (ass)=> {
		if(ass.state.toUpperCase() == "REMOVED"){
			return false
		}else{
			return true
		}
	}) 
	.map( (ass, index) =>{
		return(
			<NewStopRow assembly = {ass} index = {index}/ >
		)
	})

 
	return(
	<>
		<Alert show = {showCreditHold} variant="danger">
          This account is on credit hold
        </Alert>

		{

			loading ?
				<Spinner />
			: 
				
					scheduled ? 
							<>
								<h5> <Badge bg= "success"> Scheduled! </Badge></h5>
								<InvoiceApplication />
							<Row>
								<Col>
									<Button
										variant="info"
										onClick={ clearForm }
									> Schedule New Stop</Button>
								</Col>
							</Row>
							</>
					: 
						<>
						
							<Container className="schedule-form">
								<div id="scheduleForm">
							<Alert 
								id = "scheduleForm" 
								variant = "danger"
								show = {showAlert}
							>{alertMessage}</Alert>
							</div>
							
								<Form onSubmit={createMe}  >
									
									<Row>
										<Col sm md lg={4}>
											<Container>
												<InputGroup size="sm">
													<InputGroup.Text>Date </InputGroup.Text>
													<Form.Control type="date" value={date} onChange={(event) => setDate(event.target.value)} />
												</InputGroup>
											</Container>
										</Col>
										<Col>

											{/*<Form.Select value = {testerID} onChange = { (event) => setTesterID( event.target.value) } >
												<option value={null}> Select One </option>
													{ 
														showTesters ? 
															<> 
																{renderTesters}
															</>
														: 
															<>
															</>

													}
												
											</Form.Select>*/}
											<Container>

												<Row>
													<Col sm md lg={2}>
														<Form.Check type="radio" label="Any" name="tt" checked={!time && !wind}
															onChange={(event) => {
																setTime(!event.target.checked)
																setWind(!event.target.checked)
																setStartTime("08:00")
																setEndTime("16:00")
															}} />

														<Form.Check type="radio" label="Time" name="tt" checked={time} onChange={(event) => {
															setTime(event.target.checked)
															setWind(!event.target.checked)
															setEndTime(startTime)
														}} />

														<Form.Check type="radio" label="Window" name="tt" checked={wind} onChange={(event) => {
															setWind(event.target.checked)
															setTime(!event.target.checked)
														}} />
													</Col>
													<Col>

														{
															wind ?
																<Form.Select size="sm" value={frame}
																	onChange={(event) => {
																		switch (event.target.value) {
																			case "1":
																				setStartTime("08:00")
																				setEndTime("10:00")
																				break;
																			case "2":
																				setStartTime("10:00")
																				setEndTime("12:00")
																				break;
																			case "3":
																				setStartTime("12:00")
																				setEndTime("14:00")
																				break;
																			case "4":
																				setStartTime("14:00")
																				setEndTime("16:00")
																				break;
																			default:
																				setStartTime("08:00")
																				setEndTime("16:00")
																				break;
																		}


																	}}
																>
																	<option value={null}> Select One </option>
																	<option value="1">8AM - 10AM</option>
																	<option value="2">10AM - 12PM</option>
																	<option value="3">12PM - 2PM</option>
																	<option value="4">2PM - 4PM</option>
																</Form.Select>
																:
																<> </>

														}

														{
															time ?
																<Form.Control type="time" value={startTime} onChange={(event) => {
																	setStartTime(event.target.value)
																	setEndTime(event.target.value)
																}} />
																:
																<> </>
														}

													</Col>

												</Row>

											</Container>


										</Col>
									</Row>
									<hr />
									<Row>
										<Col>
											<Form.Label> Assign To: </Form.Label>
										</Col>
									</Row>
									<Row sm md lg={5}>

										{renderCheckTesters}
									</Row>
									<Row>

										<Col>
											<hr />
											<InputGroup size="sm">
												<InputGroup.Text > Requested by</InputGroup.Text>
												<Form.Control type="textarea" value={requestor} onChange={(event) => { setRequestor(event.target.value) }} />
											</InputGroup>
										</Col>
										<Col>
											<hr />
											<InputGroup size="sm">
												<InputGroup.Text > Phone #</InputGroup.Text>
												<Form.Control type="textarea" value={phone} onChange={(event) => { setPhone(event.target.value) }} />
											</InputGroup>
										</Col>
									</Row>
									<Row>

										<Col>
											
											<InputGroup size="sm">
												<InputGroup.Text > Scheduling Note</InputGroup.Text>
												<Form.Control
													as="textarea"
													value={note}
													disabled={showCreditHold}
													onChange={(event) => { setNote(event.target.value) }} />
											</InputGroup>
										</Col>
									</Row>
									<Row>
										<Col>
											<WorkOrders />
										</Col>
									</Row>
									<Row>

										<Col>
											<hr />
											<p>	<strong> Assemblies </strong> <br />
											
											</p>
										</Col>
									</Row>
									<Row className="scrollableTable">
										

										<Col>
											<Table size="sm" borderless striped responsive>
												<thead>
													<tr>
														<th>

														</th>
														<th>SN</th>
														<th>Location</th>
														<th>Service</th>
													</tr>
												</thead>
												<tbody>
													{
														loader ?
															<Spinner />
															:
															<>
																{renderAssemblies}
															</>

													}
												</tbody>
											</Table>
										</Col>
									</Row>

									<Row>
										<hr />
										<Col>
											<Button
												type="submit"
												disabled={showCreditHold}
											> Schedule </Button> </Col>

									</Row>
								</Form>

							</Container>
							
					</>
				

			
		}
	</>
	)
}