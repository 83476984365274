import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import DumpRow from "./DumpRow" 
import {
	requestReportList 
} from "../../../actions/dump"


export default function TestReportDump(){
	const reports  = useSelector( (state) => state.dump_report_list)

	const [loaded, setLoaded] = useState(false)
	const dispatch = useDispatch()
	useEffect(()=>{
		setLoaded(false)
		dispatch( requestReportList()).then((data, err)=>{
			setLoaded(true)
		})
	}, [])
	const renderRows = reports.map( (report) =>{
		return(
			<DumpRow id = {report.id} date = {report.date}/>
		)
		
	}) 

	return(
		<Container>
			<Table>
				<thead>
					<tr>
						<th>Date</th>
						<th>Status </th>
						<th>Results </th>
						<th>Water District </th>
					</tr>
				</thead>
				<tbody>
					{
						loaded ?
							<>
								{renderRows}
							</>	
						: 
							<>
							</>	
					}
							
				</tbody>
			</Table>
		</Container>
	)
}