import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'; 
import OverHeader from "../modules/OverHeader"; 
import LoginForm from "../modules/LoginForm"; 
import Footer from "../modules/Footer"
export default function Login(){
	return(
		<Container>
			<Row>	
				<OverHeader />
			</Row>
			<Row>
				<Col>
					<Container>
						<Row sm={1} md={1} md={1} lg={1} >
							
							<Col><LoginForm /> </Col>
							
						</Row>
					</Container>
				</Col>
			</Row>
			
		</Container>
	)
}