import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {format} from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function Gauge(){
	const report = useSelector((state) => state.universal_report)
	return(
		<Container>
			<Row>
				<Col>
					<h5> Gauge </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<p>
						<strong>SN: </strong>{report.gauge.serialN} <br/>
						<strong>Calibration:</strong> { format( new Date( report.gauge.calibrationDate) , "MM/dd/yyyy") }<br/>
						<strong>Make-Model: </strong> {report.gauge.model}<br/>
					</p>
				</Col>
			</Row>
		</Container>
	)	
}