import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container'	
import { requestAddressDetails, requestTester } from "../../../actions/address.js"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";

export default function StopCard(props){
	const dispatch = useDispatch();
	const [addressLoaded, setAddressLoaded] = useState(false)
	const [testerLoaded, setTesterLoaded] = useState(false)
	const [address, setAddress] = useState(null)
	const [tester, setTester] = useState(null)
	const [stateBadge, setStateBadge] = useState(<> </>); 

	useEffect(()=>{
		setAddressLoaded(false)
		requestAddressDetails (props.stop.addressID).then((data, err) =>{
			console.log("This is the address: ", data)
			setAddress(data)
			setAddressLoaded(true)
		})
	}, [])

	useEffect(()=>{
		if(props.stop.state == "Scheduled"){
			setStateBadge( <Badge bg="secondary" >Not Tested</Badge>)
		}else if (props.stop.state == "COMPLETED"){
			setStateBadge( <Badge bg ="primary"> Tested </Badge>)
		}

	}, [])

	useEffect(()=>{
		setTesterLoaded(false)
		requestTester( props.stop.testerID) .then((data, err) =>{
			console.log("Tester: ", data)
			setTester(data)
			setTesterLoaded(true)
		})
	}, [])


	return(
		<Container className="dispatchStop">
			<Row>
				<Col sm md lg ={8}>
				
						{
							addressLoaded ?
								<p>
									<strong>{address.location_name} </strong><br/>
									{address.street} <br/> 
									{address.city} {address.state}, {address.zipcode}
								</p>
							: 
								<Spinner />
						}
									
				
				</Col>
				<Col>
					{stateBadge}
				</Col>
			</Row>
			<Row>
				<Col>
					{
						testerLoaded ?
							<>
								{tester.name}
							</>
						:
							<Spinner />
					}
				</Col>
				<Col>
					{}
				</Col>
			</Row>
		</Container>
	)
}