import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from 'react-bootstrap/Spinner';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestList, requestDateTotal,requestQueryTotal} from "../../../actions/rounds.js"
import RoundCard from "./RoundCard"

export default function PaginateMe(){
	let mylist = useSelector((state) => state.rounds_list)
	const [active, setActive] = useState(1)
	const [toShow, setToShow] = useState(20)
	const [offset, setOffset] = useState(0)
	const [totalPages, setTotalPages] = useState(0)
	const [items, setItems] = useState([])
	const dispatch = useDispatch()
	const totalDateRounds = useSelector((state) => state.rounds_date_total)
	const [queryTotal, setQueryTotal] = useState(0)
	const [loader, setLoader] = useState(true)
	const currentMonth = useSelector((state) => state.rounds_month)
	const currentDate = useSelector((state) => state.rounds_date)
	const currentCount = useSelector((state) => state.rounds_count)
	const currentShow = useSelector((state) => state.rounds_show)
	const [totalLoader, setTotalLoader] = useState(true)
	const [queryTotalLoader, setQueryTotalLoader] = useState(true)

	useEffect(()=>{
		if(currentDate != null){
			setTotalLoader(true)
			dispatch( requestDateTotal( currentDate) ).then((data, err) =>{
				setTotalLoader(false)
			})
		}
		
	}, [currentDate]); 

	useEffect(()=>{
		if(currentDate != null && !currentShow ){
			setLoader(true)
			dispatch( requestList( currentDate, currentMonth, currentCount, active - 1) ).then((data, err) =>{
				setLoader(false)
			})

			setQueryTotalLoader(true)
			requestQueryTotal(currentDate, currentMonth, currentCount, active - 1).then((data, err)=>{
				console.log("This is from query: ", data)
				setQueryTotal(data)
				setQueryTotalLoader(false)
			})
		}

	}, [currentDate, currentCount, currentShow, currentMonth])


	useEffect(()=>{
		if(currentDate!= null && currentMonth != null && currentCount != null){
			setLoader(true)
			dispatch( requestList( currentDate, currentMonth, currentCount, active - 1) ).then((data, err) =>{
				setLoader(false)

			})
		}
		
	}, [active])

	useEffect(()=>{
		if(queryTotal > 0){
			setTotalPages( Math.ceil(queryTotal/ toShow) )
			setActive(1)
		}
		
	}, [queryTotal])

	useEffect(()=>{
		let newlist = []
		for(let number = 1; number <= totalPages; number++){
			newlist.push(
				<Pagination.Item key={number} active={number === active} onClick={() => setActive(number) }>
					{number}
				</Pagination.Item>
			)
		}
		setItems(newlist)

	}, [totalPages, active])

	const renderCards = mylist.map( (cc) => 
			<Col>
				<RoundCard  round= {cc} reload = { () => {
					setLoader(true)
					dispatch( requestList( currentDate, currentMonth, currentCount, active - 1) ).then((data, err) =>{
						setActive(1)
						setLoader(false)

					})
				}}/>
			</Col>
		)
	
	return(
		<>
			<Row sm md lg = {1} className = "roundsCardContainer">
					{
						loader ?
							<Spinner />
						: 
							<>
								{renderCards}
							</>

					}
			</Row>
			<Row>
				<Col sm md lg = {10}>
					{
						loader ?
							<Spinner />
						: 
							<Pagination>
								{items}
							</Pagination> 
					}
				</Col>
				
			</Row>
		</>
	)
}