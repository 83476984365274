import { format } from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestReportResults
} from "../../../.././../../actions/universal_test_report"
import { useSelector, useDispatch } from "react-redux";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

export default function DC(){
	const report = useSelector((state) => state.universal_report)

	return(
			<Table striped >
						<thead>
							<tr>
								<th colSpan = {1}>
									DC
								</th>
								<th>
									<Form.Check label = "typeII " checked = { report.results.RPA_typeII }/>
								</th>
							</tr>
							
						</thead>
						<tbody>
							<tr>
								<td>Initial</td>
									<Table bordered>
										<thead>
											<tr>
												
												<td> Check</td>
												<td> PSID</td>
												<td>  Tight</td>
												<td>  Leaked</td>
											</tr>	
										</thead>
										<tbody>
											<tr>
												<td>1</td>
												<td>{report.results.RPA1_psid}</td>
												<td>
													{
													report.results.RPA1_tight
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
													</td>
												<td>
												{
													report.results.RPA1_leaked
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
												</td>
											</tr>
											<tr>
												<td>2</td>
												<td>{report.results.RPA2_psid}</td>
												<td>
													{
													report.results.RPA2_tight
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
													</td>
												<td>
												{
													report.results.RPA2_leaked
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
												</td>
											</tr>
									</tbody>
								</Table>

							</tr>
							<tr>
								<td>Final</td>
									<Table bordered>
										<thead>
											<tr>
												
												<td> Check</td>
												<td> PSID</td>
												<td>  Tight</td>
												<td>  Leaked</td>
											</tr>	
										</thead>
										<tbody>
											<tr>
												<td>1</td>
												<td>{report.results.repair_rpa_double_c1_psid}</td>
												<td>
													{
													report.results.repair_rpa_double_c1_tight
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
													</td>
												<td>
												{
													!report.results.repair_rpa_double_c1_tight
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
												</td>
											</tr>
											<tr>
												<td>2</td>
												<td>{report.results.repair_rpa_double_c2_psid}</td>
												<td>
													{
													report.results.repair_rpa_double_c2_tight
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
													</td>
												<td>
												{
													!report.results.repair_rpa_double_c2_tight
														?
															<Form.Check  checked = {true} />
														: 
															<Form.Check checked = {false} />
													}
												</td>
											</tr>
									</tbody>
								</Table>

							</tr>
							
						</tbody>
					</Table>
	)
}