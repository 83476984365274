import {
  RECEIVE_CONTACT
} from "../../actions/customersnap";

const _nullContacts = { 
  contactList: []
}
                
export default (state = _nullContacts, { type, contactlist }) => {
  switch (type) {
    case RECEIVE_CONTACT:
      return contactlist;
    case "": 
      return _nullContacts; 
    default:
      return state;
  }
};