import {
  SET_ON_SITE_GATE, 
  CLEAR_ON_SITE_GATE
} from "../../actions/scheduling";

const _nullOnSiteGate = null; 
                
export default (state = _nullOnSiteGate, { type, gate}) => {
  Object.freeze(state);
  switch (type) {
    case SET_ON_SITE_GATE:
      return gate;
    case CLEAR_ON_SITE_GATE: 
      return _nullOnSiteGate
    case "": 
      return _nullOnSiteGate; 
    default:
      return state;
  }
};