import {
    SET_STATUS_SELECTED, CLEAR_STATUS_SELECTED
} from "../../actions/unfinished";

const _null = null


export default (state = _null, { type, status}) => {
    Object.freeze(state);
    switch (type) {
        case SET_STATUS_SELECTED:
            return status;
        case CLEAR_STATUS_SELECTED:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};