import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function ServiceLine(props){
	const [location, setLocation] = useState(null)
	const [serial, setSerial] = useState(null)
	const [make, setMake] = useState(null)
	const [model, setModel] = useState(null)
	const [size, setSize] = useState(null)
	const [hazard, setHazard] = useState(null)
	const [serviceType, setServiceType] = useState("TEST")
	const [status, setStatus] = useState(null)
	const services = useSelector((state) => state.newstop_services)

	useEffect(()=>{
		setLocation( services[props.index].location ) 
	}, [])
	const update = ()=>{
		services[props.index].location = location;
		services[props.index].serial =  serial; 
		services[props.index].make =  make;
		services[props.index].model =  model;
		services[props.index].size =  size;
		services[props.index].hazard =  hazard;
		services[props.index].type =  serviceType;     
	}	

	return(
		<>		
			<tr>
						<td>
							<Form.Control 
								value = {location}
								size ="sm"
								onChange = { (event) => setLocation(event.target.value)}
								onBlur = {update}
							/>
						</td>
						<td>
							<Form.Control 
								value = {serial}
								size ="sm"
								onChange = { (event) => setSerial(event.target.value)}
								onBlur = {update}
							/>
						</td>
						<td>
							<Form.Select 
								size="sm" 
								id="size" 
								value = { size } 
								onChange= { (event) => setSize(event.target.value) }
								onBlur = {update} 
							>
									<option value="3/8">3/8"</option>
									<option value="1/2">1/2"</option>
									<option value="3/4">3/4"</option>
									<option value="1">1"</option>
									<option value= "1 1/4"> 1 1/4"</option>
									<option value="1.5">1 1/2"</option>
									<option value="2">2"</option>
									<option value="2.5">2 1/2"</option>
									<option value="3">3"</option>
									<option value="4">4"</option>
									<option value="6">6"</option>
									<option value="8">8"</option>
									<option value="10">10"</option>
									<option value="12">12"</option>
								</Form.Select>
						</td>
						
						<td>
							<Form.Control
								value = {make}
								size ="sm"
								onChange = { (event) => setMake(event.target.value)}
								onBlur = {update} 
							/>
						</td>
						<td>
							<Form.Control
								value = {model}
								size ="sm"
								onChange = { (event) => setModel(event.target.value)}
								onBlur = {update} 
							/>
						</td>
						<td>

							<Form.Control
								value = {hazard}
								size ="sm"
								onChange = { (event) => setHazard(event.target.value)}
								onBlur = {update} 
							/>
						</td>
						<td><small>
							<Form.Select 
								size ="sm" 
								value = {serviceType} 
								onChange= {(event) => setServiceType(event.target.value) }
								onBlur = {update}
							>
								<option value = "TEST"> Test </option>
								<option value= "REPAIR"> Repair </option>
								<option value= "NO SERVICE"> No Service </option>
								<option value="REPLACEMENT"> Replacement</option>
							</Form.Select>
							</small> </td>
					
			</tr>
		</>
	)
}