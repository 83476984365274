import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import AssemblyCard from "./AssemblyCard"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import StopCard from "./StopCard"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AssemblyTable from "./AssemblyTable"
import { determineID, requestStops } from "../../../../actions/addressApp"
import AddressForm from "./AddressForm"
import AddressContact from "./AddressContact"
import NewStop from "./NewStop"
import DisplayStops  from "./DisplayStops"
import WorkOrders from "../workorders/app"

export default function AssemblyDisplay(){
	const stoplist = useSelector((state) => state.address_stoplist )
	const addressID = useSelector((state) => state.addressID)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [hasItems, setHasItems] = useState(false)

	useEffect(()=>{
		if(addressID != null){	
			setLoader(true)	
			dispatch( requestStops(addressID) ).then((data, err) =>{	
				setLoader(false)
			})
		}
	}, [addressID])


	
	return(
		<Container>
			<Row>
				<Col>
					<Tabs
						variant = "underline"
						defaultActiveKey="details"
				   		className = "flex-row"
						fill
						justify
				    >
				    <Tab eventKey="details" title="Details"   >
				      	<Container className = "second-half">
				      			<Row>
				      				<Col>
				       					<AddressForm />
				       				</Col>
				       			</Row>
				       			
				       		</Container>
				      </Tab>
				      <Tab eventKey="workorder" title="Work Orders" mountOnEnter unmountOnExit >
				      	<Container className = "second-half">
				      			<Row>
				      				<Col>
				       					<WorkOrders />
				       				</Col>
				       			</Row>
				       			
				       		</Container>
				      </Tab>

				      <Tab eventKey="stops" title="Stops" mountOnEnter unmountOnExit onSelect = {
				      	()=>{
				      		if(addressID != null){	
				      			setLoader(true)	
								dispatch( requestStops(addressID) ).then((data, err) =>{	
									setLoader(false)
								})
							}
				      	}
				      }>
				      	<Container className = "second-half">
				      		<Row sm md lg ={1}>
				      			<DisplayStops />
							</Row>
				      	</Container>
						</Tab>
						<Tab eventKey="assemblies" title="Assemblies" mountOnEnter unmountOnExit>
				      		<Container className = "second-half">
				      			<Row>
				      				<Col>
				       					<AssemblyTable />
				       				</Col>
				       			</Row>
				       		</Container>
				      </Tab>
				      <Tab eventKey="schedule" title="Schedule" className = "second-half" mountOnEnter >
				       	 <NewStop />
				      </Tab>
				    </Tabs>
				</Col>
			</Row>
		</Container>
	)
}