import {
  GET_WATER_DISTRICT_LIST
} from "../../actions/waterDistricts.js";

const _nullWaterDistrictList = { 
    list: []
}
                
export default (state = _nullWaterDistrictList, { type, waterdistrictlist }) => {
  Object.freeze(state);
  switch (type) {
    case GET_WATER_DISTRICT_LIST:
      return waterdistrictlist;
    case "": 
      return _nullWaterDistrictList; 
    default:
      return state;
  }
};