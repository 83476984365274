import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button'
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { requestList, createOrder, requestDetails} from "../../../../actions/workorder.js"


export default function CreateOrder(){
	const dispatch = useDispatch()	
	const [name, setName] = useState(null)
	const [po, setPO] =  useState(null)
	const [link, setLink] = useState(null) 
	const accountID = useSelector((state) => state.customerID)

	const send = (event) =>{		
		event.preventDefault(); 
		let obj = {}
		obj.name = name
		obj.po = po
		obj.link = link
		createOrder( obj, accountID).then((data, err) =>{
			dispatch( requestList( accountID )).then((data, err) =>{
				setName(null)
				setPO(null)
				setLink(null)
			})
		})
	}

	return(
		<Container>
			<Form onSubmit = {send}>
			<Row xs = {1} sm = {1} md = {1} lg = {2}>

				<Col>
					<FloatingLabel label = "Name">
						<Form.Control 
							size= "sm"
							value ={name}
							onChange= {
								(event) => { 
									setName(event.target.value.toUpperCase().replace(' ', '') )
								}
							}
							required
						/>
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel label = "PO #">
						<Form.Control
							size= "sm"
							value ={po}
							onChange= {
								(event) => { 
									setPO(event.target.value)
								}
							}
							required
						 />
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel label = "Link to sign in / sign out">
						<Form.Control 
							size= "sm"
							value ={link}
							onChange= {
								(event) => { 
									setLink(event.target.value)
								}
							}
						/>
					</FloatingLabel>
				</Col>
				<Col>
					<Container>

						<Button type = "submit"> Create WO</Button>
					</Container>
				</Col>
			</Row>
				
			</Form>
		</Container>
	)	
}