import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useEffect } from 'react'; 
import {useSelector, useDispatch} from "react-redux";
import { balanceSetter, removeItem, updateItem} from "../../../actions/invoice.js"

export default function ItemLine(props){
	const companyItems = useSelector( (state) => state.lineitems.list)
	const itemlist = useSelector( (state) => state.invoice_line_items)
	const invoiceID = useSelector((state) => state.invoiceID)
	const tax_rate = useSelector( (state) => state.invoice_tax_rate)
	const [itemIndex, setItemIndex] = useState(null)
	const [itemID, setItemID] = useState(null)
	const [description, setDescription] = useState(null); 
	const [quantity, setQuantity] = useState(null); 
	const [unitPrice, setUnitPrice] = useState(null); 
	const [amount, setAmount] = useState(0); 
	const dispatch = useDispatch()
	const [taxable, setTaxable] = useState(false)

	const renderCompanyItems = companyItems.map( (item, index) =>{
		return(
			<>	
				<option value = {index}> {item.Name} | {item.description} </option>
			</>
		)
	})

	useEffect( () =>{
		let sum = 0 ; 
		for( let i = 0; i < itemlist.length; i++){
			sum = sum + itemlist[i].amount 
		}

		dispatch( balanceSetter(sum) ).then( (data, err) => {
			console.log("Total updated: ", sum)
		})

	}, [itemlist])

	useEffect(()=>{
		dispatch( updateItem( {
			quantity: quantity, 
			amount: amount, 
			unitPrice: unitPrice, 
			itemID: itemID, 
			description: description,
			taxable: taxable

		}, props.item.id, invoiceID)).then((data, err) =>{
			console.log("updated item")

		})

	}, [amount, unitPrice, itemID, description, quantity, taxable])


	useEffect( ()=>{
		if(itemIndex!= null){
			//setUnitPrice(companyItems[itemIndex].UnitPrice)
			//setDescription(companyItems[itemIndex].Description)
			setItemID(companyItems[itemIndex].Id )
		}
	}, [itemIndex])

	useEffect(()=>{
		if(taxable){
			setAmount( quantity * unitPrice +( Number(tax_rate.rate) * (quantity * unitPrice) ))
		}else{
			setAmount( quantity * unitPrice)
		}
		
	}, [quantity, unitPrice, taxable])

	useEffect(()=>{
		setItemIndex(0)
		setDescription(props.item.description)
		setQuantity(props.item.quantity)
		setUnitPrice(props.item.unitPriceDefined)
		setAmount(props.item.amount)
	}, [])



	return(
		
			<tr>

				<td>
					<Form.Select 
						value={itemIndex} 
						onChange={ (event)=> setItemIndex(event.target.value) }
						size="sm"
					>
						{renderCompanyItems}
					</Form.Select>
				</td>
				<td>
					<Form.Control
						size= "sm"
						value= {description}
						onChange = {(event) =>{
							setDescription(event.target.value)
						}}
					/>
				</td>
				<td>
					<Form.Control 
						type = "number"
						step = "1"
						min = "1"
						value = {quantity}
						size = "sm"
						onChange={ (event) => setQuantity(event.target.value)  }
					/>
				</td>
				<td>
					<InputGroup>
						<InputGroup.Text> $ </InputGroup.Text>
						<Form.Control
							size="sm"
							min =  "0.0"
							value= { unitPrice}
							onChange= { (event) => setUnitPrice(event.target.value) }
							type= "number" 
							step= "0.01"
						/>
					</InputGroup>
				</td>
					<td>
					<Form.Check
						type="checkbox"
						value={taxable}
						onChange= { (event) =>{
							setTaxable(event.target.checked)

						}}
					/>
				</td>
			<td>$
				{
					amount?
						<> {amount.toFixed(2)} </>
					:
				<>
				</>
				}
				</td>
			
				<td>
					<Button 
						size ="sm" 
						variant = "secondary" 
						onClick = { ()=>{
							dispatch( removeItem(props.item.id)).then((data, err) =>{
								console.log("Item removed..")
							})
						}}
					> -</Button>
				</td>
			</tr>
	
	)
}