import { format } from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestReportResults
} from "../../../.././../../actions/universal_test_report"
import { useSelector, useDispatch } from "react-redux";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

export default function RV(){
	const report = useSelector((state) => state.universal_report)
	return(
		<Table striped bordered>
						<thead>
							<tr>
								<th colSpan = {4}> RP </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td> Initial </td>
									<td>
										<Table>
											<thead>
												<tr>
													<td> Opened At </td>
													<td> Pressdrop </td>
												</tr>
											</thead>
											<tbody>
												<tr>
												
													<td>{report.results.RPA_opened_at}</td>
													<td>{report.results.RPA_pressdrop}</td>
												</tr>
											</tbody>
										</Table>
									</td>
							</tr>
							<tr>
								<td> Final </td>
								<td>
										<Table>
											<thead>
												<tr>
													<td> Opened At </td>
													<td> Pressdrop </td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>{report.results.repair_rpa_openedat}</td>
													<td>{report.results.repair_rpa_pressdrop}</td>
												</tr>
											</tbody>
										</Table>
									</td>
							</tr>
						</tbody>
					</Table>
	)
}