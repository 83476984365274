import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useSelector, useDispatch } from "react-redux";

export default function Approved(){
	const report = useSelector((state) => state.universal_report)

	return(
		<Container>
			<Row>
				<Col>
					<h5> Approved </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Check 
						label= "Assembly" 
						checked = {report.results.approved_assembly}
						readOnly
					/>
					<Form.Check 
						label= "Installation" 
						checked = {report.results.approved_installation}
					/>
					<Form.Check 
						label= "Orientation" 
						checked = {report.results.approved_orientation}
					/>
					<Form.Check 
						label= "Airgap" 
						checked = {report.results.approved_airgap}
					/>
					<Form.Check 
						label= "USC" 
						checked = {report.results.approved_USC}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<p>
						<strong>Pipe Size: </strong>{report.results.pipesize} <br/>
						<strong>Physical Separation: </strong>{report.results.physical_separation} <br/>
					</p>
				</Col>
			</Row>
		</Container>
	)
}