import {
    SET_UNIVERSAL_TEMP_TESTERS, CLEAR_UNIVERSAL_TEMP_TESTERS
} from "../../actions/universal_stop";

const _null = []


export default (state = _null, { type, list }) => {
    Object.freeze(state);
    switch (type) {
        case SET_UNIVERSAL_TEMP_TESTERS:
            return list;
        case CLEAR_UNIVERSAL_TEMP_TESTERS:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};