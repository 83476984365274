export const requestFailedList = () => (
	fetch("backflow/failed", {
		method: "GET",  
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)


export const requestTestReport = (reportID)  => (
	fetch("backflow/failed/report?" + new URLSearchParams({
		reportID: reportID
	}), {
		method: "GET",  
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const flagAsScheduled = (reportID) => (
	fetch("backflow/failed/scheduled" , {
		method: "PUT", 
		body : JSON.stringify({ reportID: reportID}) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)