import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {requestAssembly} from "../../../actions/report.js"

export default function Assembly(){
	const assembly = useSelector( (state) => state.report_assembly)
	const report = useSelector( (state) =>state.testReport)
	const reportID = useSelector( (state) => state.reportID)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		//request assembly
		if(report != null){
			console.log("Current report: ", report)
			setLoader(true)
			dispatch( requestAssembly(report.assembly_id) ).then((data, err)=>{
				console.log("Requested assembly!")
				setLoader(false)
			})
		}
		
	}, [report])

	return(
		<Container>
			<Row>
				<Col>

					{
						loader ? 
							<>
								<Spinner />
							</>
						: 
							<>
							
								<h5>Assembly Details</h5>
								<p>
									<strong> Serial Number: </strong> 
	
									<u>{assembly.serial_number} </u> <br/>
										<strong> Location: </strong> 
										<u>{assembly.location}</u><br/>
									
										<strong> Type: </strong> 
										<u>{assembly.type}</u>
									
										<strong> size: </strong> 	
										<u>{assembly.size} " </u><br/>
									
										<strong> Make: </strong> 
										<u>{assembly.make }</u>
								
										<strong> Model: </strong> 
										<u>{assembly.model}</u> <br/>

										<strong> Hazard: </strong>
										<u> {assembly.hazard} </u>
									</p>
								
							</>
					
							
					}
				</Col>
			</Row>	
		</Container>
	)	
}