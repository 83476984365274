import * as serverIntuit from "../util/intuitServer";
import * as server from "../util/server";

export const  SET_INVOICE_TOTAL = " SET_INVOICE_TOTAL"
export const  RECEIVE_INVOICE_LINE_ITEMS = " RECEIVE_INVOICE_LINE_ITEMS"
export const RECEIVE_INVOICE_SERVICE_ADDRESS = "RECEIVE_INVOICE_SERVICE_ADDRESS"
export const RECEIVE_INVOICE_BILLING_DETAIL = "RECEIVE_INVOICE_BILLING_DETAIL"
export const GET_INVOICES  = "GET_INVOICES"
export const GET_OVERDUE = "GET_OVERDUE"
export const SET_TERM_LIST = "SET_TERM_LIST"
export const SET_SALES_RATE = "SET_SALES_RATE"

export const RECEIVE_INVOICE_QB = "RECEIVE_INVOICE_QB";   
export const CLEAR_INVOICE_QB = "CLEAR_INVOICE_QB"; 

export const RECEIVE_INVOICE = "RECEIVE_INVOICE"; 
export const CLEAR_INVOICE = "CLEAR_INVOICE"; 
export const RECEIVE_LINE_ITEMS = "RECEIVE_LINE_ITEMS"; 
export const NO_LIST_ITEMS = "NO_LIST_ITEMS"

export const SET_INVOICE_ID = "SET_INVOICE_ID";
export const SET_TERM_ID = "SET_TERM_ID"
export const CLEAR_INVOICE_ID= "CLEAR_INVOICE_ID"

export const INVOICE_BALANCE = "INVOICE_BALANCE"

const getInvoiceBalance = (balance) => ({
	type: INVOICE_BALANCE, 
	balance
})

const getLineItemList = (list) =>({
	type: RECEIVE_INVOICE_LINE_ITEMS, 
	list
})

const getBillingDetail = (billing_detail) => ({
	type: RECEIVE_INVOICE_BILLING_DETAIL, 
	billing_detail
})

const getServiceAddress = (address) =>({
	type: RECEIVE_INVOICE_SERVICE_ADDRESS, 
	address
})

const getInvoices = (list) =>({
	type: GET_INVOICES, 
	list
}) 

const getQBInvoice = ( qbinvoice ) => ({
	type: RECEIVE_INVOICE_QB, 
	qbinvoice
})
const getOverdue = (overduelist) =>({
	type: GET_OVERDUE, 
	overduelist
})

const clearInvoice = () => ({
	type: CLEAR_INVOICE
})
const getInvoice = (invoice) => ({
	type: RECEIVE_INVOICE, 
	invoice
})

const receiveLineItems = (itemlist) => ({
	type: "RECEIVE_LINE_ITEMS",
	itemlist
})
const noItemList = () => ({
	type: NO_LIST_ITEMS
})

const updateInvoiceID = (ID) => ({
	type: SET_INVOICE_ID, 
	ID
})

const nullIt = ()=> ({
	type: CLEAR_INVOICE_ID
})

const setPendingTotal = (total) =>({
	type : SET_INVOICE_TOTAL, 
	total 
}) 
const getTermList = (list) => ({
	type: SET_TERM_LIST, 
	list
})

const getSelectedTerm = (ID) =>({
	type: SET_TERM_ID, 
	ID
})

const receiveTaxRate = (taxrate) => ({
	type: SET_SALES_RATE, 
	taxrate
})



function base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

export const getSalesTax = (addressID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.getSalesTax(addressID); 
			const data =  await response.json()
			if(response.ok){
				dispatch( receiveTaxRate( JSON.parse(data).sales_tax ) )
				resolve()
			}
		}catch(e){

		}
	})
}

export const getTermID = (ID) => dispatch => {
	return new Promise( (resolve, reject) =>{
		try{
			dispatch(getSelectedTerm(ID))
			resolve()
		}catch(e){

		}
	})
}

export const queryTerms = () => dispatch => { 
	return new Promise( async(resolve, reject) =>{
		const response = await server.queryTerms(); 
		const data =  await response.json()
		if(response.ok){
			dispatch( getTermList( JSON.parse(data).list ) )
			resolve()
		}
	})
}
export const updateItem = (obj, itemID, invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateItem(obj, itemID, invoiceID); 
		const data =  await response.json()
		if(response.ok){
			dispatch( getLineItemList( JSON.parse(data).list ) )
			resolve()
		}
	})
}

export const balanceSetter = (balance) => dispatch => {
	return new Promise( (resolve, reject) =>{
		dispatch( getInvoiceBalance( balance) )
		resolve()
	})
}


export const updateServiceAddress = ( obj, addressID, invoiceID)  => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateServiceAddress(obj, addressID, invoiceID); 
		const data =  await response.json()
		if(response.ok){
			dispatch( getServiceAddress( JSON.parse(data).address ) )
			resolve()
		}
	})
}


export const requestInvoiceLineItems = (invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestInvoiceItems(invoiceID); 
		const data =  await response.json()
		if(response.ok){
			dispatch( getLineItemList( JSON.parse(data).list ) )
			resolve()
		}
	})
}

export const requestServiceAddress = (invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestServiceAddress(invoiceID); 
		const data =  await response.json()
		if(response.ok){
			dispatch( getServiceAddress( JSON.parse(data).address ) )
			resolve()
		}
	})

}
export const requestBillingDetail = (invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestBillingDetail(invoiceID); 
		const data =  await response.json()
		if(response.ok){
			dispatch(getBillingDetail( JSON.parse(data).billing_detail ) )
			resolve()
		}  
	})

}



export const setInvoiceAsBroken = (invoiceID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.setAsBroken( invoiceID); 
		const data =  await response.json()
		if(response.ok){
			resolve()
		}
	})
}
/*export const updateServiceAddress = (obj) => dispatch =>{
	return new Promise(async (resolve, reject) =>{
		const response = await server.updateServiceAddress(obj); 
		const data = await response.json(); 
		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( getInvoice(JSON.parse(data).invoice)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			resolve(false); 
		}
	})
}*/
export const updateBillingDetails = (obj) => dispatch =>{
	return new Promise(async (resolve, reject) =>{
		const response = await server.updateBillingDetails(obj); 
		const data = await response.json(); 
		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( getInvoice(JSON.parse(data).invoice)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			resolve(false); 
		}
	})
}

export const updateSalesPO = (obj) => dispatch =>{
	return new Promise(async (resolve, reject) =>{
		const response = await server.updateSalesPO(obj); 
		const data = await response.json(); 
		if(response.ok){
			if(JSON.parse(data).success){
				dispatch( getInvoice(JSON.parse(data).invoice)); 
				resolve(true)
			}else{
				let errObj = { message:JSON.parse(data).error.message }
				resolve(false)
			}
		}else{
			let errObj = { message:"Something wrong. Please try again later" }
			resolve(false); 
		}
	})
}




export const requestInvoiceTotal = () => dispatch =>{
	return new Promise ( async( resolve, reject) =>{
		const response = await serverIntuit.requestPendingTotal(); 
		const data =  await response.json() ; 
		if(response.ok){
			dispatch( setPendingTotal(JSON.parse(data).total) ); 
			resolve()
		}else{
			resolve(false)
		}
	})

}

export const popInvoice = (offset) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.popInvoice(offset); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( updateInvoiceID(JSON.parse(data).invoice) );  
			resolve(true); 	
		}else{
			resolve(false)
		}
	})
}
export const clearInvoiceID = () => dispatch => {
	return new Promise( async( resolve, reject) =>{
		dispatch ( nullIt() ); 
		resolve(); 
	})
}

export const setInvoiceID = (invoiceID) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		dispatch ( updateInvoiceID(invoiceID) ); 
		resolve(); 
	})
}

export const prepaid = (invoiceID) => dispatch =>{
	return new Promise ( async( resolve, reject) =>{
		const response = await serverIntuit.prepaid(invoiceID);
		const data = await response.json(); 

		if(response.ok){
			//let obj = { invoicelist: JSON.parse(data).invoicelist }
			dispatch( getInvoices( JSON.parse(data).invoicelist) )
			resolve(true)
		}
	})
}

export const requestInvoicePDF = (invoiceID) => {
	return new Promise ( async( resolve, reject) =>{
		let obj = { invoiceID: invoiceID } 
		console.log("Invoice id in actions: ", invoiceID); 
		const response = await serverIntuit.requestInvoicePDF(obj); 
		//make response into an array buffer
		const data =  await response.blob() ; 
		if(response.ok){
			console.log("getting PDF from this string: ", data);
			let blob = new Blob([data], { type: "application/pdf"});
			resolve(blob)
		}else{
			resolve(false)
		}
	})
}
export const requestPaymentLink = (invoiceID) => dispatch => {
	return new Promise ( async( resolve, reject) =>{
		let obj = { invoiceID: invoiceID } 
		const response = await serverIntuit.requestPaymentLink(obj); 
		const data =  await response.json(); 
		if(response.ok){
			console.log("Invoice Created!"); 
			dispatch( getQBInvoice( JSON.parse(data).Invoice) ); 
			resolve(true)
		}else{
			resolve(false)
		}
	})
}


export const requestOverdueInvoices = () => dispatch => {
	return new Promise ( async (resolve, reject) => {
		const response = await serverIntuit.requestOverdueInvoices(); 
		const data = await response.json(); 
		if(response.ok){
			let obj = { overduelist: JSON.parse(data).list};  
			dispatch( getOverdue(obj) ); 
			resolve();
		}
	})
}

export const requestInvoices = () => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await serverIntuit.getInvoices();
		const data = await response.json(); 

		if(response.ok){
			//let obj = { invoicelist: JSON.parse(data).invoicelist }
			dispatch( getInvoices( JSON.parse(data).invoicelist) )
			resolve(true)
		}
	})
}
export const updateInvoices = ( newinvoicelist) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		let obj = { invoicelist: newinvoicelist}
		dispatch( getInvoices( obj ) )
		resolve(true)
	})

}

export const clearInvoiceSlice = () => async dispatch => {
	return new Promise (async (resolve, reject) =>{
		dispatch( clearInvoice() ); 
		resolve(); 
	})
}
export const getInvoiceDetails = (invoiceID) =>async dispatch =>{
	return new Promise( async(resolve, reject) =>{
		let obj = { invoiceID: invoiceID}
		const response = await server.requestInvoice(obj); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( getInvoice( JSON.parse(data).invoice))
			resolve(true); 	
		}else{
			resolve(false)
		}
	})
}
export const updateInvoice  = (invoice) => dispatch =>{
	return new Promise( (resolve, reject) =>{
		dispatch( getInvoice( invoice))

	})
}
export const markAsEntered = ( invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		let myobj = { invoiceID: invoiceID }
		const response = await server.markAsEntered( myobj); 
		const data = await response.json(); 
		if(response.ok){
			resolve(true)
		}
	})

}
export const sendToQuickbooks = (invoiceID, isResidential, dueDate, termID) => dispatch => {
	return new Promise ( async( resolve, reject) =>{
		try{
			let obj = { invoiceID: invoiceID, isResidential: isResidential, dueDate: dueDate, termID: termID} 
			const response = await serverIntuit.createQBInvoice(obj); 
			const data =  await response.json(); 
			if(response.ok){
				if(JSON.parse(data).success){
					console.log("Invoice Created!", JSON.parse(data).invoice); 
					dispatch( getInvoice( JSON.parse(data).invoice) ); 
					dispatch( getQBInvoice( JSON.parse(data).qb_invoice)); 
					resolve(true) 
				}else{
					reject("Error")		
				}	
			}else{
				reject("Error")
			}
			
			//throw("Error")
		}catch(e){
			//console.log("error caught!", e)
			reject(e)
		}
			
	})

}
export const getCSRFToken = () => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.getCSRF(); 
		const data = await response.json(); 
		resolve(JSON.parse(data).token)
	})
}
export const addItem = (invoiceID) => async dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.addItem(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( getLineItemList( JSON.parse(data).list ) )
			resolve()
		}
	})
}

export const removeItem = (itemID)=> async dispatch => {
	return new Promise( async (resolve, reject) =>{
		
		const response = await server.removeItem(itemID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( getLineItemList( JSON.parse(data).list ) )
			resolve()
		}
	})
}
export const requestLineItemList = ()=> dispatch =>{
	return new  Promise( async(resolve, reject) =>{
		console.log("Requesting the line items for this company..."); 
		const response = await server.getCompanyItems(); 
		const data  = await response.json(); 

		if(response.ok){
			let obj = {list: JSON.parse(data).itemlist }; 
			dispatch( receiveLineItems(obj) ); 
			resolve(); 
		}else{
			dispatch( noItemList() ); 
			resolve(); 
		}

	})
}

export const addQuickbooksDetails = (myID, detailsObj) =>dispatch => {
	return new Promise( async ( resolve, reject) =>{
		let obj = {}; 
		console.log("data: ", myID, detailsObj)
		obj.invoiceID = myID; 
		obj.details = detailsObj; 

		const response = await server.updateQuickbooksDetails(obj); 
		const data = await response.json(); 

		if(response.ok) {

			dispatch( getInvoice( JSON.parse(data).invoice) )
			resolve(true)
		}else{
			resolve(false)
		}
	})	
}
