import React from 'react'
import {Navigate, useLocation, useNavigate, redirect, Outlet} from "react-router-dom"; 
import {useSelector, useDispatch} from "react-redux";
import { connect } from "react-redux";


const mapStateToProps = ({ session }) => ({
  session
});

const ProtectedRoutes = ( { session }) => {
	console.log(`this the session: `, session)
	if(session != null && session.username != null){
		return <Outlet />
	}else{
		return <Navigate to='/login' />; 
	}

}

export default connect(
  mapStateToProps,
)(ProtectedRoutes);