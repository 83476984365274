import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function Remarks(){
	const report = useSelector((state) => state.universal_report)

	function onOff(bool1){
		if(bool1){
			return "On"
		}else{
			return "Off"
		}
		
	}

	function yesNo(bool1){
		if(bool1){
			return "Yes"
		}else{
			return "No"
		}
		
	}
	return(
		<Container>
			<Row>
				<Col>
					<h5> Remarks </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<p>
						<strong>SO #1 found:  </strong> { onOff( report.results.so1)}<br/>
						<strong>SO #2  found: </strong> { onOff( report.results.so2)}<br/>
						<strong>Upstream found: </strong> { onOff( report.results.upstream) }<br/>
						<strong> Service Restored? </strong> { yesNo( report.results.restored)} 
					</p>
					<p>
						<strong> Comment: </strong> {report.results.comments}
					</p>
				</Col>
			</Row>
		</Container>
	)
}