import {
  GET_BILLING_DETAILS
} from "../../actions/customerApp";

const _nullBillingDetails = null
                
export default (state = _nullBillingDetails, { type, billing }) => {
  Object.freeze(state);
  switch (type) {
    case GET_BILLING_DETAILS:
      return billing;
    case "": 
      return _nullBillingDetails; 
    default:
      return state;
  }
};