import {
  SCHEDULE_SERVICE_LIST, 
  CLEAR_SERVICE_LIST
} from "../../actions/addressApp";

const _nullServicesList =[]
                
export default (state = _nullServicesList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SCHEDULE_SERVICE_LIST:
      return list;
    case CLEAR_SERVICE_LIST: 
      return _nullServicesList; 
    default:
      return state;
  }
};