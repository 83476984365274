import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import {format} from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";


export default function System(){
	const report = useSelector((state) => state.universal_report)
	return(
		<Container>
			<Row>
				<Col>
					<h5> System </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<h6> Initial </h6>
				</Col>
				<Col>
					<Form.Check 
						label="Passed" 
						checked = {report.results.initialTest_pass}
					/>
				</Col>
				<Col>
					<Form.Check
						label="Failed" 
						checked = {report.results.initialTest_fail}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<h6> Final </h6>
				</Col>
				<Col>
					<Form.Check 
						label="Passed" 
						checked = {report.results.repair_pass}
					/>
				</Col>
				<Col>
					<Form.Check
						label="Failed" 
						checked = {report.results.repair_fail}
					/>
					
					
				</Col>
			</Row>
			<Row>
				<Col>
					<p>
						<strong> Tested On: </strong> {format ( new Date(report.results.initialTest_date), "MM/dd/yyyy")}<br/>
						<strong>DMR: </strong> {report.results.initialTest_dmr}<br/>
						<strong>PSI: </strong>{report.results.initialTest_system_psid}
					</p>
					<Form.Check  
						label = "Cleaned"
						checked = { report.results.cleaned }
						readOnly
					/>
					<Form.Check  
						label = "Repaired"
						checked = { report.results.repaired }
						readOnly
					/>
				</Col>
			</Row>
		</Container>
	)
}