import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {requestDetails} from "../../../actions/report.js"
import { format } from "date-fns"
import { newCustomerID, createCustomer, associateAddressToCustomer } from "../../../actions/customerApp.js"
import { determineID, updateTrack} from "../../../actions/addressApp"
import { requestNextPop, 
	requestNextPopNoDate, 
	requestReport, 
	requestTotalNotYetSent,
	 requestTestReport} from "../../../actions/waterDelivery.js"
import Overlay from 'react-bootstrap/Overlay';

export default function TestDetails(){
	const obj = useSelector( (state) => state.report_details)
	const report = useSelector( (state) =>state.testReport)
	const reportID = useSelector((state) => state.reportID )
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [dd, setDD] = useState(new Date())
	const [trueDD, setTrueDD] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const [track, setTrack] = useState(null)
	const target = useRef(null)
	const [serviceType, setServiceType] = useState(false)

	useEffect( () =>{
		if(report != null){
			switch( report.serviceType.toUpperCase() ){
				case "E": 
					setServiceType("Existing")
					break; 
				case "NEW": 
					setServiceType("New")
					break; 
				case "RPL":
				case "REPLACED":
					setServiceType("Replaced"); 
					break; 
				case "RM": 
					setServiceType("Removed"); 
					break; 
				case "RP":
				case "REPAIR":
					setServiceType("Repair"); 
					break; 
				default: 
					setServiceType("Not determined")
				break; 
			}
		}	
	}, [])

	useEffect(()=>{
		if(report != null){
			setTrack(report.track_num)
		}
	}, [report])


	const upTrack  = ()=>{
		return new Promise((resolve, reject) =>{
			setLoader(true)
			updateTrack( report.addressID, track).then((data, err)=>{
				dispatch( requestTestReport(reportID) ).then((data, err) =>{
					console.log("got the test report")
					setShowSuccess(true)
					setTimeout(() => setShowSuccess(false), 500)
					setLoader(false)
				})
			})
		})
	}


	useEffect(()=>{
		console.log(report.initialTest_date)
		let myDate = new Date(report.initialTest_date);
		myDate.setDate(myDate.getDate());
		setDD(myDate)
		setTrueDD(true)
		
	}, [])


	return(
		<Container>
			
				
			{
				report ?
					<>
					<Row>
							
										
											<Col>
												<h5>  Test Details</h5>
											
											</Col>
											<Col>
											<Button
												size="sm"
												variant="link"
												onClick={
													() => {
														dispatch( determineID( report.addressID) ).then((data, err) =>{
															
														}) 
														dispatch(
															newCustomerID(report.accountID)).then((data, err) => {
														console.log("")
													})
												}}
									> Open client view </Button>
											</Col>
										
									</Row>
									<Row>
										<Col>
										<strong> Service Address</strong>
								{
									obj ?
										<>
											<p>
												{obj.location_name} <br />
												{obj.street}, {obj.city},
												{obj.state}, {obj.zipcode} <br/> <br/>

												<strong>Test Date: </strong>

												{
													trueDD ?
														<>
															<u>{format(new Date(dd), "MM/dd/yyyy")}</u> <br />
														</>
														:
														<>
															Loading date ... <br />
														</>
												}
												<strong>Report ID: </strong> {reportID}
											</p>
										
										</>
										:
										<Spinner />

								}
										

										<InputGroup size= "sm">
											<InputGroup.Text> Track #:  </InputGroup.Text>
											<Form.Control
												value = {track}
												onChange = { (event) =>{
													setTrack(event.target.value)
												}} 
												
											/>
											<Button onClick = {upTrack} ref= {target}>
												{
													loader ?
														<Spinner />
													: 
														<> 
															update
													</>
												}
												
											</Button>
											<Overlay show={showSuccess} target={target.current} placement = "right">
												 {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
            fontSize: '12px',
              position: 'absolute',
              backgroundColor: 'rgba(60, 179, 113, 0.85)',
              padding: '5px 3px',
              color: 'white',
              borderRadius: 10,
              ...props.style,
            }}
          >
            Updated!
          </div>
        )}
											</Overlay>
										</InputGroup>
										
								
									</Col>
								</Row>
					</>

					:
					<> </>
					
			}
			<br/>
			<Row>
						<Col>
							<p>
								<strong>Test Type:</strong> {serviceType}
							</p>
						</Col>		
			</Row>
		</Container>
	)
}