export const prepaid = (invoiceID) => (
  fetch("/backflow/invoice/prepaid", {
      method: "PUT",
      body : JSON.stringify( { invoiceID: invoiceID} ),
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)

export const requestPendingTotal = () => (
  fetch("/backflow/invoice/tt", {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)

export const connect = (obj, csrf) => ( 
  fetch("/server/quickbooks", {
    method: "POST",
    body: JSON.stringify(obj),
    headers: {
      "Content-Type": "application/json", 
      "Cache-Control": "no-store, no-cache"
    }
  })
);

export const disconnect = () =>(
  fetch("/server/quickbooks", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json", 
      "Cache-Control": "no-store, no-cache"
    }
  })
)

export const getCSRF = () => (
  fetch("/csrf", {
      method: "GET", 
      credentials: "include", 
      headers: {
        "Content-Type": "application/json", 
       "Cache-Control": "no-store, no-cache"
      }
  })
)


export const checkConnection = ()=>(
  fetch("/server/quickbooks/check", {
    method: "GET",
    headers: {
      "Content-Type": "application/json", 
      "Cache-Control": "no-store, no-cache"
    }
  })


)


export const requestOverdueInvoices = () => (
  fetch("/backflow/invoice/requestOverdue", {
      method: "GET",
      headers: {
        "Content-Type": "application/json", 
        "Cache-Control": "no-store, no-cache"
      }
  })
)
export const getInvoices = ()=> (
  fetch("backflow/invoice/pending", {
    method: "POST", 
    headers: {
            "Content-Type" : "application/json"
        }
  }) 
)

export const markAsEntered = (obj, token) =>(
  fetch("backflow/invoice/quickbooksEntered", {
    method: "POST", 
    body: JSON.stringify(obj),  
    headers: {
            "Content-Type" : "application/json", 
          "Cache-Control": "no-store, no-cache",
          "x-csrf-token" : token
        }
  }) 
)

export const createQBInvoice = (obj, token) =>(
  fetch("/backflow/invoice/createInvoice", {
    credentials: 'include', 
    method: "POST",  
    body: JSON.stringify( obj ), 
    headers: {
            "Content-Type" : "application/json", 
          "Cache-Control": "no-store, no-cache",
          "x-csrf-token" : token
        }
  })

)

export const requestPaymentLink = (obj) => (
  fetch("/backflow/invoice/getPaymentLink", {
    credentials: 'include', 
    method: "POST",  
    body: JSON.stringify( obj ), 
    headers: {
          "Content-Type" : "application/json", 
          "Cache-Control": "no-store, no-cache",
        }
  })
)
export const requestInvoicePDF = (obj) => (
  fetch("/backflow/invoice/getInvoicePDF", {
    credentials: 'include', 
    method: "POST",  
    body: JSON.stringify( obj ), 
    headers: {
          "Content-Type" : "application/json", 
          "Accept" : "application/octet-stream", 
          "Cache-Control": "no-store, no-cache",

        }
  
  })
)