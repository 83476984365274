import {
  SET_INVOICE_TOTAL
} from "../../actions/invoice";

const _nullTotal =  null
                
export default (state = _nullTotal, { type, total }) => {
  Object.freeze(state);
  switch (type) {
    case SET_INVOICE_TOTAL:
      return total;
    case "": 
      return _nullTotal; 
    default:
      return state;
  }
};