import {
SET_LINE_ITEMS_LIST_BUILDER , 
 CLEAR_LINE_ITEMS_LIST_BUILDER
} from "../../actions/invoiceBuilder";

const _nullObj =  []
                
export default (state = _nullObj, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case  SET_LINE_ITEMS_LIST_BUILDER:
      return list;
    case CLEAR_LINE_ITEMS_LIST_BUILDER: 
      return _nullObj
    case "": 
      return _nullObj; 
    default:
      return state;
  }
};