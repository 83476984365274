import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	requestDate, 
	requestResults, 
	requestWaterDistrict
} from "../../../actions/dump"

export default function DumpRow(props){
	const [date, setDate] = useState(null); 
	const [results, setResults] = useState(null);
	const [status, setStatus] = useState(null) 
	const [waterDistrict, setWaterDistrict] = useState(null)
	const dispatch = useDispatch(); 

	function passFail(bool1, bool2){	
		return new Promise((resolve, reject) =>{
			if(bool1){
				resolve( "PASS" )
			}
			if(bool2){
				resolve( "FAIL")
			}
			resolve( "NA") 
		})
			
	}
	useEffect( ()=>{
		 requestDate(props.id).then((data, err)=>{
			setDate(data)
		})
		 requestResults(props.id).then( async (data, err)=>{
		 	console.log("Results: ", data)
		 	if(data.length > 0 ){
		 		setStatus(data[0].status)
		 		let myRes = await passFail(Boolean( data[0].initialTest_pass) , Boolean( data[0].initialTest_fail))
		 		setResults( myRes )
		 	}
			
		})
		 requestWaterDistrict(props.id).then((data, err)=>{
		 	//console.log("water district: ", data)
		 	if(data.length > 0){
		 		setWaterDistrict(data[0].districtName)
				//setResults(data[0].initialTest_pass)
		 	}
		})
	}, [])


	return(
		<tr>
			<td>{props.date}</td>
			<td>{status}</td>
			<td>{results}</td>
			<td>{waterDistrict}</td>
		</tr>
	)
}