import {
    SET_TESTER_ID,
    CLEAR_TESTER_ID
} from "../../actions/employee";

const _nullID =  null; 
                
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_TESTER_ID:
          return id;
      case CLEAR_TESTER_ID:
          return _nullID;
    case "": 
      return _nullID; 
    default:
      return state;
  }
};