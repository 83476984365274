import * as server from "../util/universal_invoice";

export const SET_UNIVERSAL_INVOICE_ID = "SET_UNIVERSAL_INVOICE_ID"
export const SET_UNIVERSAL_BILLING_ID = "SET_UNIVERSAL_BILLING_ID"

export const SET_UNIVERSAL_ITEM_LIST = "SET_UNIVERSAL_ITEM_LIST"
export const SET_UNIVERSAL_DUE_DATE = "SET_UNIVERSAL_DUE_DATE"
export const SET_UNIVERSAL_PURCHASE_ORDER = "SET_UNIVERSAL_PURCHASE_ORDER"
export const SET_UNIVERSAL_SALES_TAX = "SET_UNIVERSAL_SALES_TAX"
export const SET_UNIVERSAL_STATUS = "SET_UNIVERSAL_STATUS"
export const SET_UNIVERSAL_TOTAL = "SET_UNIVERSAL_TOTAL"
export const SET_UNIVERSAL_TAXED_TOTAL = "SET_UNIVERSAL_TAXED_TOTAL"
export const SET_UNIVERSAL_TAX_RATE = "SET_UNIVERSAL_TAX_RATE"


export const CLEAR_UNIVERSAL_INVOICE_ID = "CLEAR_UNIVERSAL_INVOICE_ID"
export const CLEAR_UNIVERSAL_BILLING_ID = "CLEAR_UNIVERSAL_BILLING_ID"
export const CLEAR_UNIVERSAL_ITEM_LIST = "CLEAR_UNIVERSAL_ITEM_LIST"
export const CLEAR_UNIVERSAL_DUE_DATE = "CLEAR_UNIVERSAL_DUE_DATE"
export const CLEAR_UNIVERSAL_PURCHASE_ORDER = "CLEAR_UNIVERSAL_PURCHASE_ORDER"
export const CLEAR_UNIVERSAL_SALES_TAX = "CLEAR_UNIVERSAL_SALES_TAX"
export const CLEAR_UNIVERSAL_STATUS = "CLEAR_UNIVERSAL_STATUS"
export const CLEAR_UNIVERSAL_TOTAL = "CLEAR_UNIVERSAL_TOTAL"
export const CLEAR_UNIVERSAL_TAXED_TOTAL = "CLEAR_UNIVERSAL_TAXED_TOTAL"
export const CLEAR_UNIVERSAL_TAX_RATE = "CLEAR_UNIVERSAL_TAX_RATE"


const setInvoiceID = (id) => ({type: SET_UNIVERSAL_INVOICE_ID, id})
const setBillingID = (id) => ({type: SET_UNIVERSAL_BILLING_ID, id})
const setItemList = (list) => ({type: SET_UNIVERSAL_ITEM_LIST, list})
const setDueDate = (str) => ({ type: SET_UNIVERSAL_DUE_DATE, str})
const setPurchaseOrder = (str) => ({type: SET_UNIVERSAL_PURCHASE_ORDER, str})
const setSalesTax = (str) => ({type: SET_UNIVERSAL_SALES_TAX, str})
const setStatus = (str) => ({type: SET_UNIVERSAL_STATUS, str})
const setTotal = (str) => ({type: SET_UNIVERSAL_TOTAL, str})
const setTaxedTotal = (str) => ({type: SET_UNIVERSAL_TAXED_TOTAL, str})
const setTaxRate = (str) => ({type: SET_UNIVERSAL_TAX_RATE, str})

const clearInvoiceID = () => ({ type: CLEAR_UNIVERSAL_INVOICE_ID })
const clearBillingID = () => ({ type: CLEAR_UNIVERSAL_BILLING_ID })
const clearItemList= () => ({ type: CLEAR_UNIVERSAL_ITEM_LIST })
const clearDueDate = () => ({ type: CLEAR_UNIVERSAL_DUE_DATE })
const clearPurchaseOrder = () => ({ type: CLEAR_UNIVERSAL_PURCHASE_ORDER })
const clearSalesTax = () => ({ type: CLEAR_UNIVERSAL_SALES_TAX })
const clearStatus = () => ({ type: CLEAR_UNIVERSAL_STATUS })
const clearTotal = () => ({ type: CLEAR_UNIVERSAL_TOTAL })
const clearTaxedTotal = () => ({ type: CLEAR_UNIVERSAL_TAXED_TOTAL })
const clearTaxRate = () => ({ type: CLEAR_UNIVERSAL_TAX_RATE })

export const clearAllInvoice = () => dispatch => {
	return new Promise( async(resolve, reject) =>{
		let promises = []; 
		promises.push( dispatch(clearInvoiceID() ) )
		promises.push(  dispatch(clearBillingID() ) )
		promises.push( dispatch(clearItemList() ) )
		promises.push( dispatch(clearDueDate() ) )
		promises.push( dispatch(clearPurchaseOrder() ) )
		promises.push(  dispatch(clearSalesTax() ) )
		promises.push(  dispatch(clearStatus() ) )
		promises.push(  dispatch(clearTotal() ) )
		promises.push( dispatch(clearTaxedTotal() ) )
		promises.push( dispatch(clearTaxRate() ) )
		Promise.all(promises).then((data, err) =>{
			resolve()
		})
	})
}

export const reduceInvoiceID = (id) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		resolve(dispatch(setInvoiceID(id)) )
	}) 
}
export const reduceTotal = (total) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		resolve(dispatch(setTotal(total)) )
	}) 
}


export const requestInvoiceDetails= (invoiceID) => dispatch =>{
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestInvoiceDetails(invoiceID)
		const data = await response.json()
		
		if(response.ok){
			let obj = JSON.parse(data).obj
			let promises = []
			promises.push( dispatch( setBillingID(obj.billingDetailID ) ) )
			promises.push( dispatch( setDueDate(obj.due_date)) ) 
	
			Promise.all(promises).then((data, err) =>{
					resolve() 
			})
		}
	})
}

export const requestBillingDetails = (billingID) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestBillingDetails(billingID)
		const data = await response.json()
		if(response.ok){
			let obj = JSON.parse(data)
			resolve(obj.obj) 
		}
	})
}

export const requestLineItems = (invoiceID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestLineItems(invoiceID)
		const data = await response.json()
		if(response.ok){
			dispatch(setItemList(JSON.parse(data).list) )
			resolve() 
		}

	})
}

export const requestLineItemDetails = (itemID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestLineItemDetails(itemID)
		const data = await response.json()
		if(response.ok){
			resolve(JSON.parse(data).obj) 
		}
	})
}

export const addLineItem = (invoiceID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.addLineItem(invoiceID)
		const data = await response.json()
		if(response.ok){
			resolve() 
		}
	})
}
export const removeLineItem= (itemID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.removeLineItem(itemID)
		const data = await response.json()
		if(response.ok){
			resolve(JSON.parse(data)) 
		}
	})
}

export const updateLineItem = (itemID, obj) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateLineItem(itemID, obj)
		const data = await response.json()
		if(response.ok){
			resolve(JSON.parse(data)) 
		}
	})
}

export const updateBillingDetail = (billingID, obj) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.updateBillingDetail(billingID, obj)
		const data = await response.json()
		if(response.ok){
			resolve(JSON.parse(data)) 
		}
	})
}








