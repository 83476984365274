export const  getBaseURL  = () =>(
	fetch("backflow/card_payment/tokenURL", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const  createCharge  = (token, total) =>(
	fetch("backflow/card_payment/charge", {
		method: "POST",
		body: JSON.stringify( { token: token, total: total } ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const  sendConfirmation = (email, confirmationType) =>(
	fetch("backflow/card_payment/confirmation", {
		method: "POST",
		body: JSON.stringify( { email: email, type: confirmationType} ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const  captureCard = (cardID, amount) =>(
	fetch("backflow/card_payment/capture", {
		method: "POST",
		body: JSON.stringify( { cardID: cardID, amount: amount} ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const  createPayment = (invoiceID, amount) =>(
	fetch("backflow/card_payment/payment", {
		method: "POST",
		body: JSON.stringify( { invoiceID: invoiceID, amount: amount} ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const createSalesReceipt = (paymentID) =>(
	fetch("backflow/card_payment/salesReceipt", {
		method: "POST",
		body: JSON.stringify( { paymentID: paymentID} ) ,
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)




