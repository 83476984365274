import * as server from "../util/server";

export const RECEIVE_RESULTS = "RECEIVE_RESULTS"

const getResults = (resultlist) =>({
	type: RECEIVE_RESULTS, 
	resultlist
})

export const searchQuery = ( query ) => dispatch =>{
	return new Promise( async(resolve, reject) => {
		let obj = { query: query }
		const response = await server.searchResult( obj )
		const data = await response.json()

		if(response.ok){
			let myobj = { resultsList: JSON.parse(data).list, addresses: JSON.parse(data).addresses}
			resolve( dispatch( getResults ( myobj) ))
		}

	})

}
