import {
  SET_ACTIVE_LIST, CLEAR_ACTIVE_LIST
} from "../../actions/workorder.js";

const _null = []
                
export default (state = _null, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ACTIVE_LIST:
      return list;
    case CLEAR_ACTIVE_LIST: 
      return _null;
    case "": 
      return _null; 
    default:
      return state;
  }
};