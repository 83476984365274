import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { getList, getTotal, getTotals, determineDate} from "../../../actions/waterDelivery.js"
import {format, add, sub} from "date-fns"
import RowRowRow from "./RowRowRow"

export default function ByDay(){
	const [dateSelected, setDateSelected] = useState(null); 
	const currentDate = useSelector((state) => state.deliveryDate) 
	const total = useSelector((state) => state.deliverytotal)
	const list = useSelector((state) => state. deliverylist)
	const dispatch = useDispatch(); 
	const [showRows, setShowRows] = useState(false)

	useEffect(()=>{
		setDateSelected(format(new Date( currentDate ), "yyyy-MM-dd") ) 
	}, [])

	useEffect(()=>{	
		if(total == 0){
			setShowRows(false)
		}else{
			setShowRows(true)
		}
	}, [total])

	useEffect( ()=>{

		dispatch( determineDate(dateSelected) ) .then((data, err)=>{
		
		})
	},[dateSelected])

	const [loadList, setLoadList] = useState(false)
	function refreshList(){
		if(currentDate != null ){
			setLoadList(true)
			dispatch( getList( currentDate) ).then((data, err) =>{
				console.log("Got the list!")
				setLoadList(false)
			})
			dispatch( getTotal( currentDate) ).then((data, err) =>{
			})
			
		}

	}

	useEffect(()=>{
		console.log("Current Date: ", currentDate)
		if(currentDate != null ){
			setLoadList(true)
			dispatch( getList( currentDate) ).then((data, err) =>{
				console.log("Got the list!")
				setLoadList(false)
			})
			dispatch( getTotal( currentDate) ).then((data, err) =>{
			})
			
		}
	}, [currentDate])

	const renderWaterDistricts = list.map( (ww) =>
		<RowRowRow ww={ww} refreshList = { ()=>refreshList() }/>
	)

	return(
		<Container id="appContent">
			<Row>
				
				<Col sm md lg ={ 10}>
					<Form.Control
						type="date"
						value={dateSelected}
						onChange = { (event) => setDateSelected(event.target.value)}
					/>
				</Col>
				<Col sm md lg = {2}>
					<ButtonGroup>
					<Button size="sm" variant="secondary" onClick = { 
							()=>{ 
								let newDate = new Date( dateSelected); 
								newDate.setDate(newDate.getDate()); 
								setDateSelected( format( new Date( newDate), "yyyy-MM-dd") )
						} 
					}> 
						Prev 
					</Button>
			

					<Button size="sm" variant="secondary" onClick = { 
							()=>{ 
								console.log()
								let newDate = new Date( dateSelected); 
								newDate.setDate(newDate.getDate() + 2); 
								setDateSelected( format( new Date( newDate), "yyyy-MM-dd") )
							} 
					} > Next </Button>
					</ButtonGroup>
				</Col>
			</Row>
			<strong> Total: {total} </strong>
			<Table>
				<thead>
					<tr>
						<th>Water District</th>
						<th>Delivery Method</th>
						<th>delivery status</th>
						<th>Actions </th>
					</tr>
				</thead>
				<tbody>

					{
						showRows ?
							<>
								{
									loadList ?
										<Spinner />
									: 
										<>{ renderWaterDistricts} </>	
								}
							</>
							
						: 
							<> No test reports to send for this date </>
					}
				</tbody>
			</Table>
		</Container>
	)
}