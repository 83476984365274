import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { updateScheduleServiceList} from "../../../../actions/addressApp"


export default function NewStopRow(props){
	const [selected, setSelected] = useState(true)
	const [service, setService]  = useState("TEST")
	const [allow, setAllow] = useState(false)
	const currentList = useSelector( (state) => state.schedule_services_list); 
	const dispatch = useDispatch(); 
	const assemblyList = useSelector((state) => state.address_assemblies)
	const [filtered, setFiltered] = useState([])

	useEffect(() =>{
		let newlist = []
		for(let i = 0; i < assemblyList.length; i++){
			if(assemblyList[i].state.toUpperCase() != "REMOVED"){
				newlist.push(assemblyList[i])
			}
		}
		setFiltered(newlist)
	}, [assemblyList])

	useEffect(()=>{
		if(!selected){
			setService("NONE")
		}else{
			setService("TEST")
		}
	}, [selected])

	useEffect(()=>{
		if(props.assembly.state.toUpperCase() == "REMOVED"){
			setAllow(false)
		}else{
			setAllow(true)
		}
	}, [])

	useEffect(()=>{
		let newList = []; 
		for(let i = 0; i < filtered.length;  i++){
				if(  i == props.index){
					let obj = {};
					obj.assemblyID = filtered[i].id;
					obj.serviceType = service; 
					obj.selected = selected; 
					newList.push(obj)
				}else{
					let obj = {};
					obj.assemblyID = filtered[i].id;
					obj.serviceType = currentList[i].serviceType; 
					obj.selected = currentList[i].selected; 
					newList.push(obj)
				}
		}
		dispatch( updateScheduleServiceList(newList) ).then((resolve, reject) =>{
			console.log("Updated the current service list ")
		})
		
	}, [selected, service])

	useEffect(()=>{
		console.log("Filtered: ", filtered)
		let newList = []
		for(let i = 0; i < filtered.length;  i++){
			let obj = {};
			obj.assemblyID = filtered[i].id;
			obj.serviceType = "TEST"; 
			obj.selected = true; 
			newList.push(obj)
		}
		dispatch( updateScheduleServiceList(newList) ).then((resolve, reject) =>{
			console.log("Updated the current service list ")
		})
	}, [filtered])


	return(
		<>	
	
			
			<tr>
				<td>
					<Form.Check checked ={selected} onClick = {(event) => setSelected(event.target.checked)} /> 
				</td>
				<td>
					{props.assembly.serial_number}
				</td>
				<td>
					{props.assembly.location}
				</td>
				<td>
					<Form.Select size="sm" value ={service} onChange= {(event) => setService(event.target.value) }>
						<option value="NONE">No Service</option>
						<option value="TEST">Test</option>
						<option value="REPAIR">Repair</option>
						<option value="REPLACEMENT">Replacement</option>
						<option value ="REMOVE">Remove</option>
						<option value="INSTALL">New install</option>
					</Form.Select>
				</td>
					</tr>
		
					
		</>
	)
}