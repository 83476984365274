import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect } from 'react'; 
import {useSelector, useDispatch} from "react-redux";
import ItemLine from "./ItemLine"
import { 
	balanceSetter,
 	addItem, 
 	getSalesTax
 	 } from "../../../actions/invoice.js"

export default function InvoiceItems(){
	const itemlist = useSelector( (state) => state.invoice_line_items)
	const invoice = useSelector( (state) => state.invoice) 
	const invoiceID = useSelector( (state) => state.invoiceID)
	const balance = useSelector( (state) => state.invoice_balance) 
	const [strBalance, setStrBalance] = useState('')
	const dispatch = useDispatch(); 
	const tax_rate = useSelector((state) => state.invoice_tax_rate)
	const [taxRateLoader, setTaxRateLoader] = useState(true)


	const renderItems = itemlist.map( (item) =>{
		return(
			<ItemLine  item = {item}/>
		)
	} )

	useEffect(()=>{
		if(invoice != null){
			setTaxRateLoader(true)
			dispatch( getSalesTax(invoice.serviceAddressID)).then((data, err) =>{
				console.log("Done getting the sales tax")
				setTaxRateLoader(false)
			})
		}
	}, [invoice])

	useEffect(()=>{
		setStrBalance(balance.toFixed(2))
	}, [balance])

	
	return(
		<>
				
			<Table size="sm" responsive bordered striped>
				<thead>
					<tr>
						<th>
							Item Name
						</th>
						<th>
							Description
						</th>
						<th>
							Quantity
						</th>
						<th>
							Unit Price
						</th>
						<th>
							Taxable
						</th>
						<th>
							Amount
						</th>
						<th>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
			
					{renderItems}
					<tr> 
						<td colSpan={6}>
							<Button size="sm"
								onClick = { () => {
									dispatch(addItem(invoiceID)).then((data, err) =>{
										console.log("Added an item!")
									})
								}}
							> + 
							</Button> 
						</td>
					</tr>
					<tr>

					</tr>
					{
						taxRateLoader ?
							<> </>
						: 
						<>
							<tr>
								<td colSpan ={4}>Tax Rate</td>
								<td colSpan = {3}>{Number( tax_rate.rate) * 100}%</td>
							</tr>
							<tr>
								<td colSpan = {4}> Total With Tax </td>
								<td colSpan = {3}> ${strBalance}</td>
							</tr>
						</>
					}
							
				</tbody>
			</Table>

		</>
	)
}