import {
  GET_REPORT_LIST
} from "../../actions/report";

const _nullOnlineList = []
                
export default (state = _nullOnlineList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_REPORT_LIST:
      return list;
    case "": 
      return _nullOnlineList; 
    default:
      return state;
  }
};