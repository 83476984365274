import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";


export default function Tester(){
	const report = useSelector((state) => state.universal_report)
	return(
		<>{

					report.tester ?
						<Container>
							<Row>
								<Col>
									<h5> Tester </h5>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>
										<strong>Name:</strong> {report.tester.tester_name}<br/>
										<strong>Oregon Certification: </strong> {report.tester.OR_cert}<br/>
										<strong>Washington Certification: </strong> {report.tester.WA_cert}<br/>
									</p>
								</Col>
							</Row>
						</Container>
				:
					<></>

		}</>
	
		
	)	
}