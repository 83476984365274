
import * as server from "../util/workorder"

export const SET_WORK_ORDER_LIST = "SET_WORK_ORDER_LIST"
export const CLEAR_WORK_ORDER_LIST = "CLEAR_WORK_ORDER_LIST"

export const SET_ACTIVE_LIST = "SET_ACTIVE_LIST"
export const CLEAR_ACTIVE_LIST = "CLEAR_ACTIVE_LIST"

export const SET_SELECTED_WO = "SET_CLEAR_WO"
export const CLEAR_SELECTED_WO = "CLEAR_SELECTED_WO"


const setWOList = (list) => ({type: SET_WORK_ORDER_LIST, list})
const clearWOList = () => ({type: CLEAR_WORK_ORDER_LIST})

const setWOActiveList = (list) =>({ type: SET_ACTIVE_LIST, list})
const clearWOActiveList = () =>({ type: CLEAR_ACTIVE_LIST })

const setWorkOrderSelected = (id) => ({ type: SET_SELECTED_WO, id })
const clearWoSelected = () => ({ type: CLEAR_SELECTED_WO})


export const requestActiveWorkOrders = () => dispatch => {
	return new Promise ( async(resolve, reject) =>{	
		const response = await server.requestActiveWorkOrders() ; 
		const data = await response.json()
		if(response.ok){
			dispatch( setWOActiveList(JSON.parse(data).list) )
			resolve()
		}
	})
}

export const reduceWorkOrderSelected = (id) => dispatch => {
	return new Promise ( async(resolve, reject) =>{	
		dispatch( setWorkOrderSelected(id) )
		resolve()
	})
}

export const requestList = (accountID) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestList(accountID)
		const data = await response.json()
		if(response.ok){
			dispatch( setWOList( JSON.parse(data).list ))
			resolve() 
		}
	})
}

export const requestDetails = (woID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestDetails(woID)
		const data = await response.json()
		if(response.ok){
			resolve(JSON.parse(data).obj) 
		}
	})
}


export const createOrder= (obj, accountID) => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.createOrder(obj, accountID)
		const data = await response.json()
		if(response.ok){
			resolve() 
		}
	})
}






