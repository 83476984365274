import {
  SET_ROUNDS_NEW_FORM
} from "../../actions/rounds.js";

const _nullBool = false;
                
export default (state = _nullBool, { type, show }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_NEW_FORM:
      return show;
    case "": 
      return _nullBool; 
    default:
      return state;
  }
};