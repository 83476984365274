import {
  SET_QUERY, 
  CLEAR_QUERY
} from "../../actions/districtManagement";;
const _nullQuery = null;  
                
export default (state = _nullQuery, { type, query }) => {
  Object.freeze(state);
  switch (type) {
    case SET_QUERY:
      return query;
    case CLEAR_QUERY: 
      return _nullQuery; 
    case "": 
      return _nullQuery; 
    default:
      return state;
  }
};