import * as server from "../util/newstop.js"

export const SET_ADDRESS  = "SET_ADDRESS"; 
export const SET_CONTACT = "SET_CONTACT"; 
export const SET_DETAILS = "SET_DETAILS"; 
export const SET_SERVICES = "SET_SERVICES"; 


const receiveAddress = (obj) => ({
	type: SET_ADDRESS, 
	obj
})
const receiveContact = (obj) => ({
	type: SET_CONTACT, 
	obj
})
const receiveDetails = (obj) => ({
	type: SET_DETAILS, 
	obj
})

const receiveServices = (list) => ({
	type: SET_SERVICES, 
	list
})


export const setAddress = (obj) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			dispatch( receiveAddress(obj) )
			resolve()
		}catch(err){

		}
	})
} 
export const setContact = (obj) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			dispatch( receiveContact(obj) )
			resolve()
		}catch(err){

		}
	})
} 
export const setDetails = (obj) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			dispatch( receiveDetails(obj) )
			resolve()
		}catch(err){

		}
	})
} 

export const setServices = (list) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			dispatch( receiveServices(list) )
			resolve()
		}catch(err){

		}
	})
} 

export const createStop = (obj) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		try{
			const response = await server.createStop(obj); 
			const data  = await response.json() ; 
			if(response.ok ){
				resolve()
			}
		}catch(err){

		}
	})
}
