import * as server from "../util/server";

export const RECEIVE_ADDRESSES = "RECEIVE_ADDRESSES"; 
export const RECEIVE_CONTACT = "RECEIVE_CONTACT"; 
export const RECEIVE_CUSTOMER = "RECEIVE_CUSTOMER"

const receiveCustomer = (customerlist) =>({
	type: RECEIVE_CUSTOMER ,
	customerlist
})
const receiveContact = (contactlist) =>({
	type:RECEIVE_CONTACT, 
	contactlist
})
const receiveAddresses = (addresslist) =>({
	type: RECEIVE_ADDRESSES, 
	addresslist
})	





export const getCustomer = (customerID) => dispatch=>{
	return new Promise( async(resolve, reject) =>{
		let obj = { id:  customerID }
		const response = await server.getCustomer(obj); 
		const data = await response.json();  

		if(response.ok){
			dispatch( receiveCustomer( JSON.parse( data).list )); 
			resolve()
		}
	})
} 

export const getContact = (customerID) =>  dispatch=>{
	return new Promise( async(resolve, reject) =>{
		let obj = { id:  customerID }
		const response = await server.getContact(obj); 
		const data = await response.json();

		if(response.ok){
			dispatch( receiveContact( JSON.parse( data).list )); 
			resolve()
		}
	})
} 
export const getAddresses = (customerID) => dispatch=>{
	return new Promise( async(resolve, reject) =>{
		let obj = { id:  customerID }
		const response = await server.getAddresses(obj); 
		const data = await response.json();

		if(response.ok){
			dispatch( receiveAddresses( JSON.parse( data).list )); 
			resolve()
		}
	})
} 


