import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import LineItem from "./LineItem"
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
  requestLineItems, reduceTotal, addLineItem
} from "../../../.././../../actions/universal_invoice"

export default function LineItems(){
	const lineitems = useSelector((state) => state.universal_invoice_itemlist )
	const invoiceID = useSelector( (state) => state.universal_invoice_id)
	const total = useSelector( (state) => state.universal_invoice_total)
	const taxed_total = useSelector( (state) => state.universal_invoice_taxed_total) 
	const tax_rate = useSelector( (state) => state.invoice_tax_rate)
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch()
	useEffect(()=>{
		setLoading(true)
		dispatch( requestLineItems(invoiceID) ).then((data, err) =>{
			setLoading(false)
		})
	}, [])


	useEffect(()=>{
		let total = 0; 
		for( let i = 0; i < lineitems.length; i++){
			total = total + lineitems[i].amount
		}
		dispatch(reduceTotal(total)).then((data, err) =>{

		})
	}, [lineitems])

	const renderlineitems = lineitems.map((item) =>{
		return(
			<LineItem item = {item} />
		)
	})

	const addItem = () =>{
		setLoading(true)
		addLineItem(invoiceID).then((data, err) =>{
			dispatch( requestLineItems(invoiceID) ).then((data, err) =>{
				setLoading(false)
			})
		})
	}
	return(
		<Container>
			<Row>
				<Col>
					<Table size = "sm" bordered striped>
						<thead>
							<tr>
								<td>Description</td>
								<td> Quantity </td>
								<td> Price </td>
								<td> Taxable </td>
								<td> Amount </td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{
								loading ?
									<Spinner />
								: 
									<>
										{renderlineitems}
									</>

							}

							
							<tr>	
								<td colSpan = {6}>
									<Button 
										onClick = { addItem }
										variant = "secondary" size= "sm"> + </Button>
								</td>
							</tr>
							<tr>
								<td colSpan = {2}></td>
								<td colSpan = {2}>Tax Rate </td>
								<td>{
										tax_rate ?
											<> { Number(tax_rate.rate * 100).toFixed(2)} %</>
										: 
											<> 
												----
											</>
								}</td>
								<td></td>
							</tr>
							<tr>
								<td colSpan = {2}></td>
								<td colSpan = {2}>Total </td>
								<td> ${total}</td>
								<td></td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	)
}