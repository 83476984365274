import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {requestAssembly} from "../../../actions/report.js"
export default function Repairs(){
	const assembly = useSelector( (state) => state.report_assembly)
	const report = useSelector( (state) =>state.testReport)
	const dispatch = useDispatch(); 
	const [loader, setLoader] = useState(false)
	const [myC, setMyComment] = useState("NA")

	function yesno(boolval){
		if(boolval){
			return "Yes"
		}else{
			return "No"
		}
	}


	function onoff(boolval){
		if(boolval){
			return "On"
		}else{
			return "Off"
		}
	}
	useEffect( ()=>{
		let myComment = `${report.comments}`
		if(report.disc){
			myComment = myComment + ";disc"
		}
		if(report.seat){
			myComment = myComment + ";seat"
		}
		if(report.air_disc){
			myComment = myComment + "; Air Inlet Disc"
		}
		if(report.ff){
			myComment = myComment + "; float"
		}
		if(report.spring){
			myComment = myComment + "; spring"
		}
		if(report.guide){
			myComment = myComment + "; guide"
		}
		if(report.diaphragm){
			myComment = myComment + ";diaphragm"
		}
		if(report.o_ring){
			myComment = myComment + ";  O-Rings"
		}
		if(report.module){
			myComment = myComment + "; module"
		}
		if(report.rubber_kit){
			myComment = myComment + "; rubber_kit"
		}
		if(report.air_spring){
			myComment = myComment + "; Air Inlet Spring"
		}
		if(report.check_disc){
			myComment = myComment + "; Check Disc"
		}
		if(report.check_spring){
			myComment = myComment + "; Check Spring"
		}
		if(myComment == "null"){
			setMyComment("NA")
		}else{
			setMyComment(myComment)
		}
		
	}, [report])
	return(
		<Container>
			<Row>
				
				<Col>
					
					<h5> Shut Offs</h5>
				
					<p> <strong># 1 found: </strong> <u>{onoff( report.so1) }</u> <br/>
						<strong># 2 found: </strong> <u>{onoff( report.so2) }</u> <br/>
						<strong>Upstream found: </strong> <u>{onoff( report.upstream) }</u> <br/>
						<strong>Service Restored? </strong> <u> { yesno(report.restored) }</u> <br/>
					</p>

					<h5> Comment </h5>	
					{ myC }
				</Col>
			</Row>
		</Container>
	)
}