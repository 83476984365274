import {
  SET_WORK_ORDER_LIST, CLEAR_WORK_ORDER_LIST
} from "../../actions/workorder.js";

const _null = []
                
export default (state = _null, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_WORK_ORDER_LIST:
      return list;
    case CLEAR_WORK_ORDER_LIST: 
      return _null;
    case "": 
      return _null; 
    default:
      return state;
  }
};