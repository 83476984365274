import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup'
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import Button from 'react-bootstrap/Button';
import EnteredInvoice from "./EnteredInvoice"
import NewItem from "./NewItem"
import { format, add}  from 'date-fns'; 
import {BillingForm, ServiceAddressForm, SalesPO} from "./forms.js"
import BillingInformation from "./BillingInformation"
import ServiceAddress from "./ServiceAddress"
import InvoiceItems from "./InvoiceItems"
import InvoiceActions from "./InvoiceActions"
//import NewItem  from "./NewItem"; 
import { getStops } from '../../../actions/stops.js'
import { getTestReport} from '../../../actions/testReports.js'
import {
getInvoiceDetails, 
removeItem, 
requestPaymentLink, 
requestInvoicePDF, 
requestInvoices, 
sendToQuickbooks, 
prepaid, 
requestInvoiceTotal,
requestBillingDetail, 
requestServiceAddress, 
requestInvoiceLineItems

} from "../../../actions/invoice.js"

import {CheckMarkAnimation}from "../animations.js"


export default function InvoiceDisplay(){
	const invoiceID = useSelector((state) => state.invoiceID)
	const [billingLoader, setBillingLoader] = useState(false)
	const [serviceAddressLoader, setServiceAddressLoader] = useState(false)
	const [invoiceItemsLoader, setInvoiceItemsLoader] = useState(false)
	const [invoiceLoader, setInvoiceLoader] = useState(false)
	const [isEntered, setIsEntered] = useState(false)
	const dispatch = useDispatch(); 

	async function loader(){
		setInvoiceLoader(false)
		let p1 = await dispatch(requestBillingDetail(invoiceID))
		let p2 = await dispatch(requestServiceAddress(invoiceID) )
		let p3 = await dispatch( getInvoiceDetails(invoiceID) )
		let p4 = await dispatch( requestInvoiceLineItems( invoiceID) )
		Promise.all([p1, p2, p3, p4]).then((data, err) =>{
			setInvoiceLoader(true)
		})
	}

	useEffect(() => {
		if(invoiceID != null){
			loader(); 
		}
	}, [invoiceID])

	return(
		<Container className="invoiceScrollMe">
			{
				invoiceLoader ?
					<>
					
						
						<Row>
									<>
										<Col>
											<Container>
													<BillingInformation />
											</Container>
													
										</Col>
									
										<Col>
											<Container>
													<ServiceAddress />
											</Container>									
													
										</Col>
									</>
							

									
						</Row>
						<Row>
							<Col>
								<hr/>
								<InvoiceItems />

							</Col>
						</Row>
					</>
					:
					<Row>
						<Col>
							<Spinner />
						</Col>
					</Row>
			}
		</Container>
	)
}