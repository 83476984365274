import {
  SET_DETAILS
} from "../../actions/newstop";

const _nullDetails = { 
  tester: null, 
  date: new Date(), 
  startTime :  "08:00", 
  endTime: "16:00", 
  comment: null
}
                
export default (state = _nullDetails, { type, obj}) => {
  switch (type) {
    case SET_DETAILS:
      return obj;
    case "": 
      return _nullDetails; 
    default:
      return state;
  }
};