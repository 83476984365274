import {
  SET_ON_SITE_NAME, 
  CLEAR_ON_SITE_NAME
} from "../../actions/scheduling";

const _nullOnSiteName = null; 
                
export default (state = _nullOnSiteName, { type, name }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ON_SITE_NAME:
      return name;
    case CLEAR_ON_SITE_NAME: 
      return _nullOnSiteName
    case "": 
      return _nullOnSiteName; 
    default:
      return state;
  }
};