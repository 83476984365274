
import * as server from "../util/server";
export const RECEIVE_SESSION = "RECEIVE_SESSION"; 	
export const CLEAR_SESSION = "CLEAR_SESSION"
export const RECEIVE_ERROR = "RECEIVE_ERROR"; 
export const CLEAR_ERROR = "CLEAR_ERROR"; 


const updateSession = (session) =>({
	type: RECEIVE_SESSION, 
	session
})


const destroyUser = () => ({
	type: CLEAR_SESSION
})

const receiveError = (error) =>({
	type: RECEIVE_ERROR, 
	error
})
const clearError = () => ({
	type: CLEAR_ERROR
})

export const cleanErrors = () => dispatch =>{
	return new Promise((resolve, reject) =>{
		dispatch(clearError()); 
		resolve(true)
	})
}

export const checkSession = () => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.checkSession();
		const data = await response.json();
		if (response.ok) {
			if (JSON.parse(data).active) {
				dispatch(updateSession(JSON.parse(data).user))
				resolve(true)
			} else {
				dispatch(updateSession(null))
				resolve(false)
			}
		}
	})
}

export const updateSessionDate = (newDate, myUsername) => dispatch => {
	
	return new Promise((resolve, reject) =>{
		let myObj = { username: myUsername , currentDate: newDate } 
		dispatch( updateSession( myObj))
		resolve(true)
	})
}

export const login = (credentials) => async dispatch  =>{
	return new Promise( async (resolve, reject)=>{
		console.log("Gotta log this one in: ", credentials); 
		const response = await server.login(credentials); 
		const data = await response.json(); 

		if(JSON.parse(data).success){
			
			dispatch(updateSession(JSON.parse(data).session ) ); 
			resolve(true); 
		}else{
			console.log("Login failed..."); 
			let errObj = { success: false, message: JSON.parse(data).message }
			dispatch(receiveError(errObj) ); 
			resolve(false)
		}	
	})
	
}

export const logout = () => async dispatch =>{

	console.log("loggin out..."); 

	const response = await server.logout(); 
	const data = await response.json(); 
	if(response.ok){
		return dispatch( destroyUser() ); 
	}else{
		console.log("Logout failed..."); 
	}
}