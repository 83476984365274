import * as server from "../util/card_payment";

export const SET_TOKEN_URL = "SET_TOKEN_URL"
export const CLEAR_TOKEN_URL =  "CLEAR_TOKEN_URL"

export const SET_TOKEN = "SET_TOKEN"
export const CLEAR_TOKEN = "CLEAR_TOKEN"

export const SET_CHARGE_RESULTS = "SET_CHARGE_RESULTS"
export const CLEAR_CHARGE_RESUlTS = "CLEAR_CHARGE_RESULTS"

export const SET_CAPTURE_RESULTS = "SET_CAPTURE_RESULTS"
export const CLEAR_CAPTURE_RESULTS = "CLEAR_CAPTURE_RESULTS"

export const SET_SALES_RECEIPT = "SET_SALES_RECEIPT"
export const CLEAR_SALES_RECEIPT = "CLEAR_SALES_RECEIPT"

export const SET_CONFIRMED = "SET_CONFIRMED"
export const CLEAR_CONFIRMED = "CLEAR_CONFIRMED"

const setTokenURL = (url) => ({ type: SET_TOKEN_URL, url})
const setToken = (token) => ({ type: SET_TOKEN, token})
const setChargeResults = (obj) => ({ type: SET_CHARGE_RESULTS, obj})
const setCaptureResults = (obj) => ({ type: SET_CAPTURE_RESULTS, obj})
const setSalesReceiptResults = (obj) => ({ type: SET_SALES_RECEIPT, obj})

const clearTokenURL = () => ({ type: CLEAR_TOKEN_URL})
const clearToken = () => ({ type: CLEAR_TOKEN})
const clearChargeResults = () => ({ type: CLEAR_CHARGE_RESUlTS})
const clearCaptureResults = () => ({ type: CLEAR_CAPTURE_RESULTS})
const clearSalesReceipt = () => ({ type: CLEAR_SALES_RECEIPT})


export const clearCreditPayment = () => dispatch => {
	return new Promise( async( resolve, reject) =>{
		let promises = []
		promises.push( dispatch( clearTokenURL() )) 
		promises.push( dispatch( clearToken() )) 
		promises.push( dispatch( clearChargeResults() )) 
		promises.push( dispatch( clearCaptureResults() )) 
		promises.push( dispatch( clearSalesReceipt() )) 
		Promise.all(promises).then((data, err) =>{
			resolve()
		})
	})
}

export const getBaseURL = () => dispatch => {
	return new Promise(async( resolve, reject)=>{
		let response = await server.getBaseURL();
		let data = await response.json()
		resolve( dispatch( setTokenURL( JSON.parse(data).url )  ) )
	}) 
}


export const requestToken = (card , tokenURL) => dispatch => {
	return new Promise (async( resolve, reject)=>{
		let response =  await fetch(tokenURL, {
			method: "POST", 
			body: JSON.stringify({card: card}), 
			headers: {
	            "Content-Type" : "application/json"
	        }
		}) 
		let data = await response.json(); 
		console.log("data: ", data)
		dispatch(  setToken( data )  ); 
		resolve( data )

	})
}

export const createCharge = (token, total) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		let response  = await server.createCharge(token, total); 
		let data = await response.json(); 
		if(response.ok){
			dispatch( setChargeResults(JSON.parse(data) )); 
			resolve( JSON.parse(data))
		}
	})
}

export const sendConfirmation = (email, confirmationType) => dispatch => {
	return new Promise ( async(resolve, reject)=> {

	})
}

export const captureCard = (cardID, amount) => dispatch => {
	return new Promise( async(resolve, reject) =>{


	})
}

export const createSalesReceipt = (invoiceID) => {
	return new Promise( async( resolve, reject) =>{

	})
}


export const processCardPayment = (card, tokenURL, amount) => dispatch =>  { 
	return new Promise( async (resolve, reject) =>{
		//get token
		dispatch( requestToken(card, tokenURL) ).then((data, err) =>{
			if( 'errors' in data){
				resolve()
			}else{
				//charge card
				console.log("data: ", data)
				dispatch( createCharge(data.value, amount) ).then((data, err)=>{
					//capture card

				})
			}
		});  
		//sales receipt
	})
}
