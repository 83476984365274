import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import BasicInformation from "./BasicInformation"; 
import { Overlay, FormControl } from 'react-bootstrap';
import AccountDetails from "./AccountDetails"
import SearchBar from "./SearchBar"
import { clearID, clearCustomerReports, clearServicesList, clearCustomerServicesAddresses} from "../../../actions/customerApp.js"
import { clearAddressID, clearAddressObj, clearStopsList, clearAssemblyList} from "../../../actions/addressApp"
import CreateAccount from "./CreateAccount"
import {clearAccount, clearAccountBilling
} from "../../../actions/account"
import { checkSession } from "../../../actions/session.js"
import { clearStop } from "../../../actions/universal_stop.js"
import { clearAllInvoice } from "../../../actions/universal_invoice.js"

export default function CustomerModal(props){
	const customerID = useSelector( (state) => state.customerID); 
	const [show, setShow] = useState(false);
	const [createShow, setCreateShow] = useState(false); 
	const dispatch = useDispatch();

	const clearMe = () => {
		return new Promise(async (resolve, reject) => {
			let p1 = await dispatch(clearAccount());
			let p2 = await dispatch(clearAccountBilling());
			Promise.all([p1, p2]).then((data, err) => {
				console.log("Cleared slices..")
				resolve()
			})
		})
	}

	const clear = () => {
		return new Promise(async (resolve, reject) => {
			let promises = []
			promises.push(  dispatch(clearCustomerServicesAddresses()) );
			promises.push(   dispatch(clearServicesList()) );
			promises.push(  dispatch(clearCustomerReports()) );
			promises.push(  dispatch(clearID()) ) ;
			promises.push(   dispatch(clearAddressID()) ) ;
			promises.push(  dispatch(clearAddressObj()) ) ;
			promises.push(  dispatch(clearStopsList()) ) ;
			promises.push(  dispatch(clearAssemblyList()) );
			promises.push(   clearMe()  );
			promises.push( dispatch( clearStop() ) );
			promises.push( dispatch( clearAllInvoice() ) )

			Promise.all(promises).then(
				(data, err) => {
					console.log("Cleated redux slices")
					resolve()
				})
		})

	}
	const check = () => {
		return new Promise((resolve, reject) => {
			dispatch(checkSession()).then((data, err) => {
				resolve()
			})
		})
	}

	useEffect(()=>{
		console.log("Customer ID: ", customerID)
		if (customerID != null) {
			check().then((data, err) => {
				if (customerID != null) {
					setShow(true)
				} else {
					setShow(false)
				}
			})
		}
	
		
	}, [customerID])
	
	return(
		<>
			{
				show ?
					<Modal show={true} fullscreen>
						<Modal.Header closeButton onHide={
							() => {
								dispatch(clearID()).then((data, err) => {
									clear()
									setShow(false)
								})
							}
						}>
							<BasicInformation />
						</Modal.Header>
						<Modal.Body className="noPaddingModal">
							<AccountDetails />
						</Modal.Body>
					</Modal>
				:
					<>
						</>

			}
			
		</>

	)
}