import {
  GET_ASSEMBLY_LIST, 
  CLEAR_ASSEMBLY_LIST
} from "../../actions/customerApp";

const _nullList = []
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_ASSEMBLY_LIST:
      return list;
    case CLEAR_ASSEMBLY_LIST: 
      return _nullList; 
    case "": 
      return _nullList; 
    default:
      return state;
  }
};