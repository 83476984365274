import {
  SET_CONTACT
} from "../../actions/newstop";

const _nullContact = { 
  name: null, 
  phone: null, 
  email: null
}
                
export default (state = _nullContact, { type, obj}) => {
  switch (type) {
    case SET_CONTACT:
      return obj;
    case "": 
      return _nullContact; 
    default:
      return state;
  }
};