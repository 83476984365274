import {
    SET_UNIVERSAL_BILLING_ID, CLEAR_UNIVERSAL_BILLING_ID
} from "../../actions/universal_invoice";

const _null = null


export default (state = _null, { type, id }) => {
    Object.freeze(state);
    switch (type) {
        case SET_UNIVERSAL_BILLING_ID:
            return id;
        case CLEAR_UNIVERSAL_BILLING_ID:
            return _null
        case "":
            return _null;
        default:
            return state;
    }
};