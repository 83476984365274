import {
   SET_TOKEN, CLEAR_TOKEN
} from "../../actions/card_payment";

const _null = null
                
export default (state = _null, { type, token }) => {
  Object.freeze(state);
  switch (type) {
    case SET_TOKEN:
      return token;
    case "": 
      return _null; 
    default:
      return state;
  }
};