import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import OrderItem from "./OrderItem"
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";

export default function WorkOrderList(){
	const list = useSelector((state) => state.wo_list)
	const renderlist = list.map( (wo)=> {
		return(
			<OrderItem wo ={wo}/>
		)
	})

	return(
		<Container>
			<Row>
				<Col>
					<Accordion>
						{renderlist}
					</Accordion>
				</Col>
			</Row>
		</Container>
	)	
}