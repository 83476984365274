import { format } from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestReportResults
} from "../../../.././../../actions/universal_test_report"
import { useSelector, useDispatch } from "react-redux";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

export default function XVBA(){
	const report = useSelector((state) => state.universal_report)
	return(
		<Table striped bordered>
						<thead>
							<tr>
								<th colSpan = {4}> (X) VBA</th>
							</tr>
							
						</thead>
						<tbody>
							<tr>
								<td>Initial</td>
									<Table bordered>
										<thead>
											<tr>
												
												<td> Air Inlet Opened at</td>
												<td> Did Not Open</td>
												<td> Opened Fully</td>
												<td> Check Valve Pressdrop</td>
											</tr>	
										</thead>
										<tbody>
											<tr>
												<td>{report.results.VB_air_opened_at}</td>
												<td>
													<Form.Check 
														checked = {report.results.VB_air_didNotOpen}
													/></td>
												<td>
													<Form.Check 
														checked = {report.results.VB_air_opened_fully}
													/>

												</td>
												<td>{report.results.VB_check_pressdrop}</td>
											</tr>
						
									</tbody>
								</Table>

							</tr>
							<tr>
								<td>Final</td>
									<Table bordered>
										<thead>
											<tr>
												<td> Air Inlet Opened at</td>
												<td> Did Not Open</td>
												<td> Opened Fully</td>
												<td> Check Valve Pressdrop</td>
											</tr>	
										</thead>
										<tbody>
											<tr>
												<td>{report.results.repair_vb_air_opened_at}</td>
												<td> 
													<Form.Check 
														checked = {report.results.repair_vb_did_not_open }
													/>
												</td>
												<td>
													<Form.Check 
														checked = {report.results.repair_vb_opened_fully}
													/>
												s</td>
												<td> {report.results.repair_vb_check_pressdrop}</td>
											</tr>
										
									</tbody>
								</Table>

							</tr>
							
						</tbody>
					</Table>
	)
}