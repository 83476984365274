import Row from 'react-bootstrap/Row';	
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

export default function NewInvoice(){
	const addressList = []
	
	return(
		<Container>
			{/*<Row>
				<Col>
					<Form.Select>

					</Form.Select>
				</Col>
			</Row>*/}
		</Container>
	)

}