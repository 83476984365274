import {
  SET_CUSTOMER
} from "../../actions/failed";

const _nullCustomer = null
                
export default (state = _nullCustomer, { type, obj}) => {
  switch (type) {
    case SET_CUSTOMER:
      return obj;
    case "": 
      return _nullCustomer; 
    default:
      return state;
  }
};