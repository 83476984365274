export const requestInvoiceDetails= (invoiceID) => (
	fetch("backflow/uniinvoice?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateInvoiceDetails = (invoiceID, obj) => (
	fetch("backflow/uniinvoice/invoice" , {
		method: "PUT",
		body: JSON.stringify({invoiceID: invoiceID, obj:obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestBillingDetails = (billingID) => (
	fetch("backflow/uniinvoice/billing?" + new URLSearchParams({
		billingID: billingID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)


export const updateBillingDetail = (billingID, obj) => (
	fetch("backflow/uniinvoice/billing" , {
		method: "PUT",
		body: JSON.stringify({billingID: billingID, obj:obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestLineItems = (invoiceID) => (
	fetch("backflow/uniinvoice/lineitems?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const requestLineItemDetails = (itemID) => (
	fetch("backflow/uniinvoice/lineitem?" + new URLSearchParams({
		itemID: itemID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const removeLineItem = (itemID) => (
	fetch("backflow/uniinvoice/lineitem/remove" , {
		method: "PUT",
		body: JSON.stringify({itemID: itemID}),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const addLineItem = (invoiceID) => (
	fetch("backflow/uniinvoice/lineitem" , {
		method: "POST",
		body: JSON.stringify({invoiceID: invoiceID}),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateLineItem = (itemID, obj) => (
	fetch("backflow/uniinvoice/lineitem" , {
		method: "PUT",
		body: JSON.stringify({itemID: itemID, obj:obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
)
