import {
  SET_DATE, 
  CLEAR_DATE
} from "../../actions/scheduling";

const _nullDate =  new Date(); 
                
export default (state = _nullDate, { type, date }) => {
  Object.freeze(state);
  switch (type) {
    case SET_DATE:
      return date;
    case CLEAR_DATE: 
      return _nullDate
    case "": 
      return _nullDate; 
    default:
      return state;
  }
};