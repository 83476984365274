import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'; 
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


export default function OverHeader(){
	return(
		<Container bsPrefix="headerContainer">
			<Row bsPrefix ="flexBetween" xs={1} sm={3} md={3} lg={3}>
				<div>
				<Col >
					<h1 className="companyName"> American Backflow &<br/> Plumbing Services , Inc. </h1>
				</Col>
				</div>
				<div>
				
				</div>
			</Row>
			<Row bsPrefix="decorHeader">
			</Row>
		</Container>
	); 
}