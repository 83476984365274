export const requestTesterList = () =>(
	fetch("backflow/employee/testerList", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestGaugeList = () =>(
	fetch("backflow/employee/gaugeList", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestTesterDetails = (testerID) =>(
	fetch("backflow/employee/testerDetails?" + new URLSearchParams({
		testerID: testerID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestEmployeeSearch = (query) =>(
	fetch("backflow/employee/search?" + new URLSearchParams({
		query : query
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateTester = (testerID, obj) =>(
	fetch("backflow/employee", {
		method: "PUT", 
		body: JSON.stringify({ testerID: testerID, obj: obj}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateGauge = (gaugeID, obj) =>(
fetch("backflow/employee/gauge", {
		method: "PUT", 
		body: JSON.stringify({ gaugeID: gaugeID, obj: obj}), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
	)
