import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import {
	receiveRoundsShow, addRound,  nullifyCurrentRound
	} from "../../../actions/rounds.js"
import {format } from "date-fns";


export default function NewRound(props){
	const show = useSelector((state) => state.rounds_show)
	const dispatch = useDispatch();
	const [nextFollowUp, setNextFollowUp] = useState( new Date() )
	const [voicemail, setVoicemail] = useState(false)	
	const [unreachable, setUnreachable] = useState(false)
	const [reason, setReason] = useState(null) 
	const currentDate = useSelector((state) => state.rounds_date )
	const currentRound = useSelector( (state) => state.rounds_current )  

	const create = (event) =>{
		event.preventDefault(); 
		if(currentRound !=  null){
			let obj = {}
			obj.newDate = nextFollowUp; 
			obj.voicemail = voicemail;
			obj.unreachable = unreachable;
			obj.reason = reason;
			obj.follows = currentRound.total_follows + 1
			obj.roundID = currentRound.id
			obj.status = 'IN'; 
			console.log("object: ", obj)
			addRound(obj).then((data, err) =>{
				dispatch( receiveRoundsShow(false) ).then((data,err) =>{

				})
			})
		}
		
	}	


	useEffect(()=>{
		let cc   = new Date(currentDate); 
		setNextFollowUp (  format( new Date( cc.setDate( cc.getDate() + 10) ), "yyyy-MM-dd") ); 
	}, [currentDate])


	return(
		<>
			<Modal show = {show} centered backdropClassName="modal-backdrop rounds-backdrop" backdrop = 'static'>
			<Form onSubmit = {create}>
				<Modal.Header closeButton onHide = {

					() =>{
						dispatch(nullifyCurrentRound()).then((data, err) =>{
							dispatch( receiveRoundsShow(false) ).then(
								(data, err)=>{
								console.log("Closing the rounds")
							})

						})
						
					}

				}>
					New follow up
				</Modal.Header>
				<Modal.Body>
					
					<Container>
						<Row sm md lg = {1}>
							<Col>
								<Form.Label> When should we reach out again? </Form.Label>
								<Form.Control 
									type="date" 
									value = {nextFollowUp} 
									size = "sm"
									onChange = {(event) => setNextFollowUp(event.target.value)}
									/>
							</Col>

							<Col>
							
								<Form.Check 
									type = "checkbox" 
									label = "Left voicemail"
									checked = {voicemail}
									onChange = { (event)=>{	
										setVoicemail(event.target.checked)
									}}
								/>
							</Col>
							<Col>

								<Form.Check 
									type = "checkbox" 
									label = "Unable to reach"
									checked = {unreachable}
									onChange = { (event)=>{	
										setUnreachable(event.target.checked)
									}}
								/>
							</Col>
							<Col>
								<FloatingLabel  size = "sm" label = "Details" size="sm">
									<Form.Control  
										size = "sm"
										value = {reason}
										onChange = { (event)=>{	
											setReason(event.target.value)
										}}
										required
									/>
								</FloatingLabel>
							</Col>
						</Row>
					</Container>

				</Modal.Body>
				<Modal.Footer>
					<Button type ="submit"> Create </Button>
				</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}