import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InvoiceApp from "./invoice/InvoiceApp" 
import ServicesApp from "./services/ServicesApp"
import DetailsApp from "./details/DetailsApp"

import {
	cancelStop, 
	requestServices, 
	updateStopDetails, 
	requestStopDetails
} from "../../.././../../actions/universal_stop"
import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { format } from "date-fns"
import { determineID, requestStops } from "../../.././../../actions/addressApp"

import { clearStop } from "../../.././../../actions/universal_stop.js"
import { clearAllInvoice } from "../../.././../../actions/universal_invoice.js"



export default function StopsApp() {
	const [key, setKey] = useState('details');
	const [appToShow, setAppToShow] = useState(<>	<DetailsApp /><hr /><ServicesApp /></>)
	const dispatch = useDispatch()
	const stopID = useSelector((state) => state.universal_stopID)
	const scheduler = useSelector((state) => state.universal_scheduler)
	const scheduled = useSelector((state) => state.universal_scheduled)
	const addressID = useSelector((state) => state.addressID)
	useEffect(() => {
		switch (key) {
			case 'details':
				setAppToShow(<>	<DetailsApp /><hr /><ServicesApp /></>)
				break; 
			case 'invoice':
				setAppToShow(<InvoiceApp />)
				break;
			default: 
				setAppToShow(<>	<DetailsApp /><hr /><ServicesApp /></>)
		}
			
	}, [key])

	const cancelMe = () => {
		cancelStop(stopID).then((data, err) => {
			dispatch(requestStops(addressID)).then((data, err) => {
				dispatch(clearStop()).then((data, err) => {
					dispatch(clearAllInvoice() ).then((data, err))
				})
			})
		})
	}

	return (
		<Container className="stop-container" >
			{
				stopID ?
					<>
						<Row>
							<Col>
								<Tabs
									variant="tabs"
									activeKey={key}
									className="flex-row"
									justify
									onSelect={(k) => setKey(k)}

								>
									<Tab eventKey="details" title="Details" mountOnEnter unmountOnExit >

									</Tab>
									<Tab eventKey="invoice" title="Invoice" mountOnEnter unmountOnExit>

									</Tab>
								</Tabs>
							</Col>
						</Row>
						<Row className="white-second">
							<Col>
								{appToShow}
							</Col>
						</Row>
						<br />
						<Row>

							<Col>
								<p>
									<strong> Scheduled by: </strong> {scheduler} <br />
									{
										scheduled ?
											<>
												<strong>Scheduled on: </strong> {format(new Date(scheduled), "MM/dd/yyyy")}
											</>
											: 
											<>
											</>

									}
									
								</p>
							</Col>
							<Col>
								<Button
									variant="danger"
									onClick={cancelMe}
								> Cancel Stop </Button>
							</Col>

						</Row>

					</>
				: 
					<>
						Select a stop
					</>

			}
			
			
		
		</Container>
		
	)
}