import InvoiceDetails from "./InvoiceDetails"
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProcessingForm from "./CardForm"
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getBaseURL } from "../../../actions/card_payment"

export default function CardPayment(){
	const dispatch = useDispatch() 
	useEffect( ()=>{
		dispatch( getBaseURL() ).then((data, err) =>{
			console.log("Done")
		})
	})
	return(
		<Container>
			<Row>
				<Col>
					<h3>Process Payment </h3>
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col>
					<InvoiceDetails />
				</Col>
			</Row>
			<hr/>
			<Row>
				<Col>
					<ProcessingForm />
				</Col>
			</Row>
		</Container>
	)
}