import {
  GET_STOP_LIST, 
  CLEAR_STOP_LIST
} from "../../actions/stops";

const _nullStopList = { 
    stops: []
}
                
export default (state = _nullStopList, { type, stoplist }) => {
  Object.freeze(state);
  switch (type) {
    case GET_STOP_LIST:
      return stoplist;
    case CLEAR_STOP_LIST: 
      return _nullStopList; 
    default:
      return state;
  }
};