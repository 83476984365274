
export const updateTrack = (addressID, newTrack) => (
	fetch("backflow/address/track", {
		method: "PUT",
		body: JSON.stringify({ addressID: addressID, newTrack: newTrack} ),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateTesterList = (stopID, testerList) => (
	fetch("backflow/stops/testers", {
		method: "PUT",
		body: JSON.stringify({ stopID: stopID, testerList: testerList}),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestStopTesters = (stopID) => (
	fetch("backflow/stops/testers?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const createStop = (obj) => (
	fetch("backflow/address/stop", {
		method: "POST",
		body: JSON.stringify({ obj: obj}), 
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateAddress = (addressID, obj) => (
	fetch("backflow/address", {
		method: "PUT",
		body: JSON.stringify({  addressID: addressID, obj: obj} ), 
		headers: {
			"Content-Type": "application/json"
		}
	})

)


export const updateAssembly = (assemblyID, obj) => (
	fetch("backflow/assembly", {
		method: "PUT",
		body: JSON.stringify({  assemblyID: assemblyID, obj: obj} ), 
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestAssemblyDetails = (assemblyID) => (
	fetch("backflow/assembly?" + new URLSearchParams({
		assemblyID: assemblyID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)


export const requestStopList = (addressID) => (
	fetch("backflow/stops/all?" + new URLSearchParams({
		addressID : addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const requestReportList = (stopID) =>(
	fetch("backflow/stops/reports?" + new URLSearchParams({
		stopID : stopID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestAssemblyList  = (addressID, includeRemoved) => (
	fetch("backflow/stops/assembliesForAddress?" + new URLSearchParams({
		addressID : addressID,  includeRemoved: includeRemoved
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
