import Container from 'react-bootstrap/Container'
import CardGroup from 'react-bootstrap/CardGroup'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'


export default function PrivacyPolicy(){
	return(
		<>
	
			<Container bsPrefix="diss">
				<Row>
					<h6>Last Updated: <strong>October 26, 2023</strong></h6>

<h3>1. Introduction</h3>

Welcome to the American Backflow App. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our mobile application and services. By using our app, you agree to the terms of this Privacy Policy.

<h3>2. Information We Collect</h3>

We may collect the following types of information:

a. Personal Information: We may collect personal information such as your name, email address, phone number, and other identifying information you provide when you use the app.

b. Payment Information: To process payments, we may collect and store payment information, such as credit card numbers or other financial data, through a secure payment gateway.

c. Usage Information: We collect information about how you interact with the app, including log data, device information, and the services you access.

<h3>3. How We Use Your Information</h3>

We may use your information for the following purposes:

a. Service Provision: To provide you with the services, process payments, and display invoice information as requested through the app.

b. Customer Support: To respond to your inquiries, provide customer support, and resolve issues you may encounter.

c. Improvements: To improve our app, services, and user experience, we may analyze user behavior and feedback.

<h3>4. Sharing Your Information</h3>

We may share your information in the following ways:

a. Third-Party Service Providers: We may use third-party service providers to help us deliver the app and services. These providers have access to your information only to perform specific tasks on our behalf and are obligated not to disclose or use it for other purposes.

b. Legal Compliance: We may disclose your information in response to a legal request, such as a court order or subpoena, if we believe it is necessary to comply with applicable laws.

<h3>5. Security</h3>

We take reasonable measures to protect your information from unauthorized access, disclosure, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.

<h3>6. Children's Privacy</h3>

Our app is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe that we have unintentionally collected such information, please contact us, and we will promptly delete it.

<h3>7. Changes to this Privacy Policy</h3>

We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or by prominently posting a notice in the app. Your continued use of the app after such changes will constitute your consent to the updated Privacy Policy.

<h3>8. Contact Us</h3>

If you have any questions or concerns about this Privacy Policy or your personal information, please contact us at:

[Contact Information]

By using the American Backflow App, you acknowledge that you have read, understood, and agreed to the terms and conditions of this Privacy Policy. If you do not agree to these terms, please do not use the app.

[End of Privacy Policy]
				</Row>
			</Container>
		</>
	)
}