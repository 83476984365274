import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import CreateOrder from "./CreateOrder"
import WorkOrderList from "./WorkOrderList"
import { requestList, createOrder, requestDetails} from "../../../../actions/workorder.js"


export default function WorkOrders(){
	const dispatch = useDispatch() 
	const accountID = useSelector((state) => state.customerID)
	useEffect(()=>{
		dispatch( requestList(accountID) ).then((date, err) =>{

		})

	}, [])

	return(
		<Container>
			<Row>
				<Col>
					<h4>Work Orders </h4>
				</Col>
			</Row>
			<Row>
				<Col>
					<CreateOrder />
				</Col>
			</Row>
			<br/>
			<Row>
				<Col>
					<WorkOrderList />
				</Col>
			</Row>
		</Container>
	)
}