import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/Buttongroup'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ListGroup from 'react-bootstrap/ListGroup';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
	getContactDetails,
	updateContactDetails
} from "../../../actions/districtManagement.js"

export default function ContactModule(props) {
	const [tag, setTag] = useState(null)
	const [name, setName] = useState(null)
	const [email, setEmail] = useState(null)
	const [phone, setPhone] = useState(null)
	const [myContact, setMyContact] = useState(null)
	const [edit, setEdit] = useState(false)
	const dispatch = useDispatch();

	const update = () => {
		let obj = {}
		obj.type = tag;
		obj.name = name;
		obj.email = email;
		obj.phone = phone;
		obj.id = props.contact.id;
		console.log("updating...", obj)
		updateContactDetails(obj).then((data, err) => {
			setMyContact(data)
			setEdit(false)
		})
	}


	useEffect(() => {
		getContactDetails(props.contact.id).then((data, err) => {
			console.log("This is the data: ", data)
			setMyContact(data)
		})
	}, [])


	useEffect(() => {
		if (myContact != null) {
			setTag(myContact.type)
			setName(myContact.name)
			setPhone(myContact.phone)
			setEmail(myContact.email)
		}
	}, [myContact])


	return(
		<>
			<>
				{
					edit ?
						<tr>
							<td>
											
									<Form.Control
										size = "sm"
													value={tag}
													onChange={
														(event) => setTag(event.target.value) 
													}
												/>
											
							</td>
							<td>
											
												<Form.Control
													value={name}
													onChange={
														(event) => setName(event.target.value)
													}
												/>
											
							</td>		
							<td>

								<Form.Control
									value={email}
									onChange={
										(event) => setEmail(event.target.value)
									}
								/>
										
											
										
							</td>
							<td>
										
											
								<Form.Control
									value={phone}
									onChange={
										(event) => setPhone(event.target.value)
									}
								/>
							
							</td>
							<td>
											<ButtonGroup vertical>
												<Button
													variant="success"
													size="sm"
													onClick={
														update
													}
												> Save</Button>
											</ButtonGroup>
							</td>
							</tr>
						:
							<tr>
					
									<td> {tag} </td> 
									 <td>{name} </td>
									 <td> {email} </td>
									<td> {phone}</td>
									<td>
							
									<Button variant="link" onClick={() => setEdit(true)}>Edit </Button>
									
									</td>
							</tr>
				}
				
			
			</>
		</>
	)
}