export const requestTotalsList = () => (
	fetch("backflow/home/totalslist?", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestReportList = (startDate, endDate) => (
	fetch("backflow/home/testReports?" + new URLSearchParams({
		start: startDate, 
		end: endDate
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestDashboardDetails = () => (
	fetch("backflow/dashboard", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)