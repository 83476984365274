import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Accordion from 'react-bootstrap/Accordion'
import OrderBody from "./OrderBody"
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { requestList, createOrder, requestDetails} from "../../../../actions/workorder.js"
import {format} from "date-fns"


export default function OrderItem(props){
	const [wo, setWO] = useState(null)
	const [loading, setLoading] = useState(true)
	useEffect(()=>{
		setLoading(true)
		requestDetails( props.wo.id).then((data, err)=>{
			console.log("data:", data)
			setWO(data[0])
			setLoading(false)
		})
	}, [])

	return(
		<Accordion.Item eventKey={props.wo}>
			<Accordion.Header>
				<Container>
					{
						loading ?
							<Spinner />
						: 
							<Row>
								<Col>
									{wo.name}
									<br/>
									PO#: {wo.purchase_order}<br/>
									Created: { format( new Date(wo.created_on), "MM/dd/yyyy") } 
								</Col>
								<Col>
									STATUS <br/>
									{wo.status}
								</Col>
							</Row>
					}
				</Container>
			</Accordion.Header>
			<Accordion.Body>
			
			</Accordion.Body>
		</Accordion.Item>
	)
}