import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestTesterNames } from "../../../../actions/dispatch"
import { format} from "date-fns"
import {setAddress, setContact, setDetails, setServices} from "../../../../actions/newstop"

export default function StopForm(){
	const [startTime, setStartTime] = useState(null)
	const [endTime, setEndTime] = useState(null)
	const [tester, setTester] = useState(null)
	const [date, setDate]  = useState(null)
	const [comment, setComment] = useState(null)
	const testerList = useSelector( (state) => state.testers); 
	const dispatch = useDispatch(); 
	const [showTesters, setShowTesters] = useState(false)
	const currentObj = useSelector( (state) => state.newstop_details)

	useEffect(()=>{
		setStartTime( currentObj.startTime)
		setEndTime( currentObj.endTime)
		setDate( format( new Date(currentObj.date ), "yyyy-MM-dd") )
	}, [])

	useEffect( () =>{
		setShowTesters(false)
		dispatch( requestTesterNames() ).then((data, err) =>{
			setShowTesters(true)			
		})
	}, [])

	const update = () =>{
		let obj = {}; 
		obj.date = date; 
		obj.startTime = startTime; 
		obj.endTime = endTime; 
		obj.tester = tester; 
		obj.comment = comment; 
		dispatch( setDetails(obj) ).then((data, err ) =>{})
	}

	const renderTesters = testerList.map( (tt) =>{
		return(
			<option value ={tt.id} > {tt.tester_name}</option>
		)
	})

	return(
		<Container>
			<Row>
				<Col>
					<h5> Stop Details </h5>
				</Col>
			</Row>
			<Row>
				<Col>
					<FloatingLabel
						size= "sm"
						label = "Tester"
					>
						<Form.Select value = {tester} onChange = { (event)=> setTester(event.target.value) } onBlur = {update}>
							{renderTesters}
						</Form.Select>
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel
						size= "sm"
						label = "Date"
					>
						<Form.Control 
							type="date"
							value= {date}
							onChange= {(event) => setDate(event.target.value)}
							onBlur = {update}
						/>
					</FloatingLabel>
				</Col>
			</Row>
			<Row>
				<Col>
					<FloatingLabel
						size= "sm"
						label = "Start Time"
					>
						<Form.Control 
							type="time"
							value = {startTime} 
							onChange= {(event) => setStartTime(event.target.value)}
							onBlur = {update}
						/>
					</FloatingLabel>
				</Col>
				<Col>
					<FloatingLabel
						size= "sm"
						label = "End Time"

					>
						<Form.Control 
							type="time"
							value={endTime}
							onChange= {(event) => setEndTime(event.target.value)}
							onBlur = {update}
						 />
					</FloatingLabel>

				</Col>
			</Row>
			<Row>
				<Col>
					<FloatingLabel
						size= "sm"
						label = "Scheduler Comment"
					>
						<Form.Control 
							type ="textarea" 
							value = {comment}
							onChange = { (event)=> setComment(event.target.value)}
							onBlur = {update}
						/>
					</FloatingLabel>
				</Col>
			</Row>
		</Container>
	)
}