import {
  SET_DEFAULT_SERVICE, 
  CLEAR_DEFAULT_SERVICE
} from "../../actions/scheduling";

const _nullService = "TEST"; 
                
export default (state = _nullService, { type, servicetype }) => {
  Object.freeze(state);
  switch (type) {
    case SET_DEFAULT_SERVICE:
      return servicetype;
    case CLEAR_DEFAULT_SERVICE: 
      return _nullService
    case "": 
      return _nullService; 
    default:
      return state;
  }
};