import * as server from "../util/universal_stop";
import {reduceInvoiceID} from "./universal_invoice"

export const SET_UNIVERSAL_STOP_ID = "SET_UNIVERSAL_STOP_ID"
export const CLEAR_UNIVERSAL_STOP_ID = "CLEAR_UNIVERSAL_STOP_ID"
export const SET_UNIVERSAL_STOP_DATE = "SET_UNIVERSAL_STOP_DATE"
export const CLEAR_UNIVERSAL_STOP_DATE = "CLEAR_UNIVERSAL_STOP_DATE"
export const SET_UNIVERSAL_STOP_START = "SET_UNIVERSAL_STOP_START"
export const CLEAR_UNIVERSAL_STOP_START = "CLEAR_UNIVERSAL_STOP_START"
export const SET_UNIVERSAL_STOP_END = "SET_UNIVERSAL_STOP_END"
export const CLEAR_UNIVERSAL_STOP_END = "CLEAR_UNIVERSAL_STOP_END"
export const SET_UNIVERSAL_STOP_NOTE = "SET_UNIVERSAL_STOP_NOTE"
export const CLEAR_UNIVERSAL_STOP_NOTE = "CLEAR_UNIVERSAL_STOP_NOTE"
export const SET_UNIVERSAL_STOP_PHONE = "SET_UNIVERSAL_STOP_PHONE"
export const CLEAR_UNIVERSAL_STOP_PHONE = "CLEAR_UNIVERSAL_STOP_PHONE"
export const SET_UNIVERSAL_STOP_REQUESTOR = "SET_UNIVERSAL_STOP_REQUESTOR"
export const CLEAR_UNIVERSAL_STOP_REQUESTOR = "CLEAR_UNIVERSAL_STOP_REQUESTOR"

export const SET_UNIVERSAL_STOP_SCHEDULER    = "SET_UNIVERSAL_STOP_SCHEDULER"
export const CLEAR_UNIVERSAL_STOP_SCHEDULER = "CLEAR_UNIVERSAL_STOP_SCHEDULER"

export const SET_UNIVERSAL_STOP_SCHEDULED = "SET_UNIVERSAL_STOP_SCHEDULED"
export const CLEAR_UNIVERSAL_STOP_SCHEDULED = "CLEAR_UNIVERSAL_STOP_SCHEDULED"

export const SET_UNIVERSAL_STOP_STATUS = "SET_UNIVERSAL_STOP_STATUS"
export const CLEAR_UNIVERSAL_STOP_STATUS = "CLEAR_UNIVERSAL_STOP_STATUS"

export const SET_UNIVERSAL_STOP_TESTERS = "SET_UNIVERSAL_STOP_TESTERS"
export const CLEAR_UNIVERSAL_STOP_TESTERS = "CLEAR_UNIVERSAL_STOP_TESTERS"

export const SET_UNIVERSAL_TEMP_TESTERS = "SET_UNIVERSAL_TEMP_TESTERS"
export const CLEAR_UNIVERSAL_TEMP_TESTERS = "CLEAR_UNIVERSAL_TEMP_TESTERS"

export const SET_UNIVERSAL_STOP_SERVICES = "SET_UNIVERSAL_STOP_SERVICES"
export const CLEAR_UNIVERSAL_STOP_SERVICES = "CLEAR_UNIVERSAL_STOP_SERVICES"

const setID = (id) => ({ type: SET_UNIVERSAL_STOP_ID, id })
const setDate = (str) => ({ type: SET_UNIVERSAL_STOP_DATE, str })
const setStart = (str) => ({ type: SET_UNIVERSAL_STOP_START, str })
const setEnd = (str) => ({ type: SET_UNIVERSAL_STOP_END, str })
const setNote = (str) => ({ type: SET_UNIVERSAL_STOP_NOTE, str })
const setPhone = (str) => ({ type: SET_UNIVERSAL_STOP_PHONE, str })
const setRequestor = (str) => ({ type: SET_UNIVERSAL_STOP_REQUESTOR, str })
const setScheduler = (str) => ({ type: SET_UNIVERSAL_STOP_SCHEDULER, str })
const setScheduled = (str) => ({ type: SET_UNIVERSAL_STOP_SCHEDULED, str })
const setStatus = (str) => ({ type: SET_UNIVERSAL_STOP_STATUS, str })
const setTesters = (list) => ({ type: SET_UNIVERSAL_STOP_TESTERS, list })
const setTempTesters = (list) => ({ type: SET_UNIVERSAL_TEMP_TESTERS, list })
const setServices = (list) => ({ type: SET_UNIVERSAL_STOP_SERVICES, list })


export const reduceID = (id) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch( setID(id)))
    })
}
export const reduceDate = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setDate(str)))
    })
}
export const reduceStart = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setStart(str)))
    })
}
export const reduceEnd = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setEnd(str)))
    })
}
export const reduceNote = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setNote(str)))
    })
}
export const reducePhone = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setPhone(str)))
    })
}

export const reduceStatus = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setStatus(str)))
    })
}
export const reduceRequestor = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setRequestor(str)))
    })
}
export const reduceScheduler = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setScheduler(str)))
    })
}

export const reduceScheduled = (str) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setScheduled(str)))
    })
}
export const reduceTesters = (list) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setTesters(list)))
    })
}
export const reduceTempTesters = (list) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setTempTesters(list)))
    })
}

export const reduceServices = (list) => dispatch => {
    return new Promise(async (resolve, reject) => {
        resolve(dispatch(setServices(list)))
    })
}

const clearID = () => ({ type: CLEAR_UNIVERSAL_STOP_ID })
const clearDate = () => ({ type: CLEAR_UNIVERSAL_STOP_DATE })
const clearStart = () => ({ type: CLEAR_UNIVERSAL_STOP_START })
const clearEnd = () => ({ type: CLEAR_UNIVERSAL_STOP_END })
const clearNote = () => ({ type: CLEAR_UNIVERSAL_STOP_NOTE })
const clearPhone = () => ({ type: CLEAR_UNIVERSAL_STOP_PHONE })
const clearRequestor = () => ({ type: CLEAR_UNIVERSAL_STOP_REQUESTOR })
const clearScheduled = () => ({ type: CLEAR_UNIVERSAL_STOP_SCHEDULED })
const clearScheduler = () => ({ type: CLEAR_UNIVERSAL_STOP_SCHEDULER })
const clearStatus = () => ({ type: CLEAR_UNIVERSAL_STOP_STATUS })
const clearTesters = () => ({ type: CLEAR_UNIVERSAL_STOP_TESTERS })
const clearServices = () => ({ type: CLEAR_UNIVERSAL_STOP_SERVICES })

export const clearStop = () => dispatch => {
    return new Promise(async (resolve, reject) => {
        let promises = []
        promises.push(  dispatch(clearID()))
        promises.push( dispatch(clearDate()))
        promises.push(dispatch(clearStart()))
        promises.push( dispatch(clearEnd()))
        promises.push( dispatch(clearNote()))
        promises.push( dispatch(clearPhone()))
        promises.push( dispatch(clearRequestor()))
        promises.push( dispatch(clearStatus()))
        promises.push( dispatch(clearTesters()))
        promises.push(dispatch(clearServices()))
        promises.push(dispatch(clearScheduler()))
        promises.push(dispatch(clearScheduled()))
        Promise.all(promises).then((data, err) => {
                resolve()
        })
    })
}


export const requestStopDetails = (stopID) => dispatch => {
    return new Promise(async (resolve, reject) => {
        const response = await server.requestStopDetails(stopID)
        const data = await response.json()
        if (response.ok) {
            
            let dd = JSON.parse(data).obj
            dispatch(reduceDate(dd.date))
            dispatch(reduceStart(dd.startTime))
            dispatch(reduceEnd(dd.endTime))
            dispatch(reduceRequestor(dd.requestor))
            dispatch(reduceNote( dd.comment))
            dispatch(reduceInvoiceID(dd.invoiceID))
            dispatch(reduceScheduler(dd.scheduled_by))
            dispatch(reduceScheduled( dd.dateAdded) )
            resolve({scheduled_by: dd.scheduled_by, scheduled_on: dd.scheduled_date})
        }
    })
}

export const updateStopDetails = (stopID, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateStopDetails(stopID, obj)
        const data = await response.json()
        if (response.ok) {
            resolve()
        }
    })
} 
 
export const cancelStop = (stopID) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.cancelStop(stopID)
        const data = await response.json()
        if (response.ok) {
            resolve()
        }
    })
}
export const requestServices = (stopID) => dispatch => {
    return new Promise(async (resolve, reject) => {
        const response = await server.requestServices(stopID)
        const data = await response.json()
        if (response.ok) {
            dispatch(reduceServices(JSON.parse(data).list));  
            resolve()
        }
    })
}