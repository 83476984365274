/*import {
  RECEIVE_DISPATCH, 
  CLEAR_DISPATCH
  
} from "../../actions/dispatch";

const _nullDispatch= { 
  assigned : [], 
  notAssigned: []  
}
          
export default (state = _nullDispatch, { type, dispatch }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_DISPATCH:
      return dispatch;
    case CLEAR_DISPATCH:
      return _nullDispatch; 
    default:
      return state;
  }
};*/

import {
  RECEIVE_DISPATCH, 
  CLEAR_DISPATCH
  
} from "../../actions/dispatch";

const _nullDispatch= { 
  areas : [
    
        {
            "anchor": { 
                "obj": {
                    "street": "3736 NE 15th Ave",
                    "city": "Portland",
                    "state": "OR",
                    "zipcode": "97212"
                },
                "string": "3736 NE 15th Ave, Portland, OR, 97212",
                "id": 10,
                "alias": "NE Portland"
            },
            "stopList": []
        },

        {
            "anchor": {
                    "obj": {
                        "street": "2989 SW Montgomery DR",
                        "city": "Portland",
                        "state": "OR",
                        "zipcode": "97201"
                    },
                    "string": "2989 SW Montgomery DR, Portland, OR, 97201",
                    "id": 11,
                    "alias": "SW Portland"
                },
                "stopList": [
                    {
                        "street": "14904 NW Minda ST",
                        "city": "Portland",
                        "state": "OR",
                        "zipcode": "97229",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6591
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    },
                    {
                        "street": "7899 NW 162nd Terrace",
                        "city": "Portland",
                        "state": "OR",
                        "zipcode": "97229",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6592
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    },
                    {
                        "street": "15883 NW Gooderham ST",
                        "city": "Portland",
                        "state": "OR",
                        "zipcode": "97229",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6593
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    },
                    {
                        "street": "15858 NW Ridgeline ST",
                        "city": "Portland",
                        "state": "OR",
                        "zipcode": "97229",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6594
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "31 Shore Dr",
                        "city": "St Helens",
                        "state": "OR",
                        "zipcode": "97051"
                    },
                    "string": "31 Shore Dr, St Helens, OR, 97051",
                    "id": 12,
                    "alias": "St Helens"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "1081 33rd Place",
                        "city": "Forest Grove",
                        "state": "OR",
                        "zipcode": "97113"
                    },
                    "string": "1081 33rd Place, Forest Grove, OR, 97113",
                    "id": 13,
                    "alias": "Forest Grove"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "12540 SW 69TH AVE",
                        "city": "Tigard",
                        "state": "OR",
                        "zipcode": "97223"
                    },
                    "string": "12540 SW 69TH AVE, Tigard, OR, 97223",
                    "id": 14,
                    "alias": "Tigard"
                },
                "stopList": [
                    {
                        "street": "17085 SW Kalapuya Ln",
                        "city": "Sherwood",
                        "state": "OR",
                        "zipcode": "97140",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6595
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "128 S Mcloughlin Blvd",
                        "city": "Oregon City",
                        "state": "OR",
                        "zipcode": "97045"
                    },
                    "string": "128 S Mcloughlin Blvd, Oregon City, OR, 97045",
                    "id": 15,
                    "alias": "Oregon City"
                },
                "stopList": [
                    {
                        "street": "1647 Killarney DR",
                        "city": "West Linn",
                        "state": "OR",
                        "zipcode": "97068",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6604
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "1342 SE 208th Ave",
                        "city": "Gresham",
                        "state": "OR",
                        "zipcode": "97030"
                    },
                    "string": "1342 SE 208th Ave, Gresham, OR, 97030",
                    "id": 16,
                    "alias": "Gresham"
                },
                "stopList": [
                    {
                        "street": "867 NE Wildwood Dr",
                        "city": "Estacada",
                        "state": "OR",
                        "zipcode": "97023",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6605
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "8930 SE Sunnyside Rd",
                        "city": "Clackamas",
                        "state": "OR",
                        "zipcode": "97015"
                    },
                    "string": "8930 SE Sunnyside Rd, Clackamas, OR, 97015",
                    "id": 17,
                    "alias": "Clackamas"
                },
                "stopList": [
                    {
                        "street": "10101 SE Main ST",
                        "city": "Portland",
                        "state": "OR",
                        "zipcode": "97216",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6601
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    },
                    {
                        "street": "14682 SE Sunnyside Road",
                        "city": "Happy Valley",
                        "state": "OR",
                        "zipcode": "97015",
                        "details": {
                            "duration": 120,
                            "tests": [
                                {
                                    "id": 6602
                                },
                                {
                                    "id": 6603
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "2055 N Main Ave",
                        "city": "Tillamook",
                        "state": "OR",
                        "zipcode": "97141"
                    },
                    "string": "2055 N Main Ave, Tillamook, OR, 97141",
                    "id": 18,
                    "alias": "Tillamook"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "2917 NW Essex Ave",
                        "city": "Albany",
                        "state": "OR",
                        "zipcode": "97321"
                    },
                    "string": "2917 NW Essex Ave, Albany, OR, 97321",
                    "id": 19,
                    "alias": "Albany"
                },
                "stopList": []
            }, 
              {
                "anchor": {
                    "obj": {
                        "street": "1221 N State ST",
                        "city": "Bellingham",
                        "state": "WA",
                        "zipcode": "98225"
                    },
                    "string": "1221 N State ST, Bellingham, WA, 98225",
                    "id": 0,
                    "alias": "Bellingham"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "5427 Glenwood Ave",
                        "city": "Everett",
                        "state": "WA",
                        "zipcode": "98203"
                    },
                    "string": "5427 Glenwood Ave, Everett, WA, 98203",
                    "id": 1,
                    "alias": "Everett"
                },
                "stopList": [
                    {
                        "street": "424 124th PL SE",
                        "city": "Everett",
                        "state": "WA",
                        "zipcode": "98208",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6590
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "1806 Franklin Ave E",
                        "city": "Seattle",
                        "state": "WA",
                        "zipcode": "98102"
                    },
                    "string": "1806 Franklin Ave E, Seattle, WA, 98102",
                    "id": 2,
                    "alias": "Seattle"
                },
                "stopList": [
                    {
                        "street": "14616 106th Ave NE",
                        "city": "Bothell",
                        "state": "WA",
                        "zipcode": "98011",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6589
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "728 22nd ST SW",
                        "city": "Tacoma",
                        "state": "WA",
                        "zipcode": "98371"
                    },
                    "string": "728 22nd ST SW, Tacoma, WA, 98371",
                    "id": 3,
                    "alias": "Tacoma"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "209 5th Ave SE",
                        "city": "Olympia",
                        "state": "WA",
                        "zipcode": "98501"
                    },
                    "string": "209 5th Ave SE, Olympia, WA, 98501",
                    "id": 4,
                    "alias": "Olympia"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "1111 Johnson Road",
                        "city": "Centralia",
                        "state": "WA",
                        "zipcode": "98531"
                    },
                    "string": "1111 Johnson Road, Centralia, WA, 98531",
                    "id": 5,
                    "alias": "Centralia"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "1210 Ocean Beach Hwy",
                        "city": "Lonview",
                        "state": "WA",
                        "zipcode": "98632"
                    },
                    "string": "1210 Ocean Beach Hwy, Lonview, WA, 98632",
                    "id": 6,
                    "alias": "Longview"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "327 Hillshire Dr",
                        "city": "Woodland",
                        "state": "WA",
                        "zipcode": "98607"
                    },
                    "string": "327 Hillshire Dr, Woodland, WA, 98607",
                    "id": 7,
                    "alias": "Woodland"
                },
                "stopList": []
            },
            {
                "anchor": {
                    "obj": {
                        "street": "100 Columbia St",
                        "city": "Vancouver",
                        "state": "WA",
                        "zipcode": "98660"
                    },
                    "string": "100 Columbia St, Vancouver, WA, 98660",
                    "id": 8,
                    "alias": "Vancouver"
                },
                "stopList": [
                    {
                        "street": "1919 E Evergreen Blvd",
                        "city": "Vancouver",
                        "state": "WA",
                        "zipcode": "98661",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6599
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            },
            {
                "anchor": {
                    "obj": {
                        "street": "2512 NW LOGAN ST",
                        "city": "Camas",
                        "state": "WA",
                        "zipcode": "98604"
                    },
                    "string": "2512 NW LOGAN ST, Camas, WA, 98604",
                    "id": 9,
                    "alias": "Camas"
                },
                "stopList": [
                    {
                        "street": "330 W Fir Loop",
                        "city": "Washougal",
                        "state": "WA",
                        "zipcode": "98671",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6596
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    },
                    {
                        "street": "305 W Fir Loop",
                        "city": "Washougal",
                        "state": "WA",
                        "zipcode": "98671",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6597
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    },
                    {
                        "street": "4903 N Elk DR",
                        "city": "Camas",
                        "state": "WA",
                        "zipcode": "98607",
                        "details": {
                            "duration": 60,
                            "tests": [
                                {
                                    "id": 6598
                                }
                            ],
                            "repairs": [],
                            "replacements": [],
                            "plumbing": []
                        }
                    }
                ]
            }
    ] 
}
          
export default (state = _nullDispatch, { type, dispatch }) => {
  Object.freeze(state);
  switch (type) {
    case RECEIVE_DISPATCH:
      return dispatch;
    case CLEAR_DISPATCH:
      return _nullDispatch; 
    default:
      return state;
  }
};
