import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import { requestBillingDetails, updateBillingDetail } from "../../../.././../../actions/universal_invoice"

function Editable(props){
	const bill = props.bill
	const billingID = useSelector( (state) => state.universal_billing_id) 	
	const [name, setName] = useState(null)
	const [street, setStreet] = useState(null)
	const [city, setCity] = useState(null)
	const [state, setState] = useState(null)
	const [zipcode, setZipcode] = useState(null)
	const [po, setPO] = useState(null)
	const [salesTax, setSalesTax] = useState(null)
	const [email, setEmail] = useState(null)

	useEffect(()=>{
		setName(bill.name)
		setStreet(bill.street)
		setCity(bill.city)
		setState(bill.state)
		setZipcode(bill.zipcode)
		setPO(bill.PO)
		setSalesTax(bill.sales_tax)
		setEmail(bill.email)
	}, [])
	const save = () =>{
		let obj = {}
		obj.name = name; 
		obj.street = street; 
		obj.city = city; 
		obj.state = state; 
		obj.zipcode = zipcode; 
		obj.po = po; 
		obj.sales_tax = salesTax; 
		obj.email = email; 
		updateBillingDetail( billingID, obj).then( (data, err) =>{
			props.display()
		})
	}
	return(
		<>
		<Row>
			<Col>
				<InputGroup>
					<InputGroup.Text> Name </InputGroup.Text>
					<Form.Control
						size= "sm"
						onChange = { (event)=> setName(event.target.value) }
						value = {name}
					 />
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>Street</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setStreet(event.target.value) }
						value = {street}
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>City</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setCity(event.target.value) }
						value = {city}
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>State</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setState(event.target.value) }
						value = {state}
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>Zipcode</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setZipcode(event.target.value) }
						value = {zipcode}
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>PO #</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setPO(event.target.value) }
						value = {po}
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>Sales Tax</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setSalesTax(event.target.value) }
						value = {salesTax}
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text>email</InputGroup.Text>
					<Form.Control 
						size= "sm"
						onChange = { (event)=> setEmail(event.target.value) }
						value = {email}
					/>
				</InputGroup>
			</Col>
		</Row>
		<Row>
			<Col>
				<br/>
			  <Button onClick = {save}> Save </Button>
			</Col>
		</Row>
		</>
	)

}

function Display(props){
	
	const mybill = props.bill

	return(
		<Row>
			<Col>
				{ 
					mybill ?
						<p>
							<strong>{mybill.name}</strong> <br/>
							{mybill.street}, {mybill.city},{mybill.state}, {mybill.zipcode} <br/>
							<strong> Sales Tax: </strong> {mybill.sales_tax}<br/>
							<strong> Email: </strong> {mybill.email} <br/>
							<strong> PO #: </strong> {mybill.PO}
						</p>
					: 

						<>
						</>

				}
				
			</Col>
		</Row>
	)

}


export default function BillingInformation(){
	const [edit, setEdit] = useState(false)
	const [mybill, setMyBill] = useState(null)
	const billingID = useSelector( (state) => state.universal_billing_id)
	useEffect(()=>{
		if(billingID != null){
			requestBillingDetails(billingID).then( (data, err) =>{
				setMyBill(data)
			})
			
		}
	}, [billingID])

	const rr = ()=>{
		requestBillingDetails(billingID).then( (data, err) =>{
				setMyBill(data)
			})

	}

	const closeEdit = () =>{
		requestBillingDetails(billingID).then( (data, err) =>{
				setMyBill(data)
				setEdit(false)
		})

	}
	return(
		<>

		<Container>
			{
				edit ? 
					<Editable bill = {mybill} display = { closeEdit} />
				: 
					<Display  bill = {mybill}  />

			}

			<Row>
				<Col>
					
				</Col>
				<Col>
					{
						edit ?
							<></>
							: 
							<Button
						variant = "link"
						onClick = { () => setEdit(!edit)}
					> Edit </Button>

					}
					
				</Col>

			</Row>
		</Container>
		</>
	)
}