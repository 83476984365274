import {
  SET_ADDRESS_ID_APP
  , CLEAR_ADDRESS_ID_APP
} from "../../actions/addressApp";

const _nullID =  null
                
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ADDRESS_ID_APP:
      return id;
    case CLEAR_ADDRESS_ID_APP: 
      return _nullID
    case "": 
      return _nullID; 
    default:
      return state;
  }
};