import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import {format } from "date-fns"
import { useState, useEffect, useRef, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	cancelStop,
	requestServices,
	updateStopDetails,
	requestStopDetails,
	reduceTesters,
	reduceTempTesters,
	reduceDate, reduceStart, reduceEnd, reduceRequestor, reducePhone,
	reduceNote
} from "../../../.././../../actions/universal_stop"
import {requestStopTesters} from "../../../.././../../actions/addressApp"

function Display() {
	const dispatch = useDispatch()
	const stopID = useSelector((state) => state.universal_stopID)
	const date = useSelector((state) => state.universal_date)
	const start = useSelector((state) => state.universal_starttime)
	const end = useSelector((state) => state.universal_endtime)
	const requestor = useSelector((state) => state.universal_requestor)
	const phone = useSelector((state) => state.universal_phone)
	const note = useSelector((state) => state.universal_note)
	const testers = useSelector((state) => state.universal_testers)
	const scheduler = useSelector((state) => state.universal_scheduler)

	const renderTesters = testers.map((tt) =>{
		return(
			<>
				- {tt.tester_name}  - <br/>
			</>
		)
	})

	const timeZoned = (mydate) =>{
		let timed = mydate.slice(0,10)
		let actualString = `${timed}T00:00:00`
		console.log("actual date: ", actualString)
		return actualString
	}

	return (
		<Container>
			<Row>
				<Col>
				
							<p>
								{
									date ? 
										<> <strong>Date:</strong> { format(new Date(timeZoned(date)), "MM/dd/yyyy") }<br/> </>
									:
										<> </>
								}
								
								<strong>Time: </strong>{start} - {end} <br/>
								<strong>Assigned to: <br/> </strong>{ renderTesters } <br/>
								 
							</p>
						
					
				</Col>
				<Col>
					<p>
						<strong>Requestor:</strong>{requestor}<br />
						<strong>Phone #:</strong>{phone}<br />
						<strong>Note:</strong>{note}<br />
					</p>
				</Col>
			</Row>
			
		</Container>
	)
}
function Editable() {
	const dispatch = useDispatch()
	const date = useSelector((state) => state.universal_date)
	const start = useSelector((state) => state.universal_starttime)
	const end = useSelector((state) => state.universal_endtime)
	const requestor = useSelector((state) => state.universal_requestor)
	const phone = useSelector((state) => state.universal_phone)
	const note = useSelector((state) => state.universal_note)
	const testers = useSelector((state) => state.universal_testers)
	const available_testers = useSelector((state) => state.testers)
	const [setting, setSetting] = useState(false)

	const isChecked = (id) => {
		for (let i = 0; i < testers.length; i++) {
			if (testers[i].id === id) {
				return true
			}
		}
		return false
	}
	const getIndex = (id) => {
		for (let i = 0; i < testers.length; i++) {
			if (testers[i].id === id) {
				console.log(`Returning true for ${testers[i].id}, ${id}`)
				return i
			}
		}
		return -1
	}

	const timeZoned = (mydate) =>{
		let timed = mydate.slice(0,10)
		let actualString = `${timed}T00:00:00`
		console.log("actual date: ", actualString)
		return actualString
	}


	const renderTesters = available_testers.map((tt) => {
		if (tt.tester) {
			return (
				<Col>
					<Form.Check
						label={tt.tester_name}
						checked = { isChecked(tt.id) }
						onClick={
							(event) => {
								if (event.target.checked) {
									if (!isChecked(tt.id)) {
										let newlist = [...testers];
										newlist.push({ id: tt.id });
										dispatch(reduceTesters(newlist)).then((data, err) => {
											console.log("Done updating list")
										})
									}

								} else {
									if (isChecked(tt.id)) {
										//find index of
										let myIndex = getIndex(tt.id);
										if (myIndex > - 1) {
											console.log("This is the index: ", myIndex, testers)
											let temp = [...testers]
											let removed = temp.splice(myIndex, 1)
											let newlist = [...temp]
											setSetting(true)
											dispatch(reduceTesters(newlist)).then((data, err) => {
												console.log("Done updating list")
												setSetting(false)
											})
										}

									}
								}
							}
						}
					/>
				</Col>
			)
		} else {
			return(<></>)
		}
		
	})

	return (
		<Container>
			<Row>
				<Col xs={2} sm md lg={4}>
					<Form.Label>Date</Form.Label>
					{
						date ? 
							<>
								<Form.Control 
									type ="date"
									value = { format( new Date(timeZoned( date)) , "yyyy-MM-dd")}
									onChange = {
										(event) =>{
											dispatch( reduceDate( new Date( event.target.value)) ).then( (data, err) =>{

											})
										}
									}
								/>
							</>
						: 
							<></>

					}
					
					
				</Col>
				<Col>
					<Form.Label>Time</Form.Label>
					<InputGroup>
						<InputGroup.Text>Start</InputGroup.Text>
						<Form.Control
							type="time"
							value={start}
							onChange={
								(event) => {
									dispatch(reduceStart(event.target.value)).then((data, err) => {

									})
								}
							}
						/>
						<InputGroup.Text>End</InputGroup.Text>
						<Form.Control
							type="time"
							value={end}
							onChange={
								(event) => {
									dispatch(reduceEnd(event.target.value)).then((data, err) => {

									})
								}
							}
						/>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					
					<Form.Label>Assigned To:</Form.Label>
					<Row xs={1} sm={2} md lg={4}>
						{
							setting ?
								<Spinner />
								: 
								<>{renderTesters}</>

						}
							
					</Row>
					<hr />
					<Row>
						<Col>
							<FloatingLabel label="Requested by: ">
								<Form.Control
									value={requestor}
									onChange={
										(event) => {
											dispatch(reduceRequestor(event.target.value)).then((data, err) => {

											})
										}
									}
								/>

							</FloatingLabel>
						</Col>
						<Col>
							<FloatingLabel label = "Phone #">
								<Form.Control
									value={phone}
									onChange={
										(event) => {
											dispatch(reducePhone(event.target.value)).then((data, err) => {

											})
										}
									}
								/>
							</FloatingLabel>
						</Col>
					</Row>
					<Row>
						<Col>
							<FloatingLabel label = "Notes">
								<Form.Control
									type="textarea"
									value={note}
									onChange={
										(event) => {
											dispatch(reduceNote(event.target.value)).then((data, err) => {

											})
										}
									}
								/>
							</FloatingLabel>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	)
}

export default function DetailsApp(){
	const [edit, setEdit] = useState(false)
	const [loading, setLoading] = useState(false)
	const stopID = useSelector((state) => state.universal_stopID)
	const date = useSelector((state) => state.universal_date)
	const start = useSelector((state) => state.universal_starttime)
	const end = useSelector((state) => state.universal_endtime)
	const requestor = useSelector((state) => state.universal_requestor)
	const phone = useSelector((state) => state.universal_phone)
	const note = useSelector((state) => state.universal_note)
	const unitesters = useSelector( (state) => state.universal_testers)
	
	const dispatch = useDispatch() 

	useEffect(() => {
		if (stopID != null) {
			dispatch(requestStopDetails(stopID)).then((data, err) => {
			})
			dispatch(requestStopTesters(stopID)).then((data, err) => { 
			})
		}
	}, [stopID])

	const cancelMe = () => {
		cancelStop(stopID).then((data, err) => {
			dispatch(requestStopDetails(stopID)).then((data, err) => {
				dispatch(requestStopTesters(stopID)).then((data, err) => {
					setLoading(false)
				})

			})
		})
	}

	const updateStop = () => {
		let obj = {}
		obj.date = date; 
		obj.start = start; 
		obj.end = end; 
		obj.requestor = requestor; 
		obj.phone = phone; 
		obj.note = note; 
		obj.testerList = unitesters; 
		updateStopDetails(stopID, obj).then((data, err) => {
			setLoading(true)
			dispatch(requestStopDetails(stopID)).then((data, err) => {
				dispatch(requestStopTesters(stopID)).then((data, err) => {
					setLoading(false)
				})
				
			})
		})
		setEdit(false)
	}
	
	return (
		<Container >
			<Row>
				<Col sm={8} md lg={9}>
					{
						loading ?
							<Spinner />
							: 
							<>
								{
									edit ?
										<Editable />
										:
										<Display />
								}

							</>

					}

					
				</Col>
			
				<Col>
					<Container>
						<br/>
						<ButtonGroup vertical>
							{
								edit ?
									<Button
										onClick={updateStop}
									>Save</Button>
									:
									<Button
										variant="link"
										onClick={() => setEdit(!edit)}
									>Edit</Button>

							}
							
						</ButtonGroup>
						

					</Container>
					
				</Col>
			</Row>
			
			
			
		</Container>
	)
}