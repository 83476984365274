import Alert from 'react-bootstrap/Alert';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { getTotalsList  } from "../../../actions/home.js"
import { getTestersStops , getStops} from "../../../actions/scheduling.js"
import {
	requestAddress, requestContact, requestBilling, requestNoteList, requestList,
	addRound, 	receiveRoundsShow, setCurrentRound, removeFromRounds
	} from "../../../actions/rounds.js"

import {format } from "date-fns";

import { newCustomerID} 
from "../../../actions/customerApp.js" 
import { determineID } 
from "../../../actions/addressApp"

import NewRound from "./NewRound"

export default function RoundCard(props){
	const currentDate = useSelector((state) => state.rounds_date )
	const [nextFollowUp, setNextFollowUp] = useState( new Date() )
	const [voicemail, setVoicemail] = useState(false)	
	const [unreachable, setUnreachable] = useState(false)
	const [reason, setReason] = useState(null)
	const [address, setAddress] = useState(null)
	const [contact, setContact] = useState(null)
	const [billing, setBilling] = useState(null)
	const dispatch = useDispatch(); 
	const [addressLoader, setAddressLoader] = useState(true)	
	const [billingLoader, setBillingLoader] = useState(true)
	const [contactLoader, setContactLoader] = useState(true)
	const [noteLoader, setNoteLoader] = useState(true)
	const [notes, setNotes] = useState([])
	const currentMonth = useSelector((state) => state.rounds_month)
	const currentCount = useSelector((state) => state.rounds_count)

	const remove = () =>{
		removeFromRounds( props.round.id).then((data, err) =>{
			props.reload()
		})
	}

	useEffect(()=>{
		let cc   = new Date(currentDate); 
		setNextFollowUp (  format( new Date( cc.setDate( cc.getDate() + 8) ), "yyyy-MM-dd") ); 
	}, [currentDate])


	useEffect(()=>{
		console.log("This is the round: ", props.round)
		setAddressLoader(true) 
		setContactLoader(true)
		setBillingLoader(true)
		setNoteLoader(true)
		requestAddress(props.round.addressID).then((data, err) =>{
			setAddress(data); 
			setAddressLoader(false)
		})

		requestContact(props.round.addressID).then((data, err) =>{
	
			setContact(data)
			setContactLoader(false)
		
		})

		requestBilling(props.round.addressID).then((data, err) =>{
			setBilling(data)
			setBillingLoader(false)
		})	

		requestNoteList(props.round.id).then((data, err) =>{
			setNotes(data); 
			setNoteLoader(false)
		})

	}, [])

	const update = (event) =>{
		event.preventDefault(); 
		let obj = {}
		obj.newDate = nextFollowUp; 
		obj.voicemail = voicemail;
		obj.unreachable = unreachable;
		obj.reason = reason;
		obj.follows = props.round.total_follows + 1
		obj.roundID = props.round.id
		obj.status = 'IN'; 
		addRound(obj).then((data, err) =>{
			console.log("Round added, IN")
		})

	}
	const noFollowUp = (event) =>{
		event.preventDefault(); 
		let obj = {}
		obj.nextDate = nextFollowUp; 
		obj.voicemail = voicemail;
		obj.unreachable = unreachable;
		obj.reason = reason;
		obj.follows = props.round.total_follows + 1
		obj.roundID = props.round.id
		obj.status = 'OUT'; 
		addRound(obj).then((data, err) =>{
			console.log("Round added, OUT")
		})
	}



	const renderNotes = notes.map( (note, index) =>{
		return(
				<>
				<tr>	
					<td>
						{format( new Date(note.dateAdded), "MM/dd/yyyy")} <br/>
					
					</td>
					<td>
					{note.note}
					</td>
				
					<td>
						{
							note.voicemail ?
								<>✔</>
							: 
								<> </> 
						}
					</td>
					<td>
						{
							note.unreachable ?
								<>✔</>
							: 
								<> 
								</>

						}

					</td>
					<td>
						<small>{note.backflow_name}</small>
					</td>
				</tr>
				</>
			)
	})


	return(
		<>
			<NewRound />

			<Card bg="secondary" text ="light"  
			onDoubleClick={
				()=> {
					dispatch( newCustomerID(props.round.accountID) ).then((data, err)=>{
						dispatch( determineID (props.round.addressID) ).then((data, err) =>{

						})  
					})
				}

			}>
				<Card.Header>
					<Container>	
						<Row>
							<Col>
								<strong> Follow Up # : </strong> {props.round.total_follows}
							</Col>
							<Col>
								{
									contactLoader ?
										<Spinner />
									: 
										<>
											Contact <u>{contact.name}</u> at  <u>{contact.phone}</u>
										</>

								}
									
								
							</Col>
							<Col>
								{props.round.month}
							</Col>
						</Row>
					</Container>

				</Card.Header>
				<Card.Body>
					<Container>
						<Row>	
							<Col sm md lg = {3}>			
								{
								addressLoader ?
									<Spinner />
								: 
									<>
										<>
										<small>
										<strong> {address.location_name} </strong>
										<br/>
											{address.street}, <br/>
											{address.city}, {address.state}, {address.zipcode}
										</small>
										<br/>
										</>
									</>

									}
								{
									contactLoader ?
										<Spinner />
									:
										<>
											<small>{contact.email}
											</small>
											<br/>
										</>
								}
				
									{
										billingLoader ?
											<Spinner />	
										: 
											<>
											<small>
											<strong> Billing:  </strong>
				
												{billing.name}
											</small>
											</>

									}
								
									
								
								
							</Col>
							<Col>
								{
									noteLoader ?
										<Spinner />
									: 
										
										<>
											<div className="scrollTable">
											<Table size= "sm" bordered responsive striped> 
												<thead>
													<tr>
														<th><small>Date</small></th>
														<th><small>Note</small></th>
														<th><small>VM</small></th>
														<th><small>UR</small></th>
														<th><small>By</small></th>
													</tr>
												</thead>
												<>{
													notes.length
													? 

														<tbody >
														
																{renderNotes}
														
														</tbody>
													: 
														<> No notes to show</>
													}
												</>
													
												</Table>
											</div>
											</>
										
										
										
								}
							</Col>
							<Col sm md lg = {2}>
								<Container>
									
										<ButtonGroup  vertical>
											
											<Button 
												size = "xl" 
												variant = "success" 
												onClick = {
													() =>{
														dispatch (setCurrentRound(props.round)).then((data, err) =>{
															dispatch( receiveRoundsShow(true) ).then( (data, err) =>{
																console.log("Set  a new round form")
																console.log("Current ID:", props.round)

															} )
														})
													}
												}
											>
											Schedule Next Round
											</Button>
											<Button 
												size = "sm"
												variant = "warning"
												onClick = {remove}
											> Remove from rounds </Button>
										</ButtonGroup>
								</Container>
							</Col>
						</Row>
					</Container>
				</Card.Body>
			</Card>
		</>
			
	)
}