import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Alert from 'react-bootstrap/Alert';

export default function ProcessingResults(){
	const [message, setMessage] = useState('')
	const card_token = useSelector( (state) => state.card_token)
	useEffect(()=>{
		if(card_token){
			if('errors' in card_token){
				let newMessage = ''
				for (let i = 0 ; i < card_token.errors.length; i++){
					newMessage = `${newMessage} -  ${card_token.errors[i].moreInfo}`
				}
				setMessage(newMessage)
			}else{
				setMessage("Encrypted card")
			}
		}else{
			
		}
		
	}, [card_token])



	return(
		<Alert>
			{message}
		</Alert>
	)
}