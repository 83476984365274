import * as server from "../util/scheduleServer";


export const SET_DATE = "SET_DATE"; 
export const CLEAR_DATE = "CLEAR_DATE"
export const SET_TIME_FRAME = "SET_TIME_FRAME"
export const CLEAR_TIME_FRAME = "SET_TIME_FRAME"
export const SET_BILLING_ID = "SET_BILLING_ID"
export const CLEAR_BILLING_ID = "CLEAR_BILLING_ID"
export const SET_ITEM_LIST = "SET_ITEM_LIST"
export const CLEAR_ITEM_LIST = "CLEAR_ITEM_LIST"
export const SET_PAYMENT = "SET_PAYMENT"
export const CLEAR_PAYMENT = "CLEAR_PAYMENT"
export const SET_DATE_LIST = "SET_DATE_LIST"
export const CLEAR_DATE_LIST = "CLEAR_DATE_LIsST"
export const SET_DEFAULT_SERVICE = "SET_DEFAULT_SERVICE"
export const CLEAR_DEFAULT_SERVICE = "CLEAR_DEFAULT_SERVICE"
export const SET_SERVICES_LIST = "SERVICES_LIST"
export const CLEAR_SERVICES_LIST = "CLEAR_SERVICES_LIST"
export const SET_ON_SITE_NAME = "ON_SITE_NAME"
export const CLEAR_ON_SITE_NAME = "CLEAR_ON_SITE_NAME"
export const SET_ON_SITE_PHONE = "SET_ON_SITE_PHONE"
export const CLEAR_ON_SITE_PHONE = "CLEAR_ON_SITE_PHONE"
export const SET_ON_SITE_NOTE = "SET_ON_SITE_NOTE"
export const CLEAR_ON_SITE_NOTE = "CLEAR_ON_SITE_NOTE"
export const SET_ON_SITE_GATE = "SET_ON_SITE_GATE"
export const CLEAR_ON_SITE_GATE = "CLEAR_ON_SITE_GATE"
export const SET_PAYMENT_EMAIL = "SET_PAYMENT_EMAIL"
export const CLEAR_PAYMENT_EMAIL = "CLEAR_PAYMENT_EMAIL"
export const SET_CALENDAR_LIST = "SET_CALENDAR_LIST"
export const CLEAR_CALENDAR_LIST = "CLEAR_CALENDAR_LIST"
export const SET_ADDRESS_ID = "SET_ADDRESS_ID"
export const SET_OVERALL_TOTAL = "SET_OVERALL_TOTAL"

const receiveOverallTotal = (total) => ({
	type: SET_OVERALL_TOTAL,
	total
})

const updatePaymentEmail = (email) =>({
	type: SET_PAYMENT_EMAIL, 
	email
})

const updateOnsitename = (name) =>({
	type: SET_ON_SITE_NAME, 
	name
})
const updateOnsitephone = (phone) =>({
	type: SET_ON_SITE_PHONE, 
	phone
})
const updateOnsitenote = (note) =>({
	type: SET_ON_SITE_NOTE, 
	note
})
const updateOnsitegate = (gate) =>({
	type: SET_ON_SITE_GATE, 
	gate
})

const updateServicesList = (serviceslist) => ({
	type: SET_SERVICES_LIST, 
	serviceslist
})


const updateDate = (date) => ({
	type: SET_DATE, 
	date
})
const updateDefaultService = (servicetype) =>({
	type: SET_DEFAULT_SERVICE, 
	servicetype
})


const updateTimeFrame = (timeframe) => ({
	type: SET_TIME_FRAME, 
	timeframe
})

const updateItemsList = (itemlist) =>({
	type: SET_ITEM_LIST, 
	itemlist
})
const updatePaymentMethod = (payment) =>({
	type: SET_PAYMENT, 
	payment
})

const updateDateList = (datelist) => ({
	type: SET_DATE_LIST, 
	datelist
})

const receiveCalendarList = (list) =>({
	type: SET_CALENDAR_LIST, 
	list
})


/*POSTERS*/
export const  updateRequestor = (newrequestor, customerID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateRequestor(newrequestor, customerID); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch( updateOnsitegate( JSON.parse(data).gate) )
				dispatch( updateOnsitenote(JSON.parse(data).note) )
				dispatch( updateOnsitename( JSON.parse(data).name) )
				dispatch( updateOnsitephone(JSON.parse(data).phone) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const  updateGateNumber = (gatenumber, customerID) =>  dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateGateNumber(gatenumber, customerID); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch( updateOnsitegate( JSON.parse(data).gate) )
				dispatch( updateOnsitenote(JSON.parse(data).note) )
				dispatch( updateOnsitename( JSON.parse(data).name) )
				dispatch( updateOnsitephone(JSON.parse(data).phone) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const  updateNote = (newnote, customerID) =>  dispatch =>{
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateNote(newnote, customerID); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch( updateOnsitegate( JSON.parse(data).gate) )
				dispatch( updateOnsitenote(JSON.parse(data).note) )
				dispatch( updateOnsitename( JSON.parse(data).name) )
				dispatch( updateOnsitephone(JSON.parse(data).phone) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}

	
export const  updateOnSitePhone = (newphone, customerID) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateOnSitePhoneNumber(newphone, customerID); 
			const data  = await response.json(); 
			
			if(response.ok){
				dispatch( updateOnsitegate( JSON.parse(data).gate) )
				dispatch( updateOnsitenote(JSON.parse(data).note) )
				dispatch( updateOnsitename( JSON.parse(data).name) )
				dispatch( updateOnsitephone(JSON.parse(data).phone) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}
export const  updateOnSiteName = (newname, addressID) => dispatch => {
	return new Promise( async (resolve, reject) =>{
		try{
			const response = await server.updateOnSiteName(newname, addressID); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch( updateOnsitegate( JSON.parse(data).gate) )
				dispatch( updateOnsitenote(JSON.parse(data).note) )
				dispatch( updateOnsitename( JSON.parse(data).name) )
				dispatch( updateOnsitephone(JSON.parse(data).phone) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const sendServices = (obj) => dispatch =>{
	return new Promise(async(resolve, reject) =>{
		try{
			const response = await server.createServices(obj); 
			const data  = await response.json(); 
			if(response.ok){
				console.log(JSON.parse(data))
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}	

export const cancelService = (serviceID) => {
	return new Promise(async(resolve, reject) =>{
		try{
			const response = await server.cancelService(serviceID); 
			const data  = await response.json(); 
			if(response.ok){
				console.log(JSON.parse(data))
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}


/*GETTERS*/

export const getStops = (testerID, date)  => {
	return new Promise(async(resolve, reject) => {
		try{
			const response = await server.getTesterStops(testerID, date); 
			const data  = await response.json(); 
			if(response.ok){
				resolve(JSON.parse(data).list)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const getTestersStops = (date, testerID, offset) => dispatch => {
	return new Promise(async(resolve, reject) => {
		try{
			const response = await server.getTestersStops(date, testerID, offset); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch(receiveCalendarList(JSON.parse(data).list))
				dispatch( receiveOverallTotal( JSON.parse(data).total) )
				resolve(JSON.parse(data).list)
			}
		}catch(e){
			console.log(e)
		}
	})
}

export const onSiteDetailsGetter = (addressID) => dispatch =>{
	return new Promise(async(resolve, reject) =>{
		try{
			const response = await server.requestOnSiteDetails(addressID); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch( updateOnsitegate( JSON.parse(data).gate) )
				dispatch( updateOnsitenote(JSON.parse(data).note) )
				dispatch( updateOnsitename( JSON.parse(data).name) )
				dispatch( updateOnsitephone(JSON.parse(data).phone) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}
export const datesGetter = (addressID) => dispatch =>{
	return new Promise(async(resolve, reject) =>{
		try{
			const response = await server.requestDates(addressID); 
			const data  = await response.json(); 
			if(response.ok){
				dispatch( updateDateList( JSON.parse(data).list) )
				resolve(true)
			}
		}catch(e){
			console.log(e)
		}
	})
}




/*SETTERs*/
export const emailSetter = (email) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updatePaymentEmail(email) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
	})
}

export const paymentSetter = (method) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updatePaymentMethod(method) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
	})
}

export const gateSetter = (gate) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updateOnsitegate(gate) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
	})
}

export const noteSetter = (note) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updateOnsitenote(note) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
	})
}
export const phoneSetter = (phone) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updateOnsitephone(phone) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
	})
}

export const nameSetter = (name) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updateOnsitename(name) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
	})
}
export const dateSelector = (date) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updateDate( date) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
		
	})
}

export const serviceTypeSelector = (servicetype) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		try{	
			dispatch( updateDefaultService(servicetype) ); 
			resolve()
		}catch(e){
			console.log(e)
		}
	})

}
export const itemsBuilder = (list) =>dispatch => {
	return new Promise ( async (resolve, reject)=>{
		try{
			dispatch( updateItemsList(list) )
			resolve()
		}catch(e){
			console.log(e)
		}
	})
}

export const servicesBuilder = (serviceslist) => dispatch =>{
	return new Promise ( async (resolve, reject)=>{
		try{
			dispatch( updateServicesList( serviceslist) )
			resolve()
		}catch(e){
			console.log(e)
		}
	})
}

export const timeFrameSetter = (timeframe) => dispatch =>{
	return new Promise( async( resolve, reject) =>{
		try{
			dispatch( updateTimeFrame(timeframe) ); 
			resolve()
		}catch(e){
			console.log(e); 
		}
		
	})

}