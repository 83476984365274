import {
  RECEIVE_MESSAGES
} from "../../actions/chat";

const _nullChat = { 
  messageList: []
}
                
export default (state = _nullChat, { type, messagelist }) => {
  switch (type) {
    case RECEIVE_MESSAGES:
      return messagelist;
    case "": 
      return _nullChat; 
    default:
      return state;
  }
};