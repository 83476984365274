import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {updateAccountName,
 updateContactName, 
 updatePhoneEmail,
	updateStatus,
clearAccount, clearAccountBilling
} from "../../../../actions/account"

import { requestCustomerDetails } from "../../../../actions/customerApp.js"


export default function AccountTab(){
		const customer = useSelector( (state) => state.customerdetails ); 
		const accountID = useSelector( (state) =>state.customerID) 
		const [accountName, setAccountName] = useState(null)
		const [fname, setFname] = useState(null); 

		const [phone, setPhone] = useState(null); 
		const [email, setEmail] = useState(null); 
		const [status, setStatus] = useState(null); 
		const [notes, setNotes] = useState(null); 
		const dispatch = useDispatch(); 
		const [show, setShow] = useState(false)
		

		useEffect(()=>{
			if(accountID != null){
				dispatch(requestCustomerDetails(accountID)).then((data, err) => {
					
				})
			}
		}, [accountID])


		useEffect(()=>{
			if (customer != null) {
				setAccountName(customer.display_name)
				setFname(customer.name);
				setPhone(customer.phone);
				setEmail(customer.email);
				setStatus(customer.status)
				setNotes(customer.note)
				setShow(true)
			} else {
				setShow(false)
			}
		}, [customer])

		return(
			<Container>
				
				{
					show ?
						<>
							<Row>
							
								<Col>
									<InputGroup size="sm">
										<InputGroup.Text> Account Name </InputGroup.Text>
										<Form.Control

											value={accountName}
											onChange={(event) => setAccountName(event.target.value)}
											onBlur={() => {
												if (customer.display_name != accountName) {
													dispatch(updateAccountName(accountName, accountID)).then((data, err) => {
														console.log("updated account name")
													})
												}
											}}
										/>
									</InputGroup>


									<InputGroup size="sm">
										<InputGroup.Text> Contact </InputGroup.Text>


										<Form.Control


											value={fname}
											onChange={(event) => setFname(event.target.value)}
											onBlur={() => {
												if (customer.first_name != fname) {
													dispatch(updateContactName({ fname: fname, lname: '' }, accountID, accountID)).then((data, err) => {
														console.log("updated account name")
													})
												}
											}}
										/>
									</InputGroup>

									<InputGroup size="sm">
										<InputGroup.Text> Phone #</InputGroup.Text>
										<Form.Control

											value={phone}
											onChange={(event) => setPhone(event.target.value)}
											onBlur={() => {
												if (customer.phone_number != phone) {
													dispatch(updatePhoneEmail({ phone: phone, email: email }, accountID)).then((data, err) => {
														console.log("updated account name")
													})
												}
											}}
										/>
									</InputGroup>
									<InputGroup size="sm">
										<InputGroup.Text> Email</InputGroup.Text>
										<Form.Control

											value={email}
											onChange={(event) => setEmail(event.target.value)}
											onBlur={() => {
												if (customer.email != email) {
													dispatch(updatePhoneEmail({ phone: phone, email: email }, accountID)).then((data, err) => {
														console.log("updated account name")
													})
												}
											}}
										/>
									</InputGroup>
									<InputGroup size="sm" >
										<InputGroup.Text>Status</InputGroup.Text>
										<Form.Select
											value={status}
											onChange={(event) => setStatus(event.target.value)}
											onBlur={() => {
												if (customer.account_status != status) {
													dispatch(updateStatus({ status: status, note: notes }, accountID)).then((data, err) => {
														console.log("updated account name")
													})
												}
											}}
										>
											<option value="ACTIVE"> Active </option>
											<option value="CREDIT HOLD"> Credit Hold </option>
											<option value="NOT ACTIVE"> NOT ACTIVE </option>
										</Form.Select>
									</InputGroup>
									<InputGroup>
										<InputGroup.Text> Notes </InputGroup.Text>

										<Form.Control
											size="sm"
											value={notes}
											onChange={(event) => setNotes(event.target.value)}
											onBlur={() => {
												if (customer.note != notes) {
													dispatch(updateStatus({ status: status, note: notes }, accountID)).then((data, err) => {
														console.log("updated account name")
													})
												}
											}}
										/>
									</InputGroup>

								</Col>
							</Row>
						</>
						:
						<></>


				}
				
				
			</Container>
		)
}