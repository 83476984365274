import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import TesterSubmittedInvoices from "./TesterSubmittedInvoices"; 
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";
import { requestInvoices, requestLineItemList} from "../../../actions/invoice.js"
import TesterSubmitted from "./TesterSubmitted"

export default function BillingApplication(){
	const [appToShow, setAppToShow] = useState(<><TesterSubmitted /></>)
	const [currentTab, setCurrentTab] = useState("SS"); 
	const [itemLoader, setItemLoader] = useState(true)
	const dispatch = useDispatch(); 
	
	useEffect(()=>{
		setItemLoader(true)
		dispatch(requestLineItemList()).then( (data, err)=>{
			setItemLoader(false)
			console.log("Got company items!")
		})

	}, [])

	useEffect(()=>{	
		switch(currentTab.toUpperCase()){
			case "SS": 
				setAppToShow(<TesterSubmitted />)
				break; 
			default:
				setAppToShow(<TesterSubmitted />)
				break;
		}
	}, [ currentTab])
		
	
	return(
		<Container  fluid className="main-container">
			
		    <Row>
		    	 <Col>
		    	 		{

		    	 			itemLoader ? 
		    	 				<Spinner />
		    	 			: 
		    	 				<> 
				   					{appToShow}
		    	 				</>
		    	 		}
				   			
				 </Col>
			</Row>
		</Container>
	)
}