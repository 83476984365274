import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import ServiceRow from "./ServiceRow"
import { format } from "date-fns"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	cancelStop,
	requestServices,
	updateStopDetails,
	requestStopDetails
} from "../../../.././../../actions/universal_stop"



export default function ServicesApp() {
	const [loader, setLoader] = useState(false)
	const stopID = useSelector((state) => state.universal_stopID )
	const  list = useSelector((state) => state.universal_services)

	const dispatch = useDispatch()

	useEffect(()=>{
		if(stopID){
			dispatch( requestServices(stopID)).then((data, err) =>{
				console.log("Done!")
			})
		}
	}, [stopID])

	const renderservices = list.map((service) => {
		return (
			<ServiceRow row = {service} /> 
		)
	})
				
	return(
		<Container>
			<Row>
				<Col>
				<Table size="sm"  responsive striped bordered>
					<thead>
						<tr>
							<td> Report ID</td>
							<td> Serial #</td>
							<td> Int. Results</td>
							<td> Rpr. Results</td>
							<td> Status </td>
							<td>  Water Sent</td>
							<td>  Client Sent </td>
							<td> </td>
						</tr>
					</thead>
					<tbody>
						{renderservices}
						<tr>
						</tr>
					</tbody>
				</Table>
				</Col>
			</Row>
		</Container>
	)
}