import * as apiUtil from "../util/server";


export const GET_STOP_LIST = "GET_STOP_LIST"; 
export const CLEAR_STOP_LIST = "CLEAR_STOP_LIST"; 

export const RECEIVE_DATES = "RECEIVE_DATES"; 
export const CLEAR_DATES = "CLEAR_DATES"; 

const receiveList = (stoplist) =>({
	type: GET_STOP_LIST, 
	stoplist
})

const cleanStoplist = () => ({
	type: CLEAR_STOP_LIST
})

const receiveDateList = (datelist) =>({
	type: RECEIVE_DATES, 
	datelist
})

const clearDatelist = ()  => ({
	type: CLEAR_DATES
})




export const getStops = (selectedDate) => dispatch =>{
	return new Promise(async (resolve, reject) =>{
		console.log("getting stops: ", selectedDate)
		let obj = { date: selectedDate } 
		const response = await apiUtil.getStops(obj)
		const data = await response.json()

		if(response.ok){
			let listObj = { stops:  JSON.parse(data).stopList}
			dispatch(receiveList(listObj ));
			resolve(true);  
		}
	})
}


export const getDates = (address) => dispatch =>{
	return new Promise( async (resolve, reject) =>{
		console.log("address: ", address); 
		const response = await apiUtil.requestDates(address); 
		const data = await response.json(); 
		if(response.ok){
			console.log("results: ", JSON.parse(data)); 
			dispatch( receiveDateList( JSON.parse(data).dates)); 
			resolve(true)
		}else{
			resolve(false)
		}
	})
}

export const cleanDatelist = () => dispatch=> {
	dispatch( clearDatelist() ); 
}; 