import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
    requestUnfinishedList, requestUnfinishedService, updateServiceReview
} from "../../../../actions/unfinished.js"

import { format } from "date-fns"

import { newCustomerID, createCustomer, associateAddressToCustomer } from "../../../../actions/customerApp.js"
import { determineID } from "../../../../actions/addressApp"

export default function UnfinishedRow(props) {
    const [loading, setLoading] = useState(false)
    const [myrow, setMyRow] = useState(null)
    const [show, setShow] = useState(false)
    const date = useSelector((state) => state.unfinished_date)
    const status = useSelector((state) => state.unfinished_status)
    const [dd, setDD] = useState('')
    const dispatch = useDispatch() 

    useEffect(() => {
        console.log("myrow: ", myrow)
        if (myrow) {
            setShow(true)
            let timedDate = myrow.date.slice(0,10)
            let actualString = `${timedDate}T00:00:00`
            setDD(actualString )
        } else {
            setShow(false)
        }
    }, [myrow])

    useEffect(() => {
        setLoading(true)
        requestUnfinishedService(props.service.serviceID).then((data, err) => {
            setMyRow(data)
            setLoading(false)
        })

    }, [props]) 

    const reviewed = () => {
        updateServiceReview(props.service.serviceID).then((data, err) => {
            dispatch(requestUnfinishedList(date, status)).then((data, err) => {
                setShow(false)
            }) 
        })
    }

    const openAccount = () => {
        dispatch(newCustomerID(myrow.accountID)).then((data, err) => {
            console.log(myrow.addressID)
            dispatch(determineID(myrow.addressID)).then((data, err) => {

            })
        })
    }

    return (
        <>
            { 
                loading  ? 
                    <></>
                    : 

                    <>
                        {
                            show ?
                                <tr>
                                    <td onDoubleClick={openAccount}> {myrow.location_name} - {myrow.street}</td>
                                    <td onDoubleClick={openAccount}> {myrow.serial_number}</td>
                                    <td onDoubleClick={openAccount}> { format( new Date(dd) , "MM/dd/yyyy")} </td>
                                    <td onDoubleClick={openAccount}> {myrow.state}</td>
                                    <td onDoubleClick={openAccount}> {myrow.reason}</td>
                                    <td> <Form.Check
                                        onClick={reviewed}
                                    /></td>
                                </tr>  
                                : 
                                
                                <></>
                    
                        } 
                    </>

                   
            }
        </>


       
    )
}