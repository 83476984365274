export const requestActiveWorkOrders = () => (
	fetch("backflow/workorder/list/active", {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const createOrder= (obj, accountID) =>(
	fetch("backflow/workorder", {
		method: "POST", 
		body: JSON.stringify( { obj: obj, accountID: accountID } ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestList= (accountID) =>(
	fetch("backflow/workorder/list?" + new URLSearchParams({
		accountID: accountID
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestDetails= (workOrderId) =>(
	fetch("backflow/workorder?" + new URLSearchParams({
		workOrderId: workOrderId
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
