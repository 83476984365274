import {
 STOP_LIST
} from "../../actions/addressApp";

const _nullList = []
                
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case STOP_LIST:
      return list;
    case "": 
      return _nullList; 
    default:
      return state;
  }
};