import {
  SET_BUILDER_TOTAL, 
  CLEAR_BUILDER_TOTAL
} from "../../actions/invoiceBuilder";

const _nullTotal =  0; 
                
export default (state = _nullTotal, { type, total }) => {
  Object.freeze(state);
  switch (type) {
    case SET_BUILDER_TOTAL:
      return total;
    case CLEAR_BUILDER_TOTAL: 
      return _nullTotal
    case "": 
      return _nullTotal; 
    default:
      return state;
  }
};