export const requestStopDetails = (stopID) => (
	fetch("backflow/unistop?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const updateStopDetails = (stopID, obj) => (
	fetch("backflow/unistop/update", {
		method: "PUT",
		body: JSON.stringify({ stopID: stopID, obj: obj}),  
		headers: {
			"Content-Type": "application/json"
		}
	})
)
export const cancelStop = (stopID) => (
	fetch("backflow/unistop/cancel", {
		method: "PUT",
		body: JSON.stringify({ stopID: stopID }),
		headers: {
			"Content-Type": "application/json"
		}
	})
)

export const requestServices = (stopID) => (
	fetch("backflow/unistop/services?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
)


