import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import SelectStates from "../../components/SelectStates.js"
import { determineID, requestStops } from "../../../../actions/addressApp.js"
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { newAddress } from "../../../../actions/customerApp.js"
export default function NewAddressForm(props){
	const [locationName, setLocationName] = useState(null); 
	const [street, setStreet] = useState(null); 
	const [city, setCity] = useState(null); 
	const [state, setState] = useState("OR")
	const [zipcode, setZipcode] = useState(null); 
	const [locationType, setLocationType] = useState("RESIDENTIAL")
	const dispatch = useDispatch(); 
	const customerID = useSelector( (state) => state.customerID)

	const  addThisAddress = (event) =>{
		event.preventDefault(); 
		//grab values 
		let obj = {}; 
		obj.locationName = locationName; 
		obj.street = street;
		obj.city = city;
		obj.state = state;
		obj.zipcode = zipcode; 
		obj.location_type = locationType
		dispatch( newAddress(obj, customerID) ).then((data, err)=>{
			console.log("This id the address: ", data)
			dispatch(determineID(data) ).then((data, err)=>{
				
				props.closeModal()
			})

		})
	}

	return(
		<Container>
			<Form onSubmit= { addThisAddress }>
			
				<Row>
					<Col>
						<FloatingLabel label = "Location Type">
							<Form.Select size="sm" className="mb-3" id="locationType" value={locationType} onChange= {(event) => setLocationType(event.target.value)}>
								<option value="RESIDENTIAL">Residential</option>
								<option value="COMMERCIAL">Commercial </option>
							</Form.Select>
						</FloatingLabel>

						<FloatingLabel label = "Location Name" className="mb-3" >
							<Form.Control 
								className="mb-3"
								placeholder="Location Name"
								id="locationName"
								value = {locationName}
								onChange= { (event) => setLocationName(event.target.value) }
								size="sm"
								required
							/>
						</FloatingLabel>
					</Col>
				</Row>
				<Row>
					<Col>
						<FloatingLabel label = "Street">
							<Form.Control 
								placeholder="Street"
								size="sm"
								id="street"
								value = {street}
								onChange= { (event) => setStreet(event.target.value) }
								required
								className="mb-3"
							/>
						</FloatingLabel>
					</Col>
				</Row>
				<Row>
					<Col>
						<FloatingLabel label = "City">
						<Form.Control
							size="sm"
							placeholder="City"
							id="city"
							value = {city}
							onChange= { (event) => setCity(event.target.value) }
							required
							className="mb-3"
						/>
						</FloatingLabel>
					</Col>
					<Col>
						<FloatingLabel label = "State">
							<SelectStates updateValue = {(val) => setState(val)}/>
						</FloatingLabel>
					</Col>
					<Col>
						<FloatingLabel label = "Zipcode">
							<Form.Control
								placeholder="Zipcode"
								size="sm"
								id="zipcode"
								value = {zipcode}
								onChange= { (event) => setZipcode(event.target.value) }
								required
							 />
						</FloatingLabel>
					</Col>
						
						
			
			</Row>
			<Row>
				<Col>	
					<Button
						variant="success"
						size="sm"
						type="submit" > 
						Add 
					</Button>
				</Col>
			</Row>
			</Form>
		</Container>
	)
}