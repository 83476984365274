import {
  SET_ROUNDS_CURRENT_ROUND, CLEAR_ROUNDS_CURRENT
} from "../../actions/rounds.js";

const _nullObj = null;
                
export default (state = _nullObj, { type, obj }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ROUNDS_CURRENT_ROUND:
      return obj;
    case CLEAR_ROUNDS_CURRENT: 
      return _nullObj; 
    case "": 
      return _nullObj; 
    default:
      return state;
  }
};