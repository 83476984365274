import {
  GET_CUSTOMER_DETAILS
} from "../../actions/customerApp";

const _nullCustomer = null
                
export default (state = _nullCustomer, { type, customer }) => {
  Object.freeze(state);
  switch (type) {
    case GET_CUSTOMER_DETAILS:
      return customer;
    case "": 
      return _nullCustomer; 
    default:
      return state;
  }
};