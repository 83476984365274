import * as server from "../util/server";

export const RECEIVE_DISPATCH = "RECEIVE_DISPATCH";
export const CLEAR_DISPATCH = "CLEAR_DISPATCH";

export const RECEIVE_TESTERS = "RECEIVE_TESTERS";
export const CLEAR_TESTERS = "CLEAR_TESTERS";

export const RECEIVE_TESTLIST = "RECEIVE_TESTLIST"

export const GET_STOPLIST = "GET_STOPLIST"



const getStoplist = (stoplist) =>({
	type: GET_STOPLIST, 
	stoplist
})

const getDispatch = (dispatch) =>({
		type: RECEIVE_DISPATCH,
		dispatch

})

const getTestlist = (testlist) =>({

	type: RECEIVE_TESTLIST,
	testlist
})

const getTesters = ( testerlist) =>({
	type: RECEIVE_TESTERS,
	testerlist
})


export const requestDispatchServer= ( date )=> async dispatch =>{
	return new Promise( async (resolve, reject) =>{
		let myObj = {  date: date}
		const response = await server.requestDispatch( myObj);
		const data = await response.json();
		if(response.ok){
				let ddObj = { stoplist: JSON.parse(data).list }
				dispatch( getStoplist( ddObj) );
				resolve(true);
		}
	})
}

export const updateDispatch = (newDispatch) => dispatch =>{
	return new Promise( async( resolve, reject) =>{

		dispatch( getDispatch( newDispatch ) )
	})
}
export const requestTesterNames = () => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestTesters();
		const data = await response.json();
		if(response.ok){
			
			dispatch( getTesters(JSON.parse(data).testers) );
			resolve(true)
		}
	})
}
