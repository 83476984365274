import {
  GET_DISTRICTS, 
  CLEAR_LIST
} from "../../actions/waterDistricts";

const _nullDistrictList = []
                
export default (state = _nullDistrictList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case GET_DISTRICTS:
      return list;
    case CLEAR_LIST: 
      return _nullDistrictList; 
    case "": 
      return _nullDistrictList; 
    default:
      return state;
  }
};