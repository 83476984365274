import {
  SET_ID
  , CLEAR_ID
} from "../../actions/customerApp";

const _nullID =  null
                
export default (state = _nullID, { type, ID }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ID:
      return ID;
    case CLEAR_ID: 
      return _nullID
    case "": 
      return _nullID; 
    default:
      return state;
  }
};