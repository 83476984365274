
import * as server from "../util/homeServer.js"

export const TOTALSLIST = "TOTALSLIST"
export const REPORT_LIST = "REPORT_LIST"
export const START_DATE = "START_DATE"
export const END_DATE = "END_DATE"


const receiveTotals = (list) =>({
	type: TOTALSLIST, 
	list
})
const receiveReports = (list) =>({
	type: REPORT_LIST, 
	list
})

const setStartDate = (date) =>({
	type: START_DATE, 
	date
})
const setEndDate = (date) =>({
	type: END_DATE, 
	date
})

export const updateStartDate = (start) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		dispatch(setStartDate(start))
		resolve()
	})
}
export const updateEndDate = (end) => dispatch => {
	return new Promise( async( resolve, reject) =>{
		dispatch(setEndDate(end))
		resolve()
	})
}



export const getDasher =  () => {
	return new Promise( async(resolve, reject) =>{
		try{
			const response = await server.requestDashboardDetails(); 
			const data =  await response.json(); 
			if(response.ok){
				resolve( JSON.parse(data).obj )
			}
		}catch(e){
			reject(e)
		}
	})
}

export const getTotalsList = () => dispatch => {
	return new Promise( async(resolve, reject)=>{
		try{
			const response = await server.requestTotalsList(); 
			const data =  await response.json(); 
			if(response.ok){
				dispatch( receiveTotals( JSON.parse(data).list))
				resolve(true)
			}
		}catch(e){
			reject(e)
		}
	})
}

export const requestReports = (startDate, endDate) => dispatch => {
	return new Promise( async(resolve, reject)=>{
		try{
			const response = await server.requestReportList( startDate, endDate); 
			const data =  await response.json(); 
			if(response.ok){
				dispatch( receiveReports( JSON.parse(data).list))
				resolve(true)
			}
		}catch(e){
			reject(e)
		}
	})
}
