import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Card from 'react-bootstrap/Card';

import ListGroup from 'react-bootstrap/ListGroup';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import { format } from "date-fns"
import { reduceID,  clearStop} from "../../../../actions/universal_stop" 
import { reduceInvoiceID, clearAllInvoice} from "../../../../actions/universal_invoice" 

/*import StopsApp from "./stopcard/app"*/


export default function StopCard(props) {
	const stopID = useSelector((state) => state.universal_stopID)
	const dispatch = useDispatch()
	const stoplist = useSelector((state) => state.address_stoplist)
	const [show, setShow] = useState(false)
	const [myvariant, setMyVariant] = useState("light")
	const [selected, setSelected] = useState(false)

	const timeZoned = (date) =>{
		let timed = date.slice(0,10)
		let actualString = `${timed}T00:00:00`
		console.log("actual date: ", actualString)
		return actualString
	}

	useEffect(() => {
		if (selected) {
			setMyVariant("light")
		} else {
			setMyVariant("light")
		}
	}, [selected])

	useEffect(() => {

	}, [stoplist])

	return (
		<>
			<ListGroup.Item
				variant={myvariant}
			>
				<Row>
					<Col xs={2} sm md lg={2}>

						<Form.Check
							type="radio"
							onClick={
								(event) => {
								console.log("changed!")
								if (event.target.checked) {
									dispatch(reduceID(stoplist[props.index].id)).then((data, err) => {
										dispatch(reduceInvoiceID(stoplist[props.index].invoiceID)).then((data, err) => {
											setShow(true)
											setSelected(true)
										})
										
									})
									
								} else {
									setSelected(false)
								}
							}}
							name= "selected-stop"
						/>
					</Col>
					<Col>
						
						{
							props.stop.date ?
								<>
									<p>
										{format(new Date(`${timeZoned(props.stop.date)}`), "MM/dd/yyyy")} <br/>
										{props.stop.status}
									</p>
								</>
								:
								<>
								</>

						}
					</Col>
				</Row>
			</ListGroup.Item>	
	
		</>
	)
}